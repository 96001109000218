import ExplorePcloud, { ExplorePcloudHeight } from "./UI/Header/ExplorePcloud";
import RewindExtend, { RewindExtendHeight } from "./UI/Header/RewindExtend";
import RevisionExtend, { RevisionExtendHeight } from "./UI/Header/RevisionExtend";
import {
  GRID_VIEW, GRID_ASPECT_RATIO_VIEW,
  SORT, SORT_DIRECTION_ASC, SORT_DIRECTION_DESC, SORT_ASCENDING, SORT_DESCENDING,
  NAME, MODIFIED, SORT_FOLDERS_FIRST, SORT_NEWEST_TOP, SORT_OLDEST_TOP,
  BROWSE_CONTENT, BROWSE_CONTENT_REWIND, BROWSE_DELETED_CONTENT, BROWSE_REVISION_CONTENT, CREATED
} from "../../config/constants";
import { OPTIONS } from "../../config/options";
import TrashHeader, { TrashHeaderHeight } from "./UI/Header/TrashHeader/TrashHeader";

export const getHeaderElements = (contentType, metadata, hasExploreCards) => {
  const isRoot = metadata.folderid === 0 && metadata.ismine;
  const elems = [];

  if (contentType === BROWSE_REVISION_CONTENT) {
    elems.push({
      element: RevisionExtend,
      height: RevisionExtendHeight
    });
  } else if (contentType === BROWSE_DELETED_CONTENT) {
    elems.push({
      element: TrashHeader,
      height: TrashHeaderHeight
    });
  } else if (contentType === BROWSE_CONTENT_REWIND && !HFN.config.isEFH() && !HFN.config.isBusiness()) {
    elems.push({
      element: RewindExtend,
      height: RewindExtendHeight
    })
  } else if (contentType === BROWSE_CONTENT && isRoot && hasExploreCards) {
    elems.push({
      element: ExplorePcloud,
      height: ExplorePcloudHeight
    });
    // elems.push({
    //   element: DragAndDrop,
    //   height: DragAndDropHeight
    // });
  }

  return elems;
}

export const canCreateFolder = (metadata) => {
  return (Perm.canCreate(metadata) && !HFN.config.isBackup(metadata) && !HFN.config.isRestrictedMobileBackup(metadata) && !HFN.config.isRestrictedDesktopBackup(metadata) && !(metadata.isdeleted ?? false));
}

export const filterContentData = (currentItemKeys, contentData, category) => {
  const newSelectedFiles = new Map();

  currentItemKeys.map((id) => {
    if (!contentData.items[id].isfolder && contentData.items[id].category && contentData.items[id].category === category) {
      newSelectedFiles.set(id, contentData.items[id]);
    }
  });

  return newSelectedFiles;
}

export const sortContentData = (sortArray, sortOptions, contentData, column) => {
  const sortArrayLength = sortArray.length;

  if (!sortArrayLength || !sortOptions || !column) {
    return sortArray;
  }

  const { sortBy, sortDirection, folderFirst, sortType } = sortOptions;
  const directionSign = sortDirection === SORT_DIRECTION_ASC ? 1 : -1;
  const collator = new Intl.Collator(navigator.language || "en", { numeric: true });

  sortArray.sort((a, b) => {
    const contentDataA = contentData[a];
    const contentDataB = contentData[b];

    if (folderFirst) {
      if (contentDataA.isfolder && !contentDataB.isfolder) {
        return -1;
      }

      if (!contentDataA.isfolder && contentDataB.isfolder) {
        return 1;
      }
    }

    let sortValueA = column.sortKey ? contentDataA[column.sortKey] : (column.getFieldValue ? column.getFieldValue(contentDataA) : contentDataA[sortBy]);
    let sortValueB = column.sortKey ? contentDataB[column.sortKey] : (column.getFieldValue ? column.getFieldValue(contentDataB) : contentDataB[sortBy]);

    if (sortType === "date") {
      sortValueA = new Date(sortValueA || 0);
      sortValueB = new Date(sortValueB || 0);
    } else if (sortType === "text") {
      sortValueA = sortValueA || '';
      sortValueB = sortValueB || '';

      if (sortArrayLength < 5000) {
        return (directionSign > 0) ? collator.compare(sortValueA, sortValueB) : collator.compare(sortValueB, sortValueA)
      } else {
        return (sortValueA).localeCompare(sortValueB) * directionSign;
      }
    }

    sortValueA = sortValueA || 0;
    sortValueB = sortValueB || 0;

    return (sortValueA - sortValueB) * directionSign;
  })

  return sortArray;
}

export const setLocalStorageSort = (contentType, sortBy, sortDirection, folderFirst, sortType) => {
  if (localStorage) {
    let sortData = {};

    if (localStorage.getItem("sortOptions")) {
      sortData = JSON.parse(localStorage.getItem("sortOptions"));
    }

    sortData[contentType] = {
      sortBy,
      sortDirection,
      folderFirst,
      sortType
    };

    localStorage.setItem('sortOptions', JSON.stringify(sortData));
  }
}

export const setLocalStorageContentType = (contentType) => {
  if (localStorage) {
    localStorage.setItem('contentType', contentType);
  }
}

export const getSortMenu = (sortOptions, onColumnSortClick, currentViewType, disableFoldersFirst, useDateCreated = FALSE, disableNameSort = false) => {
  const button = {
    icon: OPTIONS[SORT].icon
  };
  const dateSortColumn = useDateCreated ? CREATED : MODIFIED;

  const items = [];

  if (!disableNameSort) {
    items.push({
      icon: OPTIONS[SORT_ASCENDING].icon,
      label: OPTIONS[SORT_ASCENDING].optionTitle,
      onClick: onColumnSortClick,
      params: [NAME, SORT_DIRECTION_ASC, sortOptions.folderFirst, "text"],
      active: sortOptions.sortBy === NAME && sortOptions.sortDirection === SORT_DIRECTION_ASC
    });
    items.push({
      icon: OPTIONS[SORT_DESCENDING].icon,
      label: OPTIONS[SORT_DESCENDING].optionTitle,
      onClick: onColumnSortClick,
      params: [NAME, SORT_DIRECTION_DESC, sortOptions.folderFirst, "text"],
      active: sortOptions.sortBy === NAME && sortOptions.sortDirection === SORT_DIRECTION_DESC
    });
  }
  items.push({
    icon: OPTIONS[SORT_NEWEST_TOP].icon,
    label: OPTIONS[SORT_NEWEST_TOP].optionTitle,
    onClick: onColumnSortClick,
    params: [dateSortColumn, SORT_DIRECTION_DESC, sortOptions.folderFirst, "date"],
    active: sortOptions.sortBy === dateSortColumn && sortOptions.sortDirection === SORT_DIRECTION_DESC
  });
  items.push({
    icon: OPTIONS[SORT_OLDEST_TOP].icon,
    label: OPTIONS[SORT_OLDEST_TOP].optionTitle,
    onClick: onColumnSortClick,
    params: [dateSortColumn, SORT_DIRECTION_ASC, sortOptions.folderFirst, "date"],
    active: sortOptions.sortBy === dateSortColumn && sortOptions.sortDirection === SORT_DIRECTION_ASC
  });
  items.push({
    divider: true
  });

  const isGridView = (currentViewType === GRID_VIEW || currentViewType === GRID_ASPECT_RATIO_VIEW);

  items.push({
    icon: OPTIONS[SORT_FOLDERS_FIRST].icon,
    label: OPTIONS[SORT_FOLDERS_FIRST].optionTitle,
    onClick: onColumnSortClick,
    params: [sortOptions.sortBy, sortOptions.sortDirection, !sortOptions.folderFirst, sortOptions.sortType],
    active: isGridView ? true : !!sortOptions.folderFirst,
    disabled: isGridView || disableFoldersFirst,
    tooltip: isGridView ? ["Disabled"] : false
  });

  return {
    button: button,
    items: items
  }
};
