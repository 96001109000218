import { useRef, useEffect, useMemo, useState } from 'react';

const useRightSectionSlideAnimationManager = ({
  containerRef,
  currentRightSectionId,
  otherRightSectionId,
  isOpened,
  isToggled,
  disableHook = false,
  closeRightSection
}) => {
  const [slideAnimationEnd, setSlideAnimationEnd] = useState();
  const [onHideAnimationEnd, setOnHideAnimationEnd] = useState();

  const otherRightSection = useMemo(() => document.querySelector("#" + otherRightSectionId), []);
  const content = useMemo(() => document.querySelector("#" + currentRightSectionId), []);

  useEffect(() => {
    if (disableHook) {
      return;
    }

    const onSlideAnimationEnd = (e) => {
      if (e.propertyName && e.propertyName === "right") {
          setSlideAnimationEnd({value: true});
      }
    }

    if (containerRef.current) {
      containerRef.current.addEventListener("transitionend", onSlideAnimationEnd);
    }

    document.addEventListener("hide-" + currentRightSectionId, closeRightSection);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("transitionend", onSlideAnimationEnd);
      }

      document.removeEventListener("hide-" + currentRightSectionId, closeRightSection);
    }
  }, []);

  useEffect(() => {
    if (disableHook) {
      return;
    }

    setOnHideAnimationEnd({value: slideAnimationEnd && slideAnimationEnd.value && (!isOpened || isToggled)});

    if (slideAnimationEnd && slideAnimationEnd.value && isOpened && !isToggled) {
      // Opened and slide animation ended. Notify the panel below me to hide itself.
      document.dispatchEvent(new CustomEvent("hide-" + otherRightSectionId));
    }
  }, [slideAnimationEnd]);

  useEffect(() => {
    if (disableHook) {
      return;
    }

    if (isOpened && !isToggled) {
      // Opened
      content.style.zIndex = 1;
      otherRightSection.style.zIndex = 0;
      content.parentElement.classList.add("opened-" + currentRightSectionId);
    } else {
      // Closed
      content.parentElement.classList.remove("opened-" + currentRightSectionId);
      if (onHideAnimationEnd && onHideAnimationEnd.value) {
          // Closed after animation
          content.style.zIndex = 0;
          otherRightSection.style.zIndex = 1;
          setOnHideAnimationEnd(null);
      }
    }
  }, [isOpened, isToggled, onHideAnimationEnd]);

  return {
    content
  }
};

export default useRightSectionSlideAnimationManager;