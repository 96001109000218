import
    React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/trash-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/trash-mobile.svg";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import TrashExtend from "../../Header/TrashHeader/TrashExtend";
import styled from "styled-components";
import HashManager from "../../../../../lib/hashManager";

const Trash = ({ noItemsLabel }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const showExtend = !HFN.config.isEFH() && !HFN.config.isBusiness();
    const folderParam = HashManager.getState('folder');

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <UpperWrapper>
                {showExtend && <TrashExtend noMargin={true} />}
                <EmptyStateMessageCustom $isMobile={isMobile}>
                    <EmptyStateMessageTitle>
                        {folderParam ? __('Empty folder') : __(...noItemsLabel)}
                    </EmptyStateMessageTitle>
                    <EmptyStateMessageSubTitle>
                        {!folderParam && __(
                            "trash_empty_message",
                            "Trash is where you'll find recently deleted files and folders from your account and be able to restore them back in the account or erase them forever."
                        )}
                    </EmptyStateMessageSubTitle>
                </EmptyStateMessageCustom>
            </UpperWrapper>

            <ImageContainer $isMobile={isMobile}>
                {!isMobile && <EmptyDesktopIcon />}
                {isMobile && <EmptyMobileIcon />}
            </ImageContainer>

            <div />
        </EmptyStateWrapper>
    );
};

export default Trash;

const UpperWrapper = styled.div`
    
`;
const EmptyStateMessageCustom = styled(EmptyStateMessage)`
    padding: ${({$isMobile}) => $isMobile ? 'var(--spacing-md) var(--spacing-none) 0' : 'var(--spacing-2xl) var(--spacing-none)'};
`;
