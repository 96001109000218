import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_ALMOST_FULL_RATIO, STORAGE_FULL_RATIO } from "../../../LeftMenu/constants";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";
import { ONBOARDING_ADD_ITEM, ONBOARDING_REMOVE_ITEM } from "../../../../lib/state/constants/onboarding";
import {
    ONBOARDING_ID_ACCOUNT_SETTINGS,
    ONBOARDING_ID_UNLOCK_SPACE
} from "../../../HighlightOnboardingComponent/constants";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import { PAGES_EMAIL_REDIRECTS } from "../../../../containers/EmailRedirectPagesContainer/constants";
import hashManager from "../../../../lib/hashManager";

const MobileMenuBar = () => {
    const { bodyWidth } = useWindowDimensions();
    const dispatch = useDispatch();
    const userInfo = useSelector(({ user }) => user.userinfo);
    const {
        emailverified,
        quota,
        usedquota,
    } = userInfo;
    const leftMenuContent = useSelector(({ content }) => content.leftMenuContent);
    const {
        activeItem,
        isProfileOpened,
        previousMainActiveItem,
        previousMainActiveItemIndex,
        isMobileMenuOpened
    } = leftMenuContent;

    const isFullStorageSpace = usedquota >= STORAGE_FULL_RATIO * quota;
    const storageAlmostFull = usedquota >= STORAGE_ALMOST_FULL_RATIO * quota;

    const openLeftMenu = () => {
        const container = document.getElementById("left-menu-container");
        if (container && !PAGES_EMAIL_REDIRECTS.includes(hashManager.getState('page'))) {
            if (isProfileOpened && !activeItem) {
                dispatch({ type: "SET_LEFT_MENU_CONTENT", payload: {
                    ...leftMenuContent,
                        activeItem: previousMainActiveItem,
                        activeItemIndex: previousMainActiveItemIndex,
                        isProfileOpened: false,
                        isMobileMenuOpened: true
                } });
            } else {
                dispatch({
                    type: "SET_LEFT_MENU_CONTENT",
                    payload: {
                        ...leftMenuContent,
                        isMobileMenuOpened: true
                    }
                });
            }

            if (bodyWidth < MOBILE_WIDTH_BREAKPOINT) {

                if (
                    !(
                        typeof userInfo.journey === 'undefined' ||
                        Object.values(userInfo.journey.steps).every(value => value === true) ||
                        (
                            new Date(userInfo.registered).getTime() <
                            new Date("2017-10-30 11:00:00 GMT")
                        ) ||
                        userInfo.premium || userInfo.business || userInfo.vivapcloud ||
                        (!userInfo.premium && userInfo.freequota === userInfo.quota)
                    ) && !HFN.config.isDlink()
                ) {
                    dispatch({
                        type: ONBOARDING_ADD_ITEM,
                        id: ONBOARDING_ID_UNLOCK_SPACE
                    });
                }
                if (!HFN.config.isDlink()) {
                    dispatch({
                        type: ONBOARDING_ADD_ITEM,
                        id: ONBOARDING_ID_ACCOUNT_SETTINGS
                    });
                }
            }
        }
    };

    useEffect(() => {
        let touchStartX = 0;
        let touchEndX = 0;
        let touchStartY = 0;
        let touchEndY = 0;
        let isInsideContainer = false;

        const handleTouchStart = (e) => {
            touchStartX = e.changedTouches[0].clientX;
            touchStartY = e.changedTouches[0].clientY;
            isInsideContainer =
                e.target.closest("#explore-cards-wrapper") !== null;
        };

        const handleTouchEnd = (e, openAvailable = true) => {
            touchEndX = e.changedTouches[0].clientX;
            touchEndY = e.changedTouches[0].clientY;
            if (!isInsideContainer) {
                handleSwipeGesture(openAvailable);
            }
        };

        const handleSwipeGesture = (openAvailable) => {
            const horizontalSwipeDistance = touchEndX - touchStartX;
            const verticalSwipeDistance = touchEndY - touchStartY;
            // It's more likely a horizontal swipe
            if (
                Math.abs(horizontalSwipeDistance) >
                Math.abs(verticalSwipeDistance)
            ) {
                if (horizontalSwipeDistance > 25 && openAvailable) {
                    // Swiped right
                    if (touchStartX >= 100) {
                        return;
                    }
                    openLeftMenu();
                } else if (
                    horizontalSwipeDistance < -25 &&
                    isMobileMenuOpened
                ) {
                    // Swiped left
                    dispatch({
                        type: "SET_LEFT_MENU_CONTENT",
                        payload: {
                            ...leftMenuContent,
                            isMobileMenuOpened: false
                        }
                    });
                    dispatch({
                        type: ONBOARDING_REMOVE_ITEM,
                        id: ONBOARDING_ID_UNLOCK_SPACE
                    });
                    dispatch({
                        type: ONBOARDING_REMOVE_ITEM,
                        id: ONBOARDING_ID_ACCOUNT_SETTINGS
                    });
                }
            }
        };

        const documentTouchEnd = (e) => handleTouchEnd(e, false);

        const contentWrapper = document.getElementById("content-wrapper");

        contentWrapper.addEventListener("touchstart", handleTouchStart);
        contentWrapper.addEventListener("touchend", handleTouchEnd);
        document.addEventListener("touchstart", handleTouchStart);
        document.addEventListener("touchend", documentTouchEnd);

        return () => {
            contentWrapper.removeEventListener("touchstart", handleTouchStart);
            contentWrapper.removeEventListener("touchend", handleTouchEnd);
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchend", documentTouchEnd);
        };
    }, [leftMenuContent]);

    return (
        <Container>
            <i className="mediumIcon fa-solid fa-bars" onClick={openLeftMenu} />
            {(!emailverified || isFullStorageSpace || storageAlmostFull) && <WarningDot $storageAlmostFull={(storageAlmostFull && !emailverified && !isFullStorageSpace)} />}
        </Container>
    );
};

export default MobileMenuBar;

const Container = styled.div`
    position: relative;
    cursor: pointer;
    & i {
        transform: translateY(1px);
    }
`;

const WarningDot = styled.div`
  position: absolute;
  top: 0px;
  right: -8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ $storageAlmostFull }) => $storageAlmostFull ? "--color-functional-warning500" : "var(--color-functional-danger500)"};

`