// @flow

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { OvalModalWrap, CloseModalIcon } from "../Modal";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";
import Button from "../ButtonDefault";
import * as Style from "./styledComponents";

import apiMethod from "../../api/apiMethod";
import { errorKeys } from "../../lib/errors";
import { __ } from "../../lib/translate";
import { createIconSrc, ICO, ICONS } from "../../lib/icons";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      display: "inline-block",
      color: "#17bed0",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  data: Array<any>
};

const StopAllSharesModal = ({ data }: Props) => {
  const { name, shares } = data;

  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        stopAllShares();
      }
    },
    [stopAllShares]
  );

  useEffect(() => {
    if (isOpen) {
      console.log("Add keyup event StopAllSharesModal");
      document.addEventListener("keyup", onEnter);
    } else {
      console.log("Remove keyup event StopAllSharesModal");
      document.removeEventListener("keyup", onEnter);
    }
  }, [isOpen, onEnter]); //[isOpen, onEnter]

  const handleClose = () => {
    setIsOpen(false);
  };

  const stopAllShares = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let wait = [];
    let allShares = shares;
    for (let n = 0; n < allShares.length; ++n) {
      if (allShares[n].type == "request") {
        wait.push(
          apiMethod(
            "cancelsharerequest",
            { sharerequestid: allShares[n].shareid },
            () => {
              HFN.cache.expireMatch("api-listshares");
            },
            {
              errorCallback: ({ result, error }) => {
                if (errorKeys[result]) {
                  HFN.message(__(errorKeys[result]), "error");
                } else {
                  console.log("ERROR", { result, error });
                  HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                }
                setIsLoading(false);
              },
              onXhrError: (xhr, status, error) => {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                setIsLoading(false);
              }
            }
          )
        );
      } else if (allShares[n].type == "share") {
        wait.push(
          apiMethod(
            "removeshare",
            { shareid: allShares[n].shareid },
            () => {
              HFN.cache.expireMatch("api-listshares");
            },
            {
              errorCallback: ({ result, error }) => {
                if (errorKeys[result]) {
                  HFN.message(__(errorKeys[result]), "error");
                } else {
                  console.log("ERROR", { result, error });
                  HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                }
                setIsLoading(false);
              },
              onXhrError: (xhr, status, error) => {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                setIsLoading(false);
              }
            }
          )
        );
      } else if (allShares[n].type == "bshare") {
        let obj = {};
        if (allShares[n].user) obj.usershareids = allShares[n].bshareid;
        else obj.teamshareids = allShares[n].bshareid;
        wait.push(
          apiMethod(
            "account_stopshare",
            obj,
            () => {
              HFN.cache.expireMatch("api-account_listshares");
            },
            {
              errorCallback: ({ result, error }) => {
                if (errorKeys[result]) {
                  HFN.message(__(errorKeys[result]), "error");
                } else {
                  console.log("ERROR", { result, error });
                  HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                }
                setIsLoading(false);
              },
              onXhrError: (xhr, status, error) => {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                setIsLoading(false);
              }
            }
          )
        );
      } else if (allShares[n].type == "publink") {
        wait.push(
          apiMethod(
            "deletepublink",
            { linkid: allShares[n].linkid },
            () => {
              HFN.cache.expireMatch("api-listpublinks");
              HFN.cache.expireMatch("publinks-all");
            },
            {
              errorCallback: ({ result, error }) => {
                if (errorKeys[result]) {
                  HFN.message(__(errorKeys[result]), "error");
                } else {
                  console.log("ERROR", { result, error });
                  HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                }
                setIsLoading(false);
              },
              onXhrError: (xhr, status, error) => {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                setIsLoading(false);
              }
            }
          )
        );
      } else if (allShares[n].type == "puplink") {
        wait.push(
          apiMethod(
            "deleteuploadlink",
            { uploadlinkid: allShares[n].uploadlinkid },
            () => {
              HFN.cache.expireMatch("api-listuploadlinks");
              HFN.cache.expireMatch("puplinks-all");
            },
            {
              errorCallback: ({ result, error }) => {
                if (errorKeys[result]) {
                  HFN.message(__(errorKeys[result]), "error");
                } else {
                  console.log("ERROR", { result, error });
                  HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                }
                setIsLoading(false);
              },
              onXhrError: (xhr, status, error) => {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                setIsLoading(false);
              }
            }
          )
        );
      }
    }

    $.when.apply($, wait).then(function() {
      HFN.message(__("stopped_shared_to_message", false, { name: data.name }, true, false));

      HFN.refreshSharesAll();
      setIsLoading(false);
      setIsOpen(false);
    });
  });

  const renderActiveSharesEmail = share => {
    return <div key={share.shareid ?? share.bshareid}>{share.sharedwith}</div>;
  };

  const title = __("share_folder_share_for").replace("%foldername%", name);

  return (
    <Modal animate onClose={handleClose} opened={isOpen}>
      <OvalModalWrap>
        <CloseModalIcon onClick={handleClose} />
        <Style.Container>
          <Style.Header>
            <Componentify text={title} converters={[customConverter]} />
          </Style.Header>
          <Style.Label>{__("Stop all shares for")}</Style.Label>
          <BoxShares style={{ marginBottom: "15px" }}>
            <Icon src={createIconSrc(ICO.FOLDER_SHARED, ICONS.LIST_SMALL)} alt="icon" />
            {name}
          </BoxShares>
          <Style.Label>{__("Shared with")}</Style.Label>
          <BoxShares>
            <EmailShares>{shares.map(renderActiveSharesEmail)}</EmailShares>
          </BoxShares>
          <Style.Footer>
            <Button
              color="lightgray4"
              style={{
                marginRight: "5px"
              }}
              onClick={handleClose}
            >
              {__("Close")}
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              color="red"
              style={{
                marginLeft: "5px"
              }}
              onClick={stopAllShares}
            >
              {__("Stop", "Stop")}
            </Button>
          </Style.Footer>
        </Style.Container>
      </OvalModalWrap>
    </Modal>
  );
};

export default StopAllSharesModal;

const BoxShares = styled(Style.Box)`
  height: auto;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  font-weight: normal;
`;

const Icon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
`;

const EmailShares = styled.div`
  text-align: left;
  font-weight: 500;
`;
