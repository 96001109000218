import React, { useEffect, useState } from "react";
import { getHashPage } from "../../../../lib";
import Default from "./Pages/Default";
import Filemanager from "./Pages/Filemanager";
import Search from "./Pages/Search";
import Trash from "./Pages/Trash";
import Songs from "./Pages/Songs";
import Albums from "./Pages/Albums";
import Artists from "./Pages/Artists";
import Playlists from "./Pages/Playlists";
import MyShares from "./Pages/MyShares";
import PublicLinks from "./Pages/PublicLinks";
import SharedWithMe from "./Pages/SharedWithMe";
import hashManager from "../../../../lib/hashManager";

const EmptyState = (props) => {
    const [page, setPage] = useState(getHashPage());

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const handleHashChange = () => {
        setPage(getHashPage());
    };

    switch (page) {
        case "filemanager":
            if (hashManager.getState("q")) {
                return <Search {...props} />;
            } else {
                return <Filemanager {...props} />;
            }
        case "trash":
            return <Trash {...props} />;
        case "tab-songs":
            return <Songs {...props} />;
        case "tab-albums":
            return <Albums {...props} />;
        case "tab-artists":
            return <Artists {...props} />;
        case "tab-playlists":
            return <Playlists {...props} />;
        case "publinks-tab":
            return <PublicLinks {...props} />;
        case "myshares-tab":
        case "cryptoshares":
            return <MyShares {...props} />;
        case "requests-tab":
        case "cryptosharedwithme":
            return <SharedWithMe {...props} />;
        default:
            return <Default {...props} />;
    }
};

export default EmptyState;
