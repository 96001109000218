import React from "react";
import DragAndDrop from "../../Header/DragAndDrop";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/filemanager-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/filemanager-mobile.svg";
import EmptyCryptoDesktopIcon from "../../../../../../root/img/grid/empty-state/crypto-desktop.svg";
import EmptyCryptoMobileIcon from "../../../../../../root/img/grid/empty-state/crypto-mobile.svg";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyUploadMobile from "../../Header/EmptyUploadMobile";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import { __ } from "../../../../../lib/translate";
import { useSelector } from "react-redux";
import AlertCryptoV1 from "../../../../../containers/CryptoPages/parts/AlertCryptoV1";
import ExplorePcloud from "../../Header/ExplorePcloud";
import { detectIsMobile } from "../../../../../lib/utils";

const Filemanager = ({ noItemsLabel }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const isMobileDevice = detectIsMobile();
    const itemData = useSelector(({ content }) => content.itemData);
    const isCrypto = !!itemData.encrypted;
    const isRoot = itemData.folderid === 0;
    const isCryptoSetup = !!sessionStorage.getItem('cryptosetupcompleted');

    return (
        <>
            {isRoot ? <ExplorePcloud isMobileDevice={isMobileDevice}/> : null}
            {isCrypto && isCryptoSetup && <AlertCryptoV1 />}
            <EmptyStateWrapper $isMobile={isMobile} $exploreCardsAreVisible={isRoot}>
                <EmptyStateMessage $isMobile={isMobile}>
                    <EmptyStateMessageTitle>
                        {isCrypto
                            ? __(
                                "crypto_empty_title",
                                "Your Crypto folder is empty."
                            )
                            : __(...noItemsLabel)}
                    </EmptyStateMessageTitle>
                    {isCrypto && (
                        <EmptyStateMessageSubTitle>
                            {__(
                                "crypto_empty_message",
                                "Upload your most precious files in the Crypto and make sure they are protected with the most complex encryption type possible for cloud storage. You are the one who own the key to the encryption."
                            )}
                        </EmptyStateMessageSubTitle>
                    )}
                </EmptyStateMessage>

                <ImageContainer $isMobile={isMobile} $exploreCardsAreVisible={isRoot}>
                    {isCrypto ? (
                        isMobile ? <EmptyCryptoMobileIcon /> : <EmptyCryptoDesktopIcon />
                    ) : (
                        isMobile ? <EmptyMobileIcon /> : <EmptyDesktopIcon />
                    )}
                </ImageContainer>

                {!isMobile && <DragAndDrop />}
                {isMobile && <EmptyUploadMobile />}
            </EmptyStateWrapper>
        </>
    );
};

export default Filemanager;
