import React, { useState, useRef, useEffect, useMemo } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { __ } from "../../lib/translate";

import GalleryHeader from "./GalleryHeader";
import GalleryFooter from "./GalleryFooter";
import VideoContainer from "./VideoContainer";
import ErrorMediaPreview from "./ErrorMediaPreview";

import useEscape from "./hooks/useEscape";
import useGalleryData from "./hooks/useGalleryData";
import useSlideshow from "./hooks/useSlideshow";

import { Loader } from "../../components/Modals/styledComponents";
import { detectIsMobile } from "../../lib/utils";

type Props = {
  contentData: {
    items: {
      [key: string]: {
        id: string,
        name: string,
        category: number,
        thumb: boolean,
        width: number,
        height: number,
        contenttype: string,
        size: number
      }
    },
    itemKeys: [string]
  },
  initialMediaId: string,
  revisionId: string,
  autoStartSlideshow: boolean,
  opts: {
    code: string,
    linkpassword: string,
    candownload: boolean
  },
  onClosePreview: () => void
};

function GalleryModal({
  contentData,
  initialMediaId,
  revisionId,
  autoStartSlideshow = false,
  opts = {}, // {code, linkpassword, candownload}
  onClosePreview
}: Props) {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  const touch = useRef({
    distanceX: 0,
    distanceY: 0,
    x: 0,
    y: 0,
    disabledGoToNextOrPrev: false
  });

  const [exitSlideshowFullscreen, setExitSlideshowFullscreen] = useState({value: false});
  const [fullscreenVideoFromSlideshow, setFullscreenVideoFromSlideshow] = useState(false);
  const [disableArrowKeys, setDisableArrowKeys] = useState(false);
  const [shownVideoPaused, setShownVideoPaused] = useState(true);
  const [draggingVideoProgress, setDraggingVideoProgress] = useState(false);
  const [onEscapeBtnClicked, setOnEscapeBtnClicked] = useState(null);

  const [slideshowActive, setSlideshowActive] = useState(autoStartSlideshow);
  const [slideshowPaused, setSlideshowPaused] = useState(true);

  const isMobile = useMemo(() => !!detectIsMobile(), []);
  const classMobile = isMobile ? "mobile-view" : "";

  const minZoom = 100;
  const maxZoom = 500;
  const zoomStep = 10;

  const modalContainer = useRef(null);

  const menuInstanceRef = React.createRef();
  const menuInstanceRefFooter = React.createRef();

  const closeDropdownMenu = () => {
    if (menuInstanceRef.current) {
      menuInstanceRef.current.closeMenu();
    }
    if (menuInstanceRefFooter.current) {
      menuInstanceRefFooter.current.closeMenu();
    }
  };

  const [zoom, setZoom] = useState(100);
  const [panningStarted, setPanningStarted] = useState(false);

  const defaultDocumentTitle = useMemo(() => document.title, []);

  const transformWrapperRef = useRef(null);
  const mediaContentWrapperEl = useRef(null);
  const slideshowMessageEl = useRef(null);

  const vidstackMediaPlayer = useRef(null);

  const resetZoom = () => {
    if (zoom === 100 || !transformWrapperRef.current) {
      return;
    }
    
    transformWrapperRef.current.resetTransform(300);
  };

  const goToMedia = (direction: "next" | "previous") => {
    if (typeof gtag === "function") {
      gtag("event", "media_preview_click", {
        action: direction,
        category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
      });
    }
    
    showItem(direction === "next" ? getNext(shownItemData.place) : getPrevious(shownItemData.place));
  };

  const goToNextMedia = () => {
    goToMedia("next");
  };

  const goToPrevMedia = () => {
    goToMedia("previous");
  };

  const shownLastMedia = () => shownItemData.place == contentDataGalleryIds.length - 1;

  const onVideoFullscreenChange = isFullscreen => {
    setDisableArrowKeys(isFullscreen);

    if (slideshowActive && !isFullscreen) {
      // Deactivate slideshow
      setSlideshowActive(false);
    }
  };

  const onVideoEndedActions = (videoDataForPlayer) => {
    if (slideshowActive && !slideshowPaused) {
      setShownVideoPaused(false);
    }

    onVideoEnded(videoDataForPlayer);
  };

  const onToggleSlideshow = () => {
    if (!slideshowActive) {
      
      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: "slideshow",
          eventValue: "on",
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      setSlideshowActive(true);
    } else {
      onCloseOrDeactivateSlideshow();
    }
  };

  const onCloseOrDeactivateSlideshow = (event, trigger = "button") => {
    if (slideshowActive && !autoStartSlideshow) {

      if (typeof gtag === "function") {
        gtag("event", "media_preview_click", {
          action: "slideshow",
          eventValue: "off",
          category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
        });
      }

      // Turn off slideshow.
      setSlideshowActive(false);
    } else {
      // Close the whole gallery modal.
      if (trigger === "esc" || trigger === "button") {
        if (typeof gtag === "function") {
          gtag("event", "media_preview_click", {
            action: "close",
            eventValue: trigger,
            category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
        }
      }
      
      onClosePreview();
    }
  };

  const onExitSlideshowFullscreen = () => {
    setExitSlideshowFullscreen({value: true});
  };

  useEffect(() => {
    // We are here on Escape key when fullscreen was changed.
    if (slideshowActive && exitSlideshowFullscreen && exitSlideshowFullscreen.value) {
      onCloseOrDeactivateSlideshow();
    }
  }, [exitSlideshowFullscreen]);

  useEffect(() => {
    if (!onEscapeBtnClicked) {
      return;
    }

    onCloseOrDeactivateSlideshow(null, "esc");
  }, [onEscapeBtnClicked]);

  useEscape((event) => {
    const isAnotherModalOpen = document.documentElement.classList.contains("g-modal-open");
    if (!isAnotherModalOpen) {
      setOnEscapeBtnClicked(event);
    }
  });

  const {
    media,
    loading,
    contentDataGalleryIds,
    shownItemData,
    showItem,
    getNext,
    getPrevious,
    onShownMediaError,
    onShownMediaSuccess,
    videoLoading,
    setVideoLoading,
    showErrorMediaPreview
  } = useGalleryData({
    rawContentData: contentData,
    initialMediaId,
    revisionId,
    opts,
    onClosePreview,
    resetZoom,
    fullscreenVideoFromSlideshow,
    disableArrowKeys,
    slideshowActive,
    slideshowPaused,
    vidstackMediaPlayer
  });

  const {
    slideSeconds,
    setSlideSeconds,
    shouldRepeat,
    setShouldRepeat,
    slideshowNotification,
    setSlideshowNotification,
    showSlideshowControls,
    onVideoEnded,
    onEnterFullscreenRequestForVideo
  } = useSlideshow({
    slideshowActive,
    setSlideshowActive,
    slideshowPaused,
    setSlideshowPaused,
    goToNextMedia,
    shownLastMedia,
    mediaContentWrapperEl,
    shownItemData,
    media,
    fullscreenVideoFromSlideshow,
    setFullscreenVideoFromSlideshow,
    showErrorMediaPreview,
    resetZoom,
    onExitSlideshowFullscreen
  });

  useEffect(() => {
    if (slideshowNotification.show && slideshowMessageEl.current) {
      // Reset animation.
      slideshowMessageEl.current.style.animation = "none";
      slideshowMessageEl.current.offsetHeight; // trigger reflow.
      slideshowMessageEl.current.style.animation = null;
    }
  }, [slideshowNotification]);

  useEffect(() => {
    if (draggingVideoProgress) {
      // Prevent swipe to next item while user is using the video time slider.
      touch.current.disabledGoToNextOrPrev = true;

      resetTouch();
    }
  }, [draggingVideoProgress]);

  const resetTouch = () => {
    touch.current = {
      distanceX: 0,
      distanceY: 0,
      x: 0,
      y: 0,
      disabledGoToNextOrPrev: false
    };
  };

  const onTouchStart = e => {
    if (draggingVideoProgress) {
      // Prevent swipe to next item while user is using the video time slider.
      touch.current.disabledGoToNextOrPrev = true;
      return;
    }

    e.stopPropagation();

    setShowHeaderFooter(true);

    touch.current.x = e.targetTouches[0].clientX;
    touch.current.y = e.targetTouches[0].clientY;
  };

  const onTouchMove = e => {
    touch.current.distanceY = Math.max(0, touch.current.y - e.targetTouches[0].clientY);
    touch.current.distanceX = touch.current.x - e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    // disableArrowKeys is TRUE when video is on fullscreen.
    if (!touch.current.disabledGoToNextOrPrev && !disableArrowKeys && contentDataGalleryIds.length > 1) {
      if (touch.current.distanceX > 50 && zoom === 100) {
        
        if (typeof gtag === "function") {
          gtag("event", "media_preview_click", {
            action: "next",
            category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
        }

        showItem(getNext(shownItemData.place));
      } else if (touch.current.distanceX < -50 && zoom === 100) {

        if (typeof gtag === "function") {
          gtag("event", "media_preview_click", {
            action: "previous",
            category: shownItemData && shownItemData.metaData && shownItemData.metaData.category === HFN.CATEGORY.VIDEO ? "video" : "image"
          });
        }

        showItem(getPrevious(shownItemData.place));
      }
    }

    resetTouch();
  };

  const handleZoomStart = () => {
    resetTouch();
  };

  const handleZoomEnd = () => {
    resetTouch();
  };

  const handlePanning = (ref) => {
    if (zoom === 100) {
      return;
    }

    resetTouch();
  };

  const onZoom = (ref) => {
    let newZoom = Math.round(ref.state.scale * 100);
    setZoom(newZoom > minZoom ? newZoom : minZoom);
  };

  const renderImage = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: "1",
        cursor: panningStarted && zoom > 100 ? "pointer" : "default",
      }}
      onClick={closeDropdownMenu}
    >
      <TransformWrapper
        ref={transformWrapperRef}
        defaultScale={1}
        initialScale={1}
        minScale={minZoom / 100} // zoom out to 50% on web and 100% on mobile
        maxScale={maxZoom / 100} // zoom in to 500%
        wheel={{ step: zoomStep / 100 }} // Set the zoom increment to 10% using scroll
        onZoom={onZoom}
        onTransformed={ref => ref.state.scale === 1 ? setZoom(100) : null}
        onZoomStart={handleZoomStart}
        onZoomStop={handleZoomEnd}
        onPanning={handlePanning}
        onPanningStart={() => setPanningStarted(true)}
        onPanningStop={() => setPanningStarted(false)}
        limitToBounds
        panning={{disabled: zoom <= 100, velocityDisabled: zoom <= 100 }}
			  alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
        centerZoomedOut
        smooth={false}
        doubleClick={{ disabled: true }}
        disabled={slideshowActive || !slideshowPaused} // disable zoom in / out on slideshow on
      >
        <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }} contentStyle={{ width: "100%", height: "100%" }}>
          <ImageContainer
            src={media.url}
            loading="lazy"
            onError={onShownMediaError}
            onLoad={() => onShownMediaSuccess("image")}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );

  const renderVideo = () => (
    <VideoContainer
      ref={vidstackMediaPlayer}
      videoData={media}
      seek={shownItemData.seek}
      autoPlay={shownItemData.autoPlay || (slideshowActive && !slideshowPaused)}
      onVideoEnded={onVideoEndedActions}
      onFullscreenChange={onVideoFullscreenChange}
      onPause={() => setShownVideoPaused(true)}
      onPlay={() => {
        setShownVideoPaused(false);
        onShownMediaSuccess("video");
      }}
      slideshowActive={slideshowActive}
      slideshowPlaying={slideshowActive && !slideshowPaused}
      videoLoading={videoLoading}
      setVideoLoading={setVideoLoading}
      onEnterFullscreenRequest={onEnterFullscreenRequestForVideo}
      defaultDocumentTitle={defaultDocumentTitle}
      onError={onShownMediaError}
      onSuccessLoadingThumb={() => onShownMediaSuccess("videoThumb")}
      onDraggingVideoProgress={setDraggingVideoProgress}
      noSwipeGesture
    />
  );

  const renderMediaContent = () => {
    if (showErrorMediaPreview.show || !media || loading) {
      return null;
    }

    switch (media.category) {
      case HFN.CATEGORY.IMAGE:
        return renderImage();
      case HFN.CATEGORY.VIDEO:
        return renderVideo();
    }
  };

  return (
    <>
      <GlobalStyle />
      <GalleryWrapper>
        <GalleryInnerWrapper
          ref={modalContainer}
          className={`gallery-inner-wrapper-modal show-header-footer${
            slideshowActive &&
            (slideshowPaused ||
              (shownItemData &&
                shownItemData.metaData &&
                shownItemData.metaData.category === HFN.CATEGORY.VIDEO &&
                shownVideoPaused))
              ? " paused"
              : ""
          }${slideshowActive ? " playing-slideshow" : ""}${
            slideshowActive && !slideshowPaused && showSlideshowControls ? " show-slideshow-controls" : ""
          } ${classMobile} ${showHeaderFooter && slideshowActive && !slideshowPaused ? "fadeInAndOut" : ""}${
            fullscreenVideoFromSlideshow ? ` fullscreen-video` : ""
          }`}
          onAnimationEnd={() => {
            setShowHeaderFooter(false);
          }}
        >
          <GalleryHeader
            shownItemData={shownItemData}
            total={contentDataGalleryIds.length}
            contentDataGalleryIds={contentDataGalleryIds}
            opts={opts}
            onClose={onCloseOrDeactivateSlideshow}
            onGoToNextMedia={goToNextMedia}
            onGoToPrevMedia={goToPrevMedia}
            slideshowOn={
              slideshowActive &&
              !(
                slideshowPaused ||
                (shownItemData &&
                  shownItemData.metaData &&
                  shownItemData.metaData.category === HFN.CATEGORY.VIDEO &&
                  shownVideoPaused)
              )
            }
            modalContainer={modalContainer && modalContainer.current}
            ref={menuInstanceRef}
            showErrorMediaPreview={showErrorMediaPreview}
          />

          <MediaContentWrapper
            ref={mediaContentWrapperEl}
            className={`slideshow${media && media.category === HFN.CATEGORY.VIDEO && !loading ? " media-video" : ""}`}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onTouchCancel={onTouchEnd}
            onTouchMoveCapture={onTouchMove}
          >
            {slideshowNotification.show ? (
              <SlideshowMessage
                ref={slideshowMessageEl}
                onAnimationEnd={() => setSlideshowNotification({ show: false, msg: "" })}
              >
                {slideshowNotification.msg}
              </SlideshowMessage>
            ) : null}

            {showErrorMediaPreview.show ? (
              <ErrorMediaPreview
                itemData={shownItemData}
                opts={opts}
                showDownloadButton={showErrorMediaPreview.options.showDownloadButton}
                onClose={onCloseOrDeactivateSlideshow}
              />
            ) : !media || loading || videoLoading ? (
              <Loader />
            ) : null}
            {renderMediaContent()}

            <div className="media-preview-message-popup" />
          </MediaContentWrapper>

          <GalleryFooter
            total={contentDataGalleryIds.length}
            shownItemData={shownItemData}
            onGoToNextMedia={goToNextMedia}
            onGoToPrevMedia={goToPrevMedia}
            slideshowActive={slideshowActive}
            onToggleSlideshow={onToggleSlideshow}
            slideshowPaused={
              slideshowPaused ||
              (shownItemData &&
                shownItemData.metaData &&
                shownItemData.metaData.category === HFN.CATEGORY.VIDEO &&
                shownVideoPaused)
            }
            slideSeconds={slideSeconds}
            setSlideSeconds={setSlideSeconds}
            shouldRepeat={shouldRepeat}
            setShouldRepeat={setShouldRepeat}
            transformWrapper={transformWrapperRef?.current}
            zoomStep={zoomStep}
            panningStarted={panningStarted}
            zoom={zoom}
            setZoom={setZoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
            ref={menuInstanceRefFooter}
            modalContainer={modalContainer && modalContainer.current}
            showErrorMediaPreview={showErrorMediaPreview}
            loading={!media || loading}
          />
        </GalleryInnerWrapper>
      </GalleryWrapper>
    </>
  );
}

export default GalleryModal;

const fadeInAndOut = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;

    .action-sheet-overlay {
      z-index: 20001;
    }
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

const GalleryWrapper = styled.div`
  .no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard */
  }
  
  .gallery-tooltip {
    background: #fff;
    padding: 8px 16px;
    color: #000;
    border-radius: 2px;
  
    &:after {
      background-color: transparent !important;
    }
  }
  
  .media-preview-message-popup {
    .Toastify__toast {
      &:not(.Toastify__toast--error) {
        color: #000 !important;
        a {
          color: #000 !important;
        }
      }
    }
    .Toastify__toast--success {
      background-color: #fff;
    }
  }
`;

const GalleryInnerWrapper = styled.div`
  z-index: 20000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  /* height: 100vh; */
  background: #191919;

  display: flex;
  justify-content: center;
  flex-direction: column;

  &.playing-slideshow .preview-header,
  &.playing-slideshow .preview-footer {
    transition: opacity 0.3s;
    opacity: 0;
  }

  &.playing-slideshow.fullscreen-video {
    background: #000;
    .preview-header,
    .preview-footer {
      display: none;
    }

    .media-video {
      height: 100vh;
      margin: 0;
      .video-wrapper {
        margin: 0;
      }
    }
  }

  &.playing-slideshow.show-slideshow-controls .preview-header,
  &.playing-slideshow.show-slideshow-controls .preview-footer,
  &.playing-slideshow.paused .preview-header,
  &.playing-slideshow.paused .preview-footer,
  &.playing-slideshow .preview-header:hover,
  &.playing-slideshow .preview-footer:hover,
  &.playing-slideshow .preview-header:active,
  &.playing-slideshow .preview-footer:active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.playing-slideshow .preview-header {
    top: 0;
  }

  &.playing-slideshow .preview-footer {
    bottom: 0;
  }

  &.playing-slideshow:not(.paused) {
    & > .slideshow:not(.media-video) {
      height: 100%;

      & > img {
        object-fit: none;
      }
    }
  }

  /* &.show-header-footer:not(.fadeInAndOut) > .preview-header.mobile-view,
  &.show-header-footer:not(.fadeInAndOut) > .preview-footer.mobile-view {
    opacity: 0;
    pointer-events: none; 
  } */

  &.show-header-footer.fadeInAndOut > .preview-header.mobile-view,
  &.show-header-footer.fadeInAndOut > .preview-footer.mobile-view {
    animation: ${fadeInAndOut} 5s forwards;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const ImageContainer = styled.img`
  object-fit: scale-down;

  width: 100%;
  height: 100%;
`;

const MediaContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 128px);
  /* 
   * Important for video player and mobile devices 
   * which gave us wrong vh when browser address bar is visible.
   */
  height: calc(100dvh - 128px);

  @media (max-width: 768px) {
    height: calc(100vh - 112px);
    /* 
    * Important for video player and mobile devices 
    * which gave us wrong vh when browser address bar is visible.
    */
    height: calc(100dvh - 112px);
  }
`;

const SlideshowMessage = styled.div`
  z-index: 20000;
  text-align: center;

  width: 400px;
  margin-left: -200px;
  margin-top: -30px;

  background: rgba(0, 0, 0, 0.75);
  color: #fff;

  border-radius: 10px;

  top: 50%;
  left: 50%;

  position: absolute;

  text-align: center;
  font-size: 15px;
  line-height: 60px;

  animation: ${fadeInAndOut} 2s ease-in-out forwards;
`;
