import React from "react";
import Tooltip from "../../../../../Tooltip";
import { __ } from "../../../../../../lib/translate";
import { ButtonWrapper } from "../styles";
import Button from "../../../../../ButtonDefault";
import InputCheckbox from "../../../../../InputCheckbox";

const InitButton = ({
    disabledButton,
    type,
    status,
    passedNextExecutionDate,
    onButtonClick,
    isOverquota
}) => {
    const renderButton = () => {
        return (
            <ButtonWrapper>
                <Tooltip
                    title={__("backup_storage_exceeded")}
                    shouldRenderTooltip={disabledButton && isOverquota}
                >
                    <Button
                        size="bigger"
                        padding="12px 20px"
                        fontWeight="400"
                        textSize={15}
                        borderRadius="50"
                        minWidth="81px"
                        onClick={onButtonClick}
                        disabledColor="#eeeeee"
                        disabledTextColor="#b2b2b2"
                        disabled={disabledButton || status === "unavailable" || status === "deprecated"}
                    >
                        {__("Start")}
                    </Button>
                </Tooltip>
            </ButtonWrapper>
        );
    };

    if (type === "onetime") {
        if (
            disabledButton &&
            status !== "inactive" &&
            !passedNextExecutionDate
        ) {
            return (
                <Tooltip
                    title={__(
                        "back_available_info_tooltip",
                        "Backup available once every 30 days."
                    )}
                >
                    {renderButton()}
                </Tooltip>
            );
        } else {
            return <>{renderButton()}</>;
        }
    }

    const isDisabled =
        status === "unavailable" ||
        status === "deprecated" ||
        status === "running" ||
        status === "paused" ||
        (isOverquota && status !== "finished");
    return (
        <ButtonWrapper
            onClick={(e) => {
                if (isDisabled) {
                    e.stopPropagation();
                }
            }}
        >
            <Tooltip
                title={__("backup_storage_exceeded")}
                shouldRenderTooltip={disabledButton && isOverquota}
            >
                <InputCheckbox
                    name=""
                    checked={Boolean(
                        status === "running" ||
                            status === "paused" ||
                            status === "finished"
                    )}
                    onChange={onButtonClick}
                    color="cyan"
                    size="modern"
                    disabled={isDisabled}
                />
            </Tooltip>
        </ButtonWrapper>
    );
};

export default InitButton;
