export const appendLeftMenuOverlay = () => {
  let overlay = document.getElementById('page-mobile-menu-overlay');
  // Append the overlay to the DOM if it’s not already there
  if (!document.body.contains(overlay)) {
    overlay = document.createElement('div');
    overlay.id = 'page-mobile-menu-overlay';
    document.body.appendChild(overlay);
    removeHtmlElementScroll();
  }
  // Allow the fade-in effect by toggling the visible class
  requestAnimationFrame(() => {
    overlay.classList.add('visible');
  });
};

export const removeBackgroundOverlay = () => {
  const overlay = document.getElementById('page-mobile-menu-overlay');
  if (overlay) {
    overlay.classList.remove('visible');
    resetHtmlElementScroll();
    // After the transition ends, remove the overlay from the DOM
    overlay.addEventListener('transitionend', function onTransitionEnd() {
      overlay.removeEventListener('transitionend', onTransitionEnd);
      overlay.remove();
    });
  }
};

export const createTemporaryActiveLine = (top) => {
  const mainTabsPanel = document.getElementById('main-tabs-pannel');

  if (mainTabsPanel) {
    const appendedBlock = document.createElement('div');

    // Clone ActiveBlock styles
    appendedBlock.style.position = 'absolute';
    appendedBlock.style.height = '40px';
    appendedBlock.style.width = '240px';
    appendedBlock.style.background = 'var(--color-base-white)';
    appendedBlock.style.top = `${top - 64}px`;

    mainTabsPanel.appendChild(appendedBlock);

    // Remove the element after 0.5 seconds
    setTimeout(() => {
      mainTabsPanel.removeChild(appendedBlock);
    }, 500);
  }
};

export const removeHtmlElementScroll = () => {
  const htmlElement = document.documentElement;
  htmlElement.style.margin = '0';
  htmlElement.style.padding = '0';
  htmlElement.style.height = '100%';
  htmlElement.style.overflow = 'hidden';
};

export const resetHtmlElementScroll = () => {
  const htmlElement = document.documentElement;
  htmlElement.style.margin = '';
  htmlElement.style.padding = '';
  htmlElement.style.height = '';
  htmlElement.style.overflow = '';
};

export const isCryptoPage = (page) => ['cryptoset', 'cryptobuy', 'cryptobusiness', 'filemanager'].includes(page);

export const filteredMenuOptions = (menuOptions, menuOptionsToFilter) => {
  let menuOptionsCopy = { ...menuOptions };
  let menuKeys = menuOptionsToFilter.map(item => item.name);
  for (const key in menuOptionsCopy) {
    if (!menuKeys.includes(key)) {
      delete menuOptionsCopy[key];
    }
  }

  return menuOptionsCopy;
}