// @flow

import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Button, { ButtonCentered } from "./ButtonDefault";
import { __ } from "../lib/translate";
import InputText from "../components/InputText";
import InputSelect from "./InputSelect";
import Textarea from "./Textarea";
import Portal from "../components/Portal";
import Modal, {
  ModalTop,
  ModalBody,
  OvalModalWrap,
  OvalModalClose,
  OvalModalBody,
  OvalModalCloseSimple
} from "./Modal";
import apiMethod from "../api/apiMethod";
import InputFieldWithPrefix from "./InputFieldWithPrefix";
import TabWrapper from "./TabWrapper";
import { TSpan } from "./intl";
import CountryCodeToName from "../lib/countryUtils";
import EUCountries from "../lib/EUCountries";
import countryToVATPrefix from "../lib/countryToVATPrefix";
import { updateUserBillingInfo } from "../lib/state/actions/user";

const TAB_COMPANY = 1;
const TAB_PERSON = 2;

type selectMap = {
  value: number | string,
  text: string,
  disabled?: boolean
};

const getCountryOptionsArray = (
  countryCodeToNameMap: { countryCode: string },
  optionRowMap: selectMap
): Array<selectMap> => {
  var result = [];

  optionRowMap && result.push(optionRowMap);
  for (var countryCode in countryCodeToNameMap) {
    result.push({
      text: countryCodeToNameMap[countryCode],
      value: countryCode
    });
  }

  return result;
};

const COUNTRY_NOT_SELECTED = "0";


const isEUCountry = (
  countryCodeToNameMap: { key: string },
  country: string
): ?string => {
  return countryCodeToNameMap[country];
};

const showErrorAlert = (...args) => {
  HFN.message(...args);
};

var styles = {
  buttonMargin: {
    margin: "0 15px"
  },
  buttonMinWidth: {
    minWidth: "210px",
    marginTop: "15px"
  },
  buttonModalMinWidth: {
    minWidth: "125px"
  },
  closeButton: {
    top: "16px",
    right: "16px",
    marginTop: 0
  }
};

type BillingInfo = {
  billingAddress: string,
  vat?: string,
  country: string,
  company?: ?string,
  names?: ?string,
  type: number
};
type BillingInfoParams = {
  billingaddress: string,
  vat?: string,
  country: string,
  company?: ?string,
  names?: ?string,
  type: number,
};

type Props = {
  token?: string,
  isBusiness: boolean,
  billingInfo: BillingInfo,
  cta: string,
  manageExternal?: boolean,
  openedExternal?: boolean,
  onClose?: () => void
};

type State = {
  opened: boolean,
  cta: string,
  hasVat: boolean,
  country: string,
  company?: ?string,
  names?: ?string,
  vat: string,
  billingAddress: string,
  type: number
};

const removeCountryCodeFromVat = (vat: string) => vat.substr(2);

const paramsDto = ({ billingAddress, ...rest }) => ({
  billingaddress: billingAddress,
  ...rest
});

class InvoiceModal extends React.Component<Props, State> {
  static defaultProps = {
    token: "",
    isBusiness: false,
    billingInfo: {},
    cta: ""
  };

  constructor(props: Props) {
    super(props);

    const { billingInfo } = this.props;
    this.state = {
      opened: false,
      loading: false,
      cta: "",
      hasVat: !!billingInfo.vat,
      country: COUNTRY_NOT_SELECTED,
      vat: "",
      type: 1,
      ...billingInfo
    };

    (this: any).baseState = this.state;

    (this: any).close = this.close.bind(this);
    (this: any).open = this.open.bind(this);
    (this: any).onApiSuccessCallback = this.onApiSuccessCallback.bind(this);
    (this: any).updateInvoiceInfo = this.updateInvoiceInfo.bind(this);
    (this: any).isFieldValid = this.isFieldValid.bind(this);
    (this: any).onTabClick = this.onTabClick.bind(this);
  }

  componentDidMount() {
    if (HFN.getHash("update")) {
      this.open();
    }
    setTimeout(() => {
      $(".update-btn")
        .off("click")
        .on("click", () => {
          this.open();
        });
    }, 0);
  }

  open() {
    this.setState({ opened: true });
  }

  close() {
    this.resetForm();
    this.setState({ opened: false });

    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  resetForm() {
    this.setState((this: any).baseState);
  }

  isFieldValid(field: any) {
    return field && field.trim();
  }

  hasNonLatinSymbolsAndDigits(field: any): any {
    //match at least one latin letter
    const atLeastOneLatinLetter = /[a-zA-Z]/;
    const onlyLatinLettersNumbersAndSymbols = /^[a-zA-Z0-9\s~!@#$%^&*()_+{}|":?><|"'\\[\]`\/,.:\-;€§№]+$/;

    return (
      atLeastOneLatinLetter.test(field) &&
      onlyLatinLettersNumbersAndSymbols.test(field)
    );
  }

  hasMinThreeLetters(field: any): any {
    //match min len 3
    return field.trim().length >= 3;
  }

  hasOnlyLatinSymbols(field: any): any {
    //match latin symbols and spaces
    const onlyLatinLettersAndSymbols = /^[a-zA-Z.\-\s]+$/;

    return onlyLatinLettersAndSymbols.test(field.trim());
  }

  onTabClick(selectedTabIndex: number) {
    this.setState({ type: selectedTabIndex });
  }

  onApiSuccessCallback(params: any): any {
    this.props.updateUserBillingInfo(params);

    // remove Invoice info bar
    if (HFN.reactRemoveInvoiceInfoBar) {
      HFN.reactRemoveInvoiceInfoBar();
    }
  }

  buildParams() {
    const { type } = this.state;

    const typeParams = {
      "1": ["billingAddress", "company", "country", "type"],
      "2": ["billingAddress", "names", "country", "type"]
    };

    const params = typeParams[type].reduce((acc, paramName) => {
      acc[paramName] = this.state[paramName];

      return acc;
    }, {});

    return paramsDto(params);
  }

  updateInvoiceInfo(e: Event) {
    e.preventDefault();
    const {
      billingAddress,
      company,
      names,
      country,
      hasVat,
      vat,
      type
    } = this.state;
    console.log("Update Invoice Info Method. State: ", this.state);

    if (country === COUNTRY_NOT_SELECTED) {
      showErrorAlert(__("invoice_modal_error_select_country", "Please select a country!"), "error");
      return;
    }

    if (type === TAB_COMPANY) {
      if (!this.isFieldValid(company)) {
        showErrorAlert(__("invoice_modal_error_company_name", "Company name can't be empty!"), "error");
        return;
      } else if (!this.hasNonLatinSymbolsAndDigits(company)) {
        showErrorAlert(
          __("invoice_modal_error_company_name_latin_letters", "Company name should contain only latin letters and numbers!"),
          "error"
        );
        return;
      }
      if (hasVat && !this.isFieldValid(vat)) {
        showErrorAlert(__("invoice_modal_error_VAT_number", "VAT Number can't be empty!"), "error");
        return;
      }
    } else if (type === TAB_PERSON && !this.isFieldValid(names)) {
      showErrorAlert(__("invoice_modal_error_name", "Name can't be empty!"), "error");
      return;
    } else if (!this.hasMinThreeLetters(names)) {
      showErrorAlert(
        __("invoice_modal_error_name_least_tree_letters", "Name should contain at least 3 latin letters!"),
        "error"
      );
      return;
    } else if (!this.hasOnlyLatinSymbols(names)) {
      showErrorAlert(
        __("invoice_modal_error_name_only_latin", "Name should contain only latin letters!"),
        "error"
      );
      return;
    }

    if (!this.isFieldValid(billingAddress)) {
      showErrorAlert(__("invoice_modal_error_billing_adress", "Billing address can't be empty!"), "error");
      return;
    }

    //api call
    const { token } = this.props;

    const params = this.buildParams();
    params.auth = token;

    if (isEUCountry(EUCountries, country) && hasVat && type === TAB_COMPANY) {
      params.vat = vat;
    }

    this.setState({ loading: true });
    console.log("params", params);

    apiMethod(
      "updateinvoiceinfo",
      params,
      ret => {
        showErrorAlert(__("invoice_modal_info_updated", "Invoice information updated!"), "success");

        const paramsCallback = params;
        paramsCallback.billingAddress = paramsCallback.billingaddress;
        delete paramsCallback.auth;
        delete paramsCallback.billingаddress;

        this.onApiSuccessCallback({ ...paramsCallback });

        this.setState({ opened: false, loading: false });
        this.props.onClose();
        (this: any).baseState = this.state;
      },
      {
        errorCallback: ret => {
          const errCode = ret.result + "";
          const errs = {
            "1161": __("invoice_modal_error_incorrect_VAT", "Incorrect VAT")
          };

          showErrorAlert(errs[errCode] || ret.error, "error");
          this.setState({ loading: false });
        }
      }
    );
  }

  renderVatField() {
    const { country, vat, hasVat } = this.state;

    if (!isEUCountry(EUCountries, country)) {
      return null;
    }

    return (
      <VatWrapper key="vatField">
        <label>
          <input
            type="checkbox"
            name="hasVat"
            onChange={e => this.setState({ hasVat: e.target.checked })}
            checked={hasVat}
          />
          <TSpan id="vat_number">VAT number</TSpan>
        </label>
        <Span>
          <InputFieldWithPrefix
            prefix={countryToVATPrefix[country]}
            color={hasVat ? "#000" : "#8080808f"}
          />
          <InputText
            placeholder={__("VAT#", "VAT#")}
            name="vat"
            value={hasVat ? removeCountryCodeFromVat(vat) : ""}
            onChange={e => this.setState({ vat: countryToVATPrefix[country] + e.target.value })}
            disabled={hasVat ? "" : "disabled"}
          />
        </Span>
      </VatWrapper>
    );
  }

  renderInvoiceModal() {
    const { isBusiness, openedExternal } = this.props;
    const {
      type,
      country,
      company,
      names,
      billingAddress,
      opened,
      loading
    } = this.state;

    const selectCountryMessage = {
      text: __("please_select_country", "Please select a country"),
      value: COUNTRY_NOT_SELECTED,
      disabled: true
    };
    
    const CountryMap = getCountryOptionsArray(
      CountryCodeToName,
      selectCountryMessage
    );

    return (
      <form
        onSubmit={this.updateInvoiceInfo}
        id="invoice-modal"
        key="invoiceModal"
      >
        <Modal animate opened={openedExternal || opened} onClose={this.close}>
          <OvalModalWrap className="responsive-modal">
          <OvalModalCloseSimple style={styles.closeButton} onClick={this.close}/>
          <Title>{__("edit_invoice_info", "Edit Your Invoice Information")}</Title>
          <BodyWrapper>
            <TabWrapper
              showTabs={!isBusiness}
              className="tab_box"
              type={type}
              onTabClick={this.onTabClick}
            >
              <TabInner label={__("Company", "Company")}>
                <div>
                  <div>
                    <Span>
                      <InputSelect
                        name="country"
                        placeholder={__(
                          "please_select_country",
                          "Please select a country"
                        )}
                        options={CountryMap}
                        value={country}
                        onChange={e =>
                          this.setState({ country: e.target.value })
                        }
                      />
                    </Span>
                  </div>
                  <div>
                    <Span>
                      <InputText
                        placeholder={__("Company", "Company")}
                        name="company"
                        value={company}
                        onChange={e =>
                          this.setState({ company: e.target.value })
                        }
                      />
                    </Span>
                  </div>

                  {this.renderVatField()}

                  <div>
                    <Span>
                      <Textarea
                        placeholder={__("Billing Address", "Address")}
                        name="billingAddress"
                        value={billingAddress}
                        onChange={e =>
                          this.setState({ billingAddress: e.target.value })
                        }
                      />
                    </Span>
                  </div>
                </div>
              </TabInner>

              <TabInner label={__("ph_person", "Person")}>
                <div>
                  <div>
                    <Span>
                      <InputSelect
                        name="country"
                        placeholder={__(
                          "please_select_country",
                          "Please select a country"
                        )}
                        options={CountryMap}
                        value={country}
                        onChange={e =>
                          this.setState({ country: e.target.value })
                        }
                      />
                    </Span>
                  </div>
                  <div>
                    <Span>
                      <InputText
                        placeholder={__("ph_names", "Names")}
                        name="names"
                        value={names}
                        onChange={e => this.setState({ names: e.target.value })}
                      />
                    </Span>
                  </div>
                  <div>
                    <Span>
                      <Textarea
                        placeholder={__("Billing Address", "Address")}
                        name="billingAddress"
                        value={billingAddress}
                        onChange={e =>
                          this.setState({ billingAddress: e.target.value })
                        }
                      />
                    </Span>
                  </div>
                </div>
              </TabInner>
            </TabWrapper>
          </BodyWrapper>
          <ButtonWrapper>
            <Button
              style={{
                marginRight: "5px"
              }}
              color="lightgray4"
              onClick={this.close}
              inline
            >
              <TSpan id="Cancel">Cancel</TSpan>
            </Button>
            <Button
              style={{
                marginLeft: "5px"
              }}
              color="green"
              onClick={this.updateInvoiceInfo}
              loading={loading}
              inline
            >
              <TSpan id="Save">Save</TSpan>
            </Button>
          </ButtonWrapper>
          </OvalModalWrap>
        </Modal>
      </form>
    );
  }

  renderButton () {
    if (this.props.manageExternal) {
      return null;
    }

    return this.props.isBusiness ? (
      <ButtonLink key="openModalButton" onClick={this.open}>
        {this.props.cta}
      </ButtonLink>
    ) : (
      <ButtonCentered
        key="openModalButton"
        style={styles.buttonMinWidth}
        color="green"
        onClick={this.open}
        inline
      >
        {this.props.cta}
      </ButtonCentered>
    );
  }

  render() {
    return [
      this.renderButton(),
      this.renderInvoiceModal()
    ];
  }
}

export default connect(
  ({ user }) => {
    const { token, userinfo: { password, countryCode, msisdn } = {} } = user;

    return {
      token,
      password,
      countryCode,
      msisdn
    };
  },
  dispatch =>
    bindActionCreators(
      {
        updateUserBillingInfo,
      },
      dispatch
    )
)(InvoiceModal);

const Span = styled.span`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;

  input,
  select {
    margin-bottom: 10px;
  }

  input[name="vat"] {
    margin: 10px 0 20px 0;
    width: auto;
    padding-left: 50px;
    display: flex;
    flex: 1;

    @media (max-width: 500px) {
      width: 100%;
      padding-left: 35px;
    }
  }

  input[name="vat"]:disabled {
    font-weight: normal;
  }

  select {
    padding: 10px 5px;
  }

  textarea {
    min-height: 75px;
  }
`;

const TabInner = styled.li`
  list-style-type: none;
  font-size: 13px;
`;

const ButtonLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin: 30px 0 25px 0;
`
const ButtonWrapper = styled.div`
  display: flex;
  padding: 30px;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    a {
      margin-left: 0 !important;
      margin-right: 0;
    }

    a:first-child {
      margin-bottom: 10px;
    }
  }
`;

const BodyWrapper = styled.div`
  width: 480px;
  padding: 0 30px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const VatWrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    padding-bottom: 10px;
  }

  label > span {
    margin: 0 9px 0 6px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
`;
