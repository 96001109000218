import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputText from "../components/InputText";
import * as Style from "./SharedInvitationComponents";
import styles from "../styles/forms.less";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";
import { boldConverter } from "../lib/componentifyConverters";

const ChangeEmailChooseNewEmail = ({ code }) => {
  const [newemail, setNewEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const pickEmail = useCallback(
    (e) => {
      e.preventDefault();

      if (!newemail.length) {
        setError(__("provide_email", "Provide Email."));
        return;
      }

      if (!validateEmail(newemail)) {
        setError(__("invalid_email", "Invalid Email."));
        return;
      }

      setLoading(true);

      apiMethod(
        "sendnewchangemail",
        { code, newmail: newemail },
        (ret) => {
          setDone(true);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    },
    [code, newemail]
  );

  const renderForm = () => (
    <>
      <Style.FormLabel>{__("enter_new_email_for_pcloud_account", "Enter a new email address for your pCloud account")}</Style.FormLabel>
      <Style.Form method="post" onSubmit={pickEmail}>
      {error && <div className="error">{error}</div>}
      <InputText
          name="newemail"
          placeholder={__("new_email", "New email")}
          value={newemail}
          onChange={(e) => setNewEmail(e.target.value)}
          type="email"
        />
        <SubmitButton loading={loading} active={!loading}>
          {__("Continue")}
        </SubmitButton>
      </Style.Form>
    </>
  );

  const renderDone = () => (
    <Style.ComponentifyWrapper>
      <Componentify
        text={__(
          "change_email_mail_sent",
          "We've sent a link to your email <b>%newemail%</b> with further instructions.",
          { newemail }
        )}
        converters={[boldConverter]}
      />
    </Style.ComponentifyWrapper>
  );

  const renderNoCode = () => (
    <Style.WarningMessage>
      <WarnIcon />
      <Style.ComponentifyWrapper>
        <Componentify text={__("invalid_data_arrived")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.WarningMessage>
  );

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("change_email_btn")}</Style.Title>
      {!code ? renderNoCode() : done ? renderDone() : renderForm()}
    </Style.Container>
  );
};

ChangeEmailChooseNewEmail.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ChangeEmailChooseNewEmail;
