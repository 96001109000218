import React, { useEffect, useState } from "react";
import { MenuProvider } from "../../context/MenuContextProvider";
import useWindowDimensions from "../../../../lib/hooks/useWindowDimensions";
import MobileLayout from "./MobileLayout";
import DesktopLayout from "./DesktopLayout";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../config/constants";
import { PAGES_EMAIL_REDIRECTS } from "../../../../containers/EmailRedirectPagesContainer/constants";
import { getHashPage, toggleClassForPage } from "../../../../lib/utils";

export const disableSearchOnPages = ['time', 'trash']

const TopBar = () => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const [searchBarExpanded, setSearchBarExpanded] = useState(false);
    const [page, setPage] = useState(getHashPage());

    useEffect(() => {
        const handleHashChange = () => {
            setPage(getHashPage());
        };

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);


    if (page === 'puplink') {
        return null;
    }

    useEffect(() => {
        HFN.showRibbon();
    }, [isMobile]);

    useEffect(() => {
        const checkHashInItems = () => {
            toggleClassForPage(PAGES_EMAIL_REDIRECTS, "no-topbar");
        };

        checkHashInItems();

        window.addEventListener('hashchange', checkHashInItems);

        return () => window.removeEventListener('hashchange', checkHashInItems);
    }, []);

    return (
        <MenuProvider>
            {isMobile && (
                <MobileLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                />
            )}

            {!isMobile && (
                <DesktopLayout
                    searchBarExpanded={searchBarExpanded}
                    setSearchBarExpanded={setSearchBarExpanded}
                />
            )}
        </MenuProvider>
    );
};

export default TopBar;
