import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CloudDrop from '../../../root/img/svg/upload-manager/cloud-drop.svg';
import CloudDropDisabled from '../../../root/img/svg/upload-manager/cloud-drop-disabled.svg';
import { handleDisabledInfoModals, getFormattedPasteName, isUploadDisabledRedesign } from "./helpers";
import { CRYPTOLOCKED_PAGE, DEFAULT_PASTE_ITEM_NAME } from './constants';
import { __ } from "../../lib/translate"
import { ONBOARDING_ADD_ITEM, ONBOARDING_REMOVE_ITEM } from "../../lib/state/constants/onboarding";
import { ONBOARDING_ID_DRAG_DROP, ONBOARDING_ID_UPLOAD_BUTTON } from "../HighlightOnboardingComponent/constants";
import { useDispatch } from 'react-redux';
import DragAndDropContent from "../HighlightOnboardingComponent/Contents/DragAndDropContent";
import HighlightOnboardingComponent from "../HighlightOnboardingComponent";
import { getHashPage } from "../../lib";

const DropArea = ({ onUpload, random }) => {
  const dispatch = useDispatch();

  const checkIfCryptoLockPage = () => {
    const hashParams = new URLSearchParams(window.location.hash);
    return hashParams.get("#page") === CRYPTOLOCKED_PAGE;
  }

  const [page, setPage] = useState(getHashPage());
  const [dragging, setDragging] = useState(false);
  const [showOnBoarding, setShowOnBoarding] = useState(false);

  useEffect(() => {
    if (!HFN.config.isDlink() && !HFN.config.isMobile() && !isUploadDisabledRedesign()) {
      dispatch({
        type: ONBOARDING_ADD_ITEM,
        id: ONBOARDING_ID_DRAG_DROP
      });
    }

    const handleHashChange = () => {
      setPage(getHashPage());
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [page]);

  useEffect(() => {
    if (page === 'puplink') {
      return;
    }
    const handleDragOverGlobal = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const types = e.dataTransfer.types;
      if (types && types.length > 0) {
        const hasFiles = Array.from(types).includes('Files');
        if (!hasFiles || checkIfCryptoLockPage()) {
          e.dataTransfer.dropEffect = 'none';
        } else {
          e.dataTransfer.dropEffect = 'copy';
        }
      }
    }

    const handleDragEnterGlobal = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const types = e.dataTransfer.types;
      if (types && types.length > 0) {
        const hasFiles = Array.from(types).includes('Files');
        if (hasFiles) {
          setDragging(true);
        }

        dispatch({ type: ONBOARDING_REMOVE_ITEM, id: ONBOARDING_ID_UPLOAD_BUTTON });
      }
    };

    const handleDropGlobal = (e) => {
      e.preventDefault();
      e.stopPropagation();

      handleDisabledInfoModals(() => {
        // Both items and files will be needed
        if (!checkIfCryptoLockPage()) {
          onUpload({ items: e.dataTransfer, isDrop: true });
        }
      });

      setDragging(false);
    };

    // Add global event listeners
    document.addEventListener('dragenter', handleDragEnterGlobal);
    document.addEventListener('dragover', handleDragOverGlobal);
    document.addEventListener('drop', handleDropGlobal);
    document.addEventListener('paste', handlePaste);

    // Remove global event listeners on cleanup
    return () => {
      document.removeEventListener('dragenter', handleDragEnterGlobal);
      document.removeEventListener('dragover', handleDragOverGlobal);
      document.removeEventListener('drop', handleDropGlobal);
      document.removeEventListener('paste', handlePaste);
    };
  }, [random, page]);

  const handleDragLeave = (e) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return; // Ignore drag leave since it's within the container
    }
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const renderFolderName = () => {
    const meta = HFN.data.fflookup[`d${currentFolder}`]
    return HFN.metaName(meta);
  }

  // TODO - maybe improve to work for multiple files and folders
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let item of items) {
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          if (file.name === DEFAULT_PASTE_ITEM_NAME) {
            Object.defineProperty(file, 'name', {
              value: getFormattedPasteName(),
              writable: false
            });
          }
          handleDisabledInfoModals(() => {
            if (!checkIfCryptoLockPage()) {
              onUpload({ items: [file], pasteItemUrl: arrayBuffer });
            }
          });
        };
        reader.readAsDataURL(file);
        break; // Only handle the first image file
      }
    }
  };

  return (
    <Container
      onDragLeave={handleDragLeave}
      $isDragging={dragging || showOnBoarding}
    >
      {!showOnBoarding && (
        <>
          {isUploadDisabledRedesign() || checkIfCryptoLockPage() ?
              <DisabledCenteredContainer>
                <CloudDropDisabled />
                <CloudDropText>{__('upload_manager_droparea_unavailable', 'Upload currently unavailable.')}</CloudDropText>
                {checkIfCryptoLockPage() && <CloudDropText margin={0}>{__('upload_manager_droparea_crypto_locked', `Unlock the Crypto folder to enable uploading.`)}</CloudDropText>}
              </DisabledCenteredContainer> :
              <>
                <CentralCircle>
                  <CloudDrop />
                  <CloudDropText>{__('upload_manager_droparea_text', 'Drop to upload to %foldername% folder.').replace('%foldername%', renderFolderName())}</CloudDropText>
                </CentralCircle>
                <SurroundingCircle size={290} opacity={0.04} />
                <SurroundingCircle size={340} opacity={0.03} />
                <SurroundingCircle size={390} opacity={0.02} />
              </>
          }
          </>
      )}

      <OnboardingContainer $showOnBoarding={showOnBoarding}>
        <CentralCircle>
          <CloudDrop />
          <HighlightOnboardingComponent
              id={ONBOARDING_ID_DRAG_DROP}
              content={[<DragAndDropContent />]}
              notShowAfterClose={true}
              showOverlay={true}
              zIndex={10010}
              place={"bottom-right"}
              delay={500}
              onStartVisible={() => setShowOnBoarding(true)}
              onEndVisible={() => setShowOnBoarding(false)}
          >
            <CloudDropText>{__('upload_manager_droparea_text', 'Drop to upload to %foldername% folder.').replace('%foldername%', "/")}</CloudDropText>
          </HighlightOnboardingComponent>
        </CentralCircle>
        <SurroundingCircle size={290} opacity={0.04} />
        <SurroundingCircle size={340} opacity={0.03} />
        <SurroundingCircle size={390} opacity={0.02} />
      </OnboardingContainer>
    </Container>
  )
}

export default DropArea

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(142, 142, 148, 0.50);
  visibility: ${({ $isDragging }) => $isDragging ? 'visible' : 'hidden'};
  opacity: ${({ $isDragging }) => $isDragging ? 1 : 0};
  transition: visibility 0s, opacity 0.5s linear;
`;

const OnboardingContainer = styled.div`
  display: ${({ $showOnBoarding }) => $showOnBoarding ? 'block' : 'none'};
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const CentralCircle = styled.div`
  width: 240px;
  height: 240px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 120px);
  animation: ${pulse} 2s infinite ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SurroundingCircle = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - ${({ size }) => size / 2}px);
  left: calc(50% - ${({ size }) => size / 2}px);
  background-color: ${({ opacity }) => `rgba(0, 0, 0, ${opacity})`};
  animation: ${pulse} 2s infinite ease-in-out;
`;

const CloudDropText = styled.p`
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: ${({ margin }) => margin !== undefined ? margin : 10}px;
  text-align: center;
`

const DisabledCenteredContainer = styled.div`
  width: 240px;
  height: 240px;
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`