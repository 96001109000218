import { useCallback, useMemo, useRef, useEffect } from "react";

function findIndex(list, predicate) {
    if (list.findIndex) {
        return list.findIndex(predicate);
    }

    for (let i = 0; i < list.length; i++) {
        if (predicate(list[i])) {
            return i;
        }
    }
    return -1;
}

function find(list, predicate) {
    if (list.find) {
        return list.find(predicate);
    }
    const index = findIndex(list, predicate);
    if (index !== -1) {
        return list[index];
    }
    return undefined;
}

const supportedPageVisibilityEventName = (() => {
    const base = "visibilitychange";

    if (typeof document === "undefined") {
        return base;
    }

    const candidates = [
        base,
        `ms${base}`,
        `webkit${base}`,
        `moz${base}`,
        `o${base}`
    ];

    const supported = find(
        candidates,
        (eventName) => `on${eventName}` in document
    );

    return supported || base;
})();

const idle = { type: "IDLE" };
export const timeForLongPress = 10;
export const forcePressThreshold = 0.15;

function getWindowBindings({ cancel, getPhase }) {
    return [
        {
            eventName: "orientationchange",
            fn: cancel
        },
        {
            eventName: "resize",
            fn: cancel
        },
        {
            eventName: "contextmenu",
            fn: (event) => {
                event.preventDefault();
            }
        },
        {
            eventName: "keydown",
            fn: (event) => {
                if (getPhase().type !== "DRAGGING") {
                    cancel();
                    return;
                }

                if (event.key === "Escape") {
                    event.preventDefault();
                }
                cancel();
            }
        },
        {
            eventName: supportedPageVisibilityEventName,
            fn: cancel
        }
    ];
}

function getHandleBindings({ cancel, completed, getPhase }) {
    return [
        {
            eventName: "touchmove",
            options: { capture: false },
            fn: (event) => {
                const phase = getPhase();
                if (phase.type !== "DRAGGING") {
                    cancel();
                    return;
                }

                phase.hasMoved = true;

                const { clientX, clientY } = event.touches[0];

                const point = {
                    x: clientX,
                    y: clientY
                };

                event.preventDefault();
                phase.actions.move(point);
            }
        },
        {
            eventName: "touchend",
            fn: (event) => {
                const phase = getPhase();
                if (phase.type !== "DRAGGING") {
                    cancel();
                    return;
                }

                event.preventDefault();
                phase.actions.drop({ shouldBlockNextClick: true });
                completed();
            }
        },
        {
            eventName: "touchcancel",
            fn: (event) => {
                if (getPhase().type !== "DRAGGING") {
                    cancel();
                    return;
                }

                event.preventDefault();
                cancel();
            }
        },

        {
            eventName: "touchforcechange",
            fn: (event) => {
                const phase = getPhase();

                if (phase.type === "IDLE") {
                    throw Error("invariant");
                }

                const touch = event.touches[0];

                if (!touch) {
                    return;
                }

                const isForcePress = touch.force >= forcePressThreshold;

                if (!isForcePress) {
                    return;
                }

                const shouldRespect = phase.actions.shouldRespectForcePress();

                if (phase.type === "PENDING") {
                    if (shouldRespect) {
                        cancel();
                    }
                    return;
                }

                if (shouldRespect) {
                    if (phase.hasMoved) {
                        event.preventDefault();
                        return;
                    }
                    cancel();
                    return;
                }

                event.preventDefault();
            }
        },
        {
            eventName: supportedPageVisibilityEventName,
            fn: cancel
        }
    ];
}

function getOptions(shared, fromBinding) {
    return {
        ...shared,
        ...fromBinding
    };
}

function bindEvents(el, bindings, sharedOptions) {
    const unbindings = bindings.map((binding) => {
        const options = getOptions(sharedOptions, binding.options);

        el.addEventListener(binding.eventName, binding.fn, options);

        return function unbind() {
            el.removeEventListener(binding.eventName, binding.fn, options);
        };
    });

    return function unbindAll() {
        unbindings.forEach((unbind) => {
            unbind();
        });
    };
}

export default function useTouchSensor(api) {
    const phaseRef = useRef(idle);
    const unbindEventsRef = useRef(() => null);

    const getPhase = useCallback(() => {
        return phaseRef.current;
    }, []);

    const setPhase = useCallback((phase) => {
        phaseRef.current = phase;
    }, []);

    const startCaptureBinding = useMemo(
        () => ({
            eventName: "touchstart",
            fn: function onTouchStart(event) {
                if (event.defaultPrevented) {
                    return;
                }

                const draggableId = api.findClosestDraggableId(event);

                if (!draggableId) {
                    return;
                }

                const actions = api.tryGetLock(draggableId, stop, {
                    sourceEvent: event
                });

                if (!actions) {
                    return;
                }

                const touch = event.touches[0];
                const { clientX, clientY } = touch;
                const point = {
                    x: clientX,
                    y: clientY
                };
                const dragHandleId = api.findClosestDraggableId(event);
                if (!dragHandleId) {
                    throw Error(
                        "Touch sensor unable to find drag dragHandleId"
                    );
                }
                const handle = document.querySelector(
                    `[data-rbd-drag-handle-draggable-id='${dragHandleId}']`
                );
                if (!handle) {
                    throw Error("Touch sensor unable to find drag handle");
                }

                unbindEventsRef.current();

                startPendingDrag(actions, point, handle);
            }
        }),
        [api]
    );

    const listenForCapture = useCallback(
        function listenForCapture() {
            const options = {
                capture: true,
                passive: false
            };

            unbindEventsRef.current = bindEvents(
                window,
                [startCaptureBinding],
                options
            );
        },
        [startCaptureBinding]
    );

    const stop = useCallback(() => {
        const { current } = phaseRef;
        if (current.type === "IDLE") {
            return;
        }

        if (current.type === "PENDING") {
            clearTimeout(current.longPressTimerId);
        }

        setPhase(idle);
        unbindEventsRef.current();

        listenForCapture();
    }, [listenForCapture, setPhase]);

    const cancel = useCallback(() => {
        const phase = phaseRef.current;
        stop();
        if (phase.type === "DRAGGING") {
            phase.actions.cancel({ shouldBlockNextClick: true });
        }
        if (phase.type === "PENDING") {
            phase.actions.abort();
        }
    }, [stop]);

    const bindCapturingEvents = useCallback(
        function bindCapturingEvents(target) {
            const options = { capture: true, passive: false };
            const args = {
                cancel,
                completed: stop,
                getPhase
            };

            const unbindTarget = bindEvents(
                target,
                getHandleBindings(args),
                options
            );
            const unbindTargetWindow = bindEvents(
                window,
                getHandleBindings(args),
                options
            );
            const unbindWindow = bindEvents(
                window,
                getWindowBindings(args),
                options
            );

            unbindEventsRef.current = function unbindAll() {
                unbindTarget();
                unbindTargetWindow();
                unbindWindow();
            };
        },
        [cancel, getPhase, stop]
    );

    const startDragging = useCallback(
        function startDragging() {
            const phase = getPhase();
            if (phase.type !== "PENDING") {
                throw Error(`Cannot start dragging from phase ${phase.type}`);
            }

            const actions = phase.actions.fluidLift(phase.point);

            setPhase({
                type: "DRAGGING",
                actions,
                hasMoved: false
            });
        },
        [getPhase, setPhase]
    );

    const startPendingDrag = useCallback(
        function startPendingDrag(actions, point, target) {
            if (getPhase().type !== "IDLE") {
                throw Error("Expected to move from IDLE to PENDING drag");
            }

            const longPressTimerId = setTimeout(
                startDragging,
                timeForLongPress
            );

            setPhase({
                type: "PENDING",
                point,
                actions,
                longPressTimerId
            });

            bindCapturingEvents(target);
        },
        [bindCapturingEvents, getPhase, setPhase, startDragging]
    );

    useEffect(
        function mount() {
            listenForCapture();

            return function unmount() {
                unbindEventsRef.current();

                const phase = getPhase();
                if (phase.type === "PENDING") {
                    clearTimeout(phase.longPressTimerId);
                    setPhase(idle);
                }
            };
        },
        [getPhase, listenForCapture, setPhase]
    );

    useEffect(function webkitHack() {
        const unbind = bindEvents(window, [
            {
                eventName: "touchmove",
                fn: () => {},
                options: { capture: false, passive: false }
            }
        ]);

        return unbind;
    }, []);
}
