import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Componentify from "react-componentify";
import * as Style from "../SharedInvitationComponents";
import FamilyInviteIcon from "../../../root/img/svg/family_invite.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";
import RegistrationForm from "../UserAuthentication/InvitationRegistration/RegistrationForm";
import apiMethod from "../../api/apiMethod";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { __ } from "../../lib/translate";
import { getHash, formatSizeInGb, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { boldConverter, brTagConverter } from "../../lib/componentifyConverters";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import Button from "../../components/ButtonDefault";

const FamilyInvitation = () => {
  const user = useSelector(({ user }) => user);
  const {
    logged,
    token,
    userinfo
  } = user;
  const {
    subscriptions,
    premium,
    language,
    premiumlifetime,
    plan,
    business,
    email
  } = userinfo;
  const dispatch = useDispatch();

  const [holder, setHolder] = useState("");
  const [code, setCode] = useState("");
  const [invitationEmail, setInvitationEmail] = useState("");
  const [quota, setQuota] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    getInviteInfo();
  }, []);

  useEffect(() => {
    if (logged) {
      getInviteInfo();
    }
  }, [logged]);

  const onRegistration = ({ action, userinfo }) => {
    dispatch({ type: "USER_LOGIN", userinfo, token: userinfo.auth });
    HFN.userLoginAfter(userinfo, () => {
      setLoading(false);
      setError(getErrorMessage());
    });
  };

  const onLogout = () => {
    setError("");
    HFN.logout();
  };

  const getInviteInfo = () => {
    const inviteCode = getHash("code");
    const params = { code: inviteCode, auth: token };
    setLoading(true);

    apiMethod(
      "fm_invitationinfo",
      params,
      ({ invitermail, email, quota }) => {
        setCode(inviteCode);
        setHolder(invitermail);
        setInvitationEmail(email);
        setQuota(quota);
        setLoading(false);
        setError(getErrorMessage());
      },
      {
        errorCallback: ({ result, error }) => {
          setLoading(false);
          setError(
            getErrorMessage(
              result === 2012
                ? __("family_invite_cancelled_title")
                : result === 2336
                ? __("family_member_invite_relocation")
                : error
            )
          );
        }
      }
    );
  };

  const getPlanName = () => {
    if (plan === 17) {
      return __("plan_name_13" + (premiumlifetime ? "_lifetime" : ""));
    }

    let planNameKey = "plan_name_";
    planNameKey += plan || "_unknown";
    if (premiumlifetime) planNameKey += "_lifetime";
    else if (business) planNameKey = "plan_name_business";

    return __(planNameKey);
  };

  const getErrorMessage = (err) => {
    if (err) return err;
    return logged && !isSameLocation() ? __("family_invite_subscribed_message4") : "";
  };

  const cancelInvitation = () => {
    setLoading(true);
    apiMethod(
      "fm_cancelinvitation",
      { code, auth: token },
      () => {
        HFN.message("Invitation Cancelled", "success", true, true);
        setTimeout(() => window.open("https://my.pcloud.com", "_self"), 5000);
      },
      { errorCallback: ({ error }) => setLoading(false) }
    );
  };

  const join = () => {
    setLoading(true);
    apiMethod(
      "fm_acceptinvitation",
      { code, auth: token },
      () => window.open(URLSITE, "_self"),
      { errorCallback: ({ error }) => setLoading(false) }
    );
  };

  const renderLoading = () => (
    <Style.LoadingWrapper>
      <div />
    </Style.LoadingWrapper>
  );

  const renderMessages = (error) => {
    const planName = getPlanName();
    const quotaFormatted = formatSizeInGb(quota, 0);

    let title = __("family_invite_subscribed_header");
    let message;
    let Icon = WarnIcon;
    let MessageComponent = Style.WarningMessage;
    let showButtons;

    const hasSubscription = subscriptions && subscriptions.length > 0;
    const hasEditablePrSubscription =
        subscriptions &&
        subscriptions.some(subscription => {
          const premiumSubscription =
              subscription.products.indexOf(1) == -1 || subscription.products.indexOf(3) == -1;
          return subscription.canedit == true && premiumSubscription;
        });
    const hasPremiumLifetime = premiumlifetime && (plan == 1 || plan == 3);
    const hasPremiumSubscription = premium && !premiumlifetime;
    const hasFamilyPlan = plan == 14 || plan == 15;
    const hasBusiness = business;
    const hasCustomPlan = plan == 17 && (premiumlifetime || premium);

    if (logged && !isSameLocation()) {
      title = "";
      message = __("family_invite_subscribed_message4");
    } else if (error) {
      title = "";
      message = error;
      showButtons = false;
    } else if (hasPremiumLifetime || hasBusiness || hasFamilyPlan || hasCustomPlan) {
      message = __("family_invite_subscribed_message3");
      showButtons = false;
    } else if (hasFamilyPlan) {
      message = __("family_access_denied");
      showButtons = false;
    } else if (hasSubscription && !hasEditablePrSubscription) {
      message = __("family_invite_subscribed_message2");
      showButtons = true;
    } else if (hasPremiumSubscription) {
      message = __("family_invite_subscribed_message1");
      showButtons = true;
    } else {
      title = __("plan_name_14");
      message = __("family_invite_join_message");
      Icon = FamilyInviteIcon;
      MessageComponent = Style.Message;
      showButtons = true;
    }


    message = message
      .replace("%planname%", planName)
      .replace("%owneremail%", holder)
      .replace("%useremail%", email)
      .replace("%familyquota%", quotaFormatted);

    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{title}</Style.Title>
        <MessageComponent>
          <Icon fill="#17bed0" />
          <Style.ContainerCol>
            <Style.ComponentifyWrapper>
              <Componentify text={message} converters={[boldConverter, brTagConverter]} />
            </Style.ComponentifyWrapper>
          </Style.ContainerCol>
        </MessageComponent>
        {showButtons && (
            <Style.Footer>
              <Button
                  color="lightgray4"
                  borderRadius={8}
                  style={{
                    marginRight: "5px"
                  }}
                  onClick={cancelInvitation}
              >
                {__("Cancel")}
              </Button>
              <Button
                  color="cyan"
                  borderRadius={8}
                  style={{
                    marginLeft: "5px"
                  }}
                  onClick={join}
              >
                {__("Join")}
              </Button>
            </Style.Footer>
        )}
        {logged && <Style.Link onClick={onLogout}>{__("family_invite_subscribed_login")}</Style.Link>}
      </Style.Container>
    );
  };

  const renderRegister = () => {
    const quotaFormatted = formatSizeInGb(quota, 0);
    const message = __("family_invite_subheadline")
      .replace("%planowner%", holder)
      .replace("%familyquota%", quotaFormatted);
    const locationFromUrl = getLocationFromUrl() || {};

    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{__("plan_name_14")}</Style.Title>
        <Style.Message>
          <FamilyInviteIcon width="32" height="21" fill="#17bed0" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.Message>
        <Style.Wrapper className="family-invitation-reg-wrapper">
          <RegistrationForm
            title={__("family_invite_description")}
            formWidth="360px"
            email={invitationEmail}
            language={language}
            shouldRenderNames={false}
            submitButtonText={__('create account')}
            locations={[locationFromUrl]}
            initLoginRegModal={HFN.initLoginRegModal.bind(HFN)}
            onSuccess={onRegistration}
          />
        </Style.Wrapper>
      </Style.Container>
    );
  };

  return loading ? renderLoading() : logged || error ? renderMessages(error) : renderRegister();
};

export default FamilyInvitation;
