// @flow
import React, { useState, Fragment, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import Componentify from "react-componentify";
import {boldConverter, linkConverter, linkConverterV2, underlineConverter} from "../lib/componentifyConverters";

import "react-toastify/dist/ReactToastify.css";
import { ButtonLink } from "../containers/UserAuthentication2/MultiplatformComponents/CommonStyles";

type Props = {
  message: string,
  type: "success" | "error" | "custom",
  autoClose: boolean,
  skipComponentifyConverters: boolean,
  theme: "dark" | "light",
  buttonText: string,
  onButtonClick: () => void
};

const SystemNotificationPopup = ({
  message,
  type = "success",
  autoClose = "true",
  skipComponentifyConverters = false,
  enableConverters = {},
  theme = "dark",
  buttonText = "",
  onButtonClick = () => {},
}: Props) => {
  const linkConverterOptions = Object.assign({
    boldConverter: true,
    linkConverter: true,
    linkConverterV2: true,
    underlineConverter: true
  }, enableConverters)

  useEffect(() => {
    const options = {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose,
      hideProgressBar: true,
      closeOnClick: true
    };
    const converters = [];

    if (!skipComponentifyConverters) {
      if (linkConverterOptions.boldConverter) converters.push(boldConverter);
      if (linkConverterOptions.linkConverter) converters.push(linkConverter);
      if (linkConverterOptions.linkConverterV2) converters.push(linkConverterV2);
      if (linkConverterOptions.underlineConverter) converters.push(underlineConverter);
    }

    if (type === "success") {
      toast.success(<Componentify text={message} converters={converters} />, options);
    } else if (type === "error") {
      toast.error(<Componentify text={message} converters={converters} />, options);
    } else if (type === "custom") {
      toast.success(
        <>
          <Componentify
            text={message}
            converters={converters}
          />
          <LinkButtonCustom
              onClick={() => {
                onButtonClick();
              }}
            >
              {__(buttonText)}
          </LinkButtonCustom>
        </>,
        options
      );
    } else {
      toast.error(
        <Componentify
          text={message}
          converters={converters}
        />,
        options
      );
    }
  }, [message, type]);

  return (
    <Wrapper theme={theme}>
      <ToastContainer />
    </Wrapper>
  );
};

export default SystemNotificationPopup;

const Wrapper = styled.div`
  .Toastify__toast {
    border-radius: 4.8px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 25px;
    min-width: 400px;
    max-width: 600px;
    color: ${({ theme }) => (theme === "dark" ? "#fff !important" : "#000 !important")};
    a {
      color: ${({ theme }) => (theme === "dark" ? "#fff !important" : "#000 !important")};
    }
    @media (max-width: 480px) {
      min-width: 90%;
      max-width: 90%;
      margin: 0 auto 10px auto;
    }
  }

  .Toastify__toast-container {
    z-index: 250000;
    width: auto;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => (theme === "dark" ? "#212225" : "#fff")};
  }

  .Toastify__toast--error {
    background-color: #d92d2d;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 10px;
    height: 17px;
  }
`;

const LinkButtonCustom = styled(ButtonLink)`
  padding-left: 8px;
  color: #17bed0;
`;
