// @flow

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Componentify from "react-componentify";

import * as Style from "../../containers/SharedInvitationComponents";
import ModalSignin from "../../containers/UserAuthentication/ModalSignin";
import AcceptInviteToFolder from "../AcceptInviteToFolder";
import FolderInviteIcon from "../../../root/img/svg/invite_to_folder_login.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";
import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import { getHash, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { boldConverter, brTagConverter, linkConverterV2 } from "../../lib/componentifyConverters";
import { LOCATION_REGIONS } from "@pcloud/web-utilities/dist/config/constants";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";

const FolderInvitation = () => {
    const user = useSelector(({ user }) => user);
    const {
        logged,
        userRegionId,
        userinfo
    } = user;
    const { email } = userinfo;

  const [shareData, setShareData] = useState(null);
  const [holder, setHolder] = useState("");
  const [folderName, setFolderName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const getErrorMessage = useCallback(
    (message, folderName, holder) => {
      let errorMessage = message || "";
      if (!message && logged && !isSameLocation()) {
        errorMessage = __("folder_invitation_message_another_region")
          .replace("%folder%", folderName)
          .replace("%email%", holder)
          .replace("%learnmore%", `${URLSITE}data-regions/`);
        return errorMessage;
      }
      return __(message)
    },
    [logged]
  );

  const getInviteInfo = useCallback(() => {
    const code = getHash("code");

    apiMethod(
      "sharerequestinfo",
      { code },
      ({ share }) => {
        share.code = code;
        const { frommail, sharename, tomail = "" } = share;
        let errorMessage = "";

        if (logged && tomail && tomail.toLowerCase() !== email.toLowerCase()) {
          errorMessage = __("cannot_accept_share", "You can't accept this share. <br> This share is sent to %email%", {
            email: tomail,
          });
        }

        setShareData(share);
        setHolder(frommail);
        setFolderName(sharename);
        setError(getErrorMessage(errorMessage, sharename, frommail));
        setLoading(false);
      },
      {
        showErrorMessage: false,
        errorCallback: ({ result, error }) => {
          setLoading(false);
          const errorMsg = result === 2012
            ? __("family_invite_cancelled_title")
            : getErrorMessage(error);
          setError(errorMsg);
        },
      }
    );
  }, [logged, email, getErrorMessage]);

  useEffect(() => {
    getInviteInfo();
  }, [getInviteInfo]);

  const onSuccess = useCallback(
    ({ userinfo }) => {
      HFN.userLoginAfter(userinfo, () => {
        setError(getErrorMessage("", folderName, holder));
      });
    },
    [folderName, holder, getErrorMessage]
  );

  const onLogout = () => {
    HFN.logout();
    setError("");
  };

  const renderLoading = () => (
    <Style.LoadingWrapper>
      <div />
    </Style.LoadingWrapper>
  );

  const renderErrorMessage = () => (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("Attention")}</Style.Title>
      <Style.WarningMessage>
        <WarnIcon />
        <Style.ComponentifyWrapper>
          <Componentify text={error} converters={[boldConverter, brTagConverter, linkConverterV2]} />
        </Style.ComponentifyWrapper>
      </Style.WarningMessage>
      {logged && (
        <Style.Link onClick={onLogout}>
          {__("family_invite_subscribed_login", "Log in with a different account")}
        </Style.Link>
      )}
    </Style.Container>
  );

  const renderForm = () => {
    const message = __("folder_invitation_subheadline")
      .replace("%email%", holder)
      .replace("%folder%", folderName);

    const title = {
      login: __("invite_to_folder_invite_signin_title"),
      register: __("invite_to_folder_invite_signup_title"),
    };

    const label = LOCATION_REGIONS[userRegionId];
    const region = __(label.key, label.text);
    const beforeRegisterMessage = __("folder_invitation_message_before_continue")
      .replace("%folder%", folderName)
      .replace("%dataregion%", region);
    const locationFromUrl = getLocationFromUrl() || {};

    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{__("Share Folder")}</Style.Title>
        <Style.WarningMessage>
          <FolderInviteIcon width="32" fill="#ff9700" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.WarningMessage>
        <Style.Wrapper>
          <ModalSignin
            title={title}
            locations={[locationFromUrl]}
            beforeRegisterMessage={beforeRegisterMessage}
            onSuccess={onSuccess}
            onForgotPassword={HFN.config.label.forgotPassword.bind(HFN)}
            isLoginModal={false}
          />
        </Style.Wrapper>
      </Style.Container>
    );
  };

  return loading ? renderLoading() : error ? renderErrorMessage() : logged ? <AcceptInviteToFolder shareData={shareData} /> : renderForm();
};

export default FolderInvitation;
