import React from "react";
import { Provider } from "react-redux";
import Clipboard from "clipboard";

import store from "../lib/state/store";
import actionCreators from "../lib/state/actions";
import { userState, contentState, notificationsState, quotaState, sharedFoldersState, businessState } from "../lib/state";

import UserHeader from "../components/UserHeader";
import UserDropdown from "../components/UserDropdown";
import UserPassHeader from "../components/UserPassHeader";
import Notifications from "../components/Notifications";
import FooterSmall from "../components/FooterSmall";
import FooterWrap from "../components/FooterWrap";
import EmbedDirectlink from "../components/EmbedDirectlink";
//import UploadLink from "../containers/UploadLink";
import DLStatsSidebar from "../containers/DLStatsSidebar";
import DeactivateAccount from "../containers/DeactivateAccount";
import ChangeEmailConfirmation from "../containers/ChangeEmailConfirmation";
import ChangeEmailChooseNewEmail from "../containers/ChangeEmailChooseNewEmail";
import ModalSignin from "../containers/UserAuthentication/ModalSignin";
import LoginOrSignup from "../containers/UserAuthentication2/LoginOrSignup";
import Signin from "../containers/UserAuthentication/Signin";
import Signup from "../containers/UserAuthentication/Signup";
import Registration from "../containers/UserAuthentication/Registration";
import SubscriptionsSettingsContainer from "../containers/SubscriptionsSettingsContainer";
import PublicFolderPage from "../containers/PublicFolderPage";
import OpenInApp from "../components/OpenInApp";
import ContactForm from "../components/ContactForm";
import PublicLinkPreview from "../components/PublicLinkPreview";
import BonusSteps from "../components/journey/BonusSteps";
import NotificationsPopup from "../components/NotificationsPopup";
import FinanceContainer from "../containers/FinanceContainer";
import DriveUpdateModal from "../components/DriveUpdateModal";
import NewPrivacyPolicy from "../containers/NewPrivacyPolicy";
import CookieConsent from "../components/CookieConsent";
import InviteFriendsContainer from "../containers/InviteFriendsContainer";
import StorageQuotaFullModal from "../components/StorageQuotaFullModal";
import UnlockSpaceSteps from "../components/unlockspace/UnlockSpaceSteps";
import VivacomRegistration from "../containers/VivacomRegistration";
import UnverifiedUsersAlert from "../components/UnverifiedUsersAlert";
import PromoModal from "../components/PromoModal";
import FamilyContainer from "../containers/FamilyPlan/FamilyContainer";
import FamilyInvitation from "../containers/FamilyPlan/FamilyInvitation";
import AuthorizeOAuth2 from "../components/AuthorizeOAuth2";
import UseAppModal from "../containers/Mobile/UseAppModal";
import BrandingContainer from "../containers/BrandingLinksSettings/BrandingContainer";
import BusinessInvitation from "../containers/Business/BusinessInvitation";
import RegistrationForm from "../containers/UserAuthentication/InvitationRegistration/RegistrationForm";
import Button from "../components/ButtonDefault";
import EditBrandingModal from "../containers/BrandingLinksSettings/EditBrandingModal";
import SearchBar from "../components/SearchBar";
import PhotoSearchInput from "../components/PhotoSearchInput";
import OpenAppButton from "../containers/Mobile/OpenAppButton";
import DownloadLinkModal from "../components/DownloadLinkModal";
import ShareModal from "../components/shares/ShareModal";
import FolderInvitation from "../components/shares/FolderInvitation";
import ChangeUserOrTeamCryptoPass from "../containers/ChangeUserOrTeamCryptoPass";
import Alert from "../components/Alert";
import UpdateCrypto from "../containers/CryptoPages/UpdateCrypto";
import CryptoLockButton from "../components/CryptoLockButton";
import * as FooterRegistration from "../components/FooterRegistration";
import AppleSigninDrive from "../components/AppleSigninDrive";
import ShareSettingsModal from "../containers/ShareLinkSettings/ShareSettingsModal";
import InfoModal from "../components/InfoModal";
import EditBookmarkModal from "../containers/Bookmarks/EditBookmarkModal";
import EditBookmarkTooltip from "../containers/Bookmarks/EditBookmarkTooltip";
import DeleteBookmarkModal from "../containers/Bookmarks/DeleteBookmarkModal";
import ExportBookmark from "../containers/Bookmarks/ExportBookmark";
import SystemNotificationPopup from "../components/SystemNotificationPopup";
import CreditCardModal from "../containers/PaymentForm/CreditCardModal";
import LeftMenuBanner from "../components/LeftMenuBanner";
import BackupsContainer from "../components/BackupsContainer";
import DesktopInfo from "../containers/Backups/DesktopInfo";
import MobileInfo from "../containers/Backups/MobileInfo";
import DeleteItemModal from "../components/Modals/DeleteItemModal";
import DeleteLinkModal from "../components/Modals/DeleteLinkModal";
import StopBackupModal from "../components/StopBackupModal";
import SpeedTestContainer from "../containers/SpeedTest/SpeedTestContainer";
import BillingPageContainer from "../containers/Business/BillingPage/BillingPageContainer.js";
import EmailVerifyModal from "../containers/EmailVerifyModal.js";
import CreateFolderModal from "../components/Modals/CreateFolderModal.js";
import EditUserModal from "../components/Modals/Business/EditUserModal.js";
import EditTeamNameModal from "../components/Modals/Business/EditTeamNameModal.js";
import ChangeTeamStatusModal from "../components/Modals/Business/ChangeTeamStatusModal.js";
import SetTeamHeadModal from "../components/Modals/Business/SetTeamHeadModal.js";
import RemoveUserFromTeamModal from "../components/Modals/Business/RemoveUserFromTeamModal.js";
import InviteUsersToTeamModal from "../components/Modals/Business/InviteUsersToTeamModal.js";
import InviteUsersToTeamsModal from "../components/Modals/Business/InviteUsersToTeamsModal.js";
import ResetMemberPassModal from "../components/Modals/Business/ResetMemberPassModal.js";
import DeactivateMemberModal from "../components/Modals/Business/DeactivateMemberModal.js";
import DeactivatePublicFolderPage from "../components/Modals/DeactivatePublicFolderPage.js";
import DeleteLogoModal from "../components/Modals/Business/DeleteLogoModal.js";
import CreateNewTeamModal from "../components/Modals/Business/CreateNewTeamModal.js";
import EditCompanyInfoModal from "../components/Modals/Business/EditCompanyInfoModal.js";
import RequestMoreSpaceModal from "../components/Modals/Business/RequestMoreSpaceModal.js";
import RequestMoreSpaceModalBusinessPro from "../components/Modals/Business/RequestMoreSpaceModalBusinessPro.js";
import InviteUsersToAccountModal from "../components/Modals/Business/InviteUsersToAccountModal.js";
import CancelInvitationModal from "../components/Modals/Business/CancelInvitationModal.js";
import CopyInviteLinkModal from "../components/Modals/Business/CopyInviteLinkModal.js";
import CopyModal from "../components/Modals/CopyModal.js";
import CopyToCloudModal from "../components/Modals/CopyToCloudModal.js";
import MoveModal from "../components/Modals/MoveModal.js";
import CreateArchiveModal from "../components/Modals/CreateArchiveModal.js";
import PreviewArchiveModal from "../components/Modals/PreviewArchiveModal.js";
import ExtractArchiveModal from "../components/Modals/ExtractArchiveModal.js";
import AcceptShareModal from "../components/Modals/AcceptShareModal.js";
import DeclineShareModal from "../components/Modals/DeclineShareModal.js";
import RemoveShareModal from "../components/Modals/RemoveShareModal.js";
import StopAllSharesModal from "../components/Modals/StopAllSharesModal.js";
import ActivateUserModal from "../components/Modals/Business/ActivateUserModal.js";
import DLUsageStats from "../containers/DLUsageStats";
import FileRequestModal from "../containers/FileRequest/FileRequestModal.js";
import RenameModal from "../components/Modals/RenameModal.js";
import DeleteCommentModal from "../components/Modals/DeleteCommentModal.js";
import ItemInfoModal from "../components/Modals/ItemInfoModal.js";
import DownloadArchiveModal from "../components/Modals/DownloadArchiveModal.js";
import GetPublickLinkModal from "../components/Modals/GetPublickLinkModal.js";
import ManageMemberQuotaModal from "../components/Modals/Business/ManageMemberQuotaModal.js";
import ContentView from "../components/ContentView/ContentView.js";
import LeaveAccountModal from "../components/Modals/LeaveAccountModal.js";
import CreatePlaylistModal from "../components/Player/Playlist/CreatePlaylistModal.js";
import DeletePlaylistModal from "../components/Player/Playlist/DeletePlaylistModal.js";
import RenamePlaylistModal from "../components/Player/Playlist/RenamePlaylistModal.js";
import AddToPlaylistModal from "../components/Player/Playlist/AddToPlaylistModal.js";
import TrashRestoreModal from "../components/Modals/Trash/TrashRestoreModal.js";
import TrashDeleteModal from "../components/Modals/Trash/TrashDeleteModal.js";
import EmptyTrashModal from "../components/Modals/Trash/EmptyTrashModal.js";
import RestoreRevisionTimeModal from "../components/Modals/RestoreRevisionTimeModal.js";
import RevertRevisionModal from "../components/Modals/RevertRevisionModal.js";
import RestoreRevisionAsNewFileModal from "../components/Modals/RestoreRevisionAsNewFileModal.js";
import LoginRegPage from "../containers/UserAuthentication2/LoginRegPage";
import PCloudServicesDropdown from "../../web-shared/components/PCloudServicesDropdown.js";
import RemoteuploadExceededModal from "../components/Modals/RemoteuploadExceededModal.js";
import DownloadVideoModal from "../components/Modals/DownloadVideoModal.js";
import DownloadImageSizesModal from "../components/Modals/DownloadImageSizesModal.js";
import PreviewDocErrorModal from "../components/Modals/PreviewDocErrorModal.js";
import PreviewGenericModal from "../components/Modals/PreviewGenericModal.js";
import PreviewDocModal from "../components/Modals/PreviewDocModal.js";
import PreviewDocumentModal from "../components/Modals/PreviewDocumentModal.js";
import NoPdfReaderModal from "../components/Modals/NoPdfReaderModal.js";
import GenerateSharedLinkModal from "../components/Modals/GenerateSharedLinkModal.js";
import PreviewFileCodeModal from "../components/Modals/PreviewFileCodeModal.js";
import VerifyEmailPage from "../components/VerifyEmailPage";
import EmailRedirectPagesContainer from "../containers/EmailRedirectPagesContainer/index.js";
import UseAppContainer from "../containers/Mobile/UseAppContainer";
import BreadcrumbDropdown from "../components/BreadcrumbDropdown";
import AudioPlayer from "../components/Player/AudioPlayer";
import TopBar from "../components/ContentView/UI/TopBar";
import UploadManagerContainer from "../components/UploadManager/UploadManagerContainer";
import UploadContextProvider from "../components/UploadManager/context/UploadContextProvider.js";
import UploadButton from "../components/UploadManager/UploadButton";
import DropArea from "../components/UploadManager/DropArea";
import GalleryModal from "../containers/Gallery/GalleryModal";
import VideoContainer from "../containers/Gallery/VideoContainer";
import LeftMenuContainer from "../components/LeftMenu/LeftMenuContainer.js";
import PromoStickyBar from "@pcloud/web-utilities/dist/resources/components/PromoSticky/PromoStickyBar";
import BetaModal from "../components/Modals/BetaModal.js";
import CryptoLock from "../containers/CryptoPages/CryptoLock";
import CryptoBuy from "../containers/CryptoPages/CryptoBuy";
import CryptoBusiness from "../containers/CryptoPages/CryptoBusiness";
import CryptoSet from "../containers/CryptoPages/CryptoSet";
import ChatBot from "../components/ChatBot.js";
import SettingsContainer from "../components/Settings/SettingsContainer";
import TimeHome from "../containers/TimeHome";
import ItemsInfoPanel from "../components/ItemsInfoPanel/ItemsInfoPanel";

import * as utils from "../lib/utils";
import * as icons from "../lib/icons";
import * as libs from "../lib";
import * as apiAll from "../api";
import { downloadLinkFile, US_API, EU_API, setDefaultLocations } from '../api';
import { apiMethodUrl, apiMethodParams } from '../api/utils';
import cache from "@pcloud/web-utilities/dist/api/cache";
import * as constants from "@pcloud/web-utilities/dist/config/constants";
import * as linkstats from "../lib/linkstats";
import * as sendanalyticsevent from "../lib/sendAnalyticsEvent"
import {
  initTracking,
  setTracking,
  FBQ_FLAG,
  GA_FLAG,
  initAnalytics,
  initTiktokTracking,
  initFacebook,
  initTwitter
} from "../lib/tracking";
import * as ContentData from "../components/ContentView/ContentData";

import { LOGIN_EXPIRED_CODES } from "@pcloud/web-utilities/dist/api/errors";

import componentMount from "../lib/componentMount";

import trackTooltip from "../lib/tracktooltip-link";
import FBLogin from "../lib/fb_login";
import GLLogin from "../lib/gl_login";
import APPLELogin from "../lib/apple_login";
import { cryptoGetNavCmd, processNavCmd } from "../lib/navigation";

import * as crypto from "../lib/crypto";
import { sendCJTokenOnLogin } from "../../web-shared/cjAffiliate";
import * as prices from "../../web-shared/prices";
import * as sharedConstants from "../../web-shared/constants";
import * as sharedUtils from "../../web-shared/utils";
import * as sharedPlansUtils from "../../web-shared/plansUtils";

import { initCrashlytics } from "@pcloud/web-utilities/dist/lib/crashlyticsReports";

import {
  pushSubscribe,
  //initSubscribeModal,
  initYellowLineQuestion,
  initPushNotifications,
  initPushNotificationsLogin,
  initBasicNotifications
} from "../lib/notifications/pushSubscribe";

import { getToken } from "../lib/notifications/push";

HFN.pushSubscribe = pushSubscribe;
//HFN.initSubscribeModal = initSubscribeModal;
HFN.initYellowLineQuestion = initYellowLineQuestion;
HFN.getPushToken = getToken;
HFN.initPushNotifications = initPushNotifications;
HFN.initPushNotificationsLogin = initPushNotificationsLogin;
HFN.initBasicNotifications = initBasicNotifications;
HFN.initTracking = initTracking;
HFN.setTracking = setTracking;
HFN.initAnalytics = initAnalytics;
HFN.initTiktokTracking = initTiktokTracking;
HFN.initFacebook = initFacebook;
HFN.initTwitter = initTwitter;

HFN.cryptoGetNavCmd = cryptoGetNavCmd;
HFN.processNavCmd = processNavCmd;
HFN.cache = cache;

self.prc = self.prc || {};
self.FBLogin = FBLogin;
self.GLLogin = GLLogin;
self.APPLELogin = APPLELogin;
self.Clipboard = Clipboard;

HFN.LOGIN_EXPIRED_CODES = LOGIN_EXPIRED_CODES;

import "../styles/b.less";
import "../styles/farsi.less";
import "../styles/coversafe.less";
import "../styles/main-styles.less";
import "../styles/appearance.less";

import { URLSITE, URLMY } from "@pcloud/web-utilities/dist/config";
import { CRYPTO_LANDING_URL, BUY_LINK_TRAFFIC_URL } from "@pcloud/web-utilities/dist/config/constants";
import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";
import PlayerContextProvider from "../components/Player/context/PlayerContextProvider";

HFN.config.URLSITE = URLSITE;
HFN.CRYPTO_LANDING_URL = CRYPTO_LANDING_URL;
HFN.BUY_LINK_TRAFFIC_URL = BUY_LINK_TRAFFIC_URL;
HFN.getPromoParam = getPromoParam;
HFN.config.URLMY = URLMY;
HFN.config.GA_FLAG = GA_FLAG;
HFN.config.FBQ_FLAG = FBQ_FLAG;

HFN.state = {
  dispatch: (...args) => store.dispatch(...args),
  getState: (...args) => store.getState(...args),
  subscribe: (...args) => store.subscribe(...args),
  actions: actionCreators
};

const _attachList = [
  {
    attach: HFN,
    list: [
      "apiMethod",
      "initApi",
      "apiConfig",
      "apiMethodUrl",
      "apiMethodParams",
      "setDefaultLocations",
      "isEULocation",
      "downloadLinkFile",
      "formatSize",
      "formatSizeInGb",
      "formatDt",
      "percentage",
      "toParseDt",
      "formatDtTime",
      "formatTime",
      "prepDt",
      "getFileIcon",
      "ICONS",
      "iconTypes",
      "createIconSrc",
      "createImage",
      "createIcon",
      "ICO",
      "METHODS",
      "ERRORS",
      "ERROR_MESSAGE",
      "ERROR_MESSAGE_KEYS",
      "displayDownloadLinkTraffic",
      "getDownloadLinkTrafficSummary",
      "showDownloadLinkTrafficSummary",
      "diffHandler",
      "subscriptions__",
      "events",
      "mynotifications",
      "getSearch",
      "getHash",
      "isSameLocation",
      "getHostNameByLocationId",
      "getLocationFromUrl",
      "PROD_HOSTNAMES",
      "langu",
      //"dLink",
      "CATEGORY",
      "CATEGORY_TEXT",
      "LOCATION_REGIONS",
      "EU_LOCATIONID",
      "US_LOCATIONID",
      "US_API",
      "EU_API",
      "CHANGE_DATA_REGION_STATUS",
      "LS_CRYPTO_TRANSITION_STARTED",
      "LS_CRYPTO_TRANSITION_NOTIFIED_USERS_KEY",
      "CRYPTO_LANDING_URL",
      "BUY_LINK_TRAFFIC_URL",
      "PROVIDERS_IDS",
      "lockCrypto",
      "FooterRegistration",
      "sendAnalyticsEvent",
      "sendAnalyticsEventFromStorage",
      "copyTextToClipboard",
      "loadScript",
      "syncAuthCookie",
      "diffDates",
      "sendCJTokenOnLogin",
      "renderQuota",
      "BUSINESS_MIN_USER_QUOTA",
      "BUSINESS_MIN_FREE_OWNER_QUOTA",
      "getPlanTtraffic",
      "getUserTtrafficCount",
      "getCookiesFromSibling",
      "getLocationById",
      "checkPassToken",
      "getPromoParam",
      // web-utils
      "initCrashlytics",
      "getViewType"
    ]
  },
  {
    attach: self,
    list: [
      "noop",
      "fileext",
      "mimetypes",
      "range",
      "getTimeZone",
      "getUTCOffset",
      "obLength",
      "getUTCOffsetMiliseconds",
      "map",
      "each",
      "filter",
      "htmlentities",
      "validateEmail",
      "validateName",
      "__e",
      "obMegaLength",
      "Popup",
      "dropDown",
      "singleChoiceDropdown",
      "popOver",
      "uniqueNum",
      "MobilePopup",
      "rcookie",
      "setcookie",
      "currencyPrices",
      "PREMIUM_PLAN_ID",
      "PREMIUM_PLUS_PLAN_ID",
      "CUSTOM4TB_PLAN_ID",
      "FAMILY2TB_PLAN_ID",
      "FAMILY4TB_PLAN_ID",
      "CUSTOM_PLAN_ID",
      "CUSTOM_1COUNT_PLAN_ID",
      "CUSTOM_4COUNT_PLAN_ID",
      "CRYPTO_PLAN_ID",
      "EFH_PLAN_ID",
      "RELOCATION_ID",
    ]
  },
  {
    attach: $,
    list: ["browser"]
  },
  {
    attach: self.prc,
    list: [
      "Registration",
      "LoginOrSignup",
      "Signin",
      "Signup",
      "ModalSignin",
      //"Notifications",
      "ChangeEmailChooseNewEmail",
      "ChangeEmailConfirmation",
      "DeactivateAccount",
      "UserHeader",
      //"UserDropdown",
      "DLStatsSidebar",
      "DLUsageStats",
      //"UploadLink",
      "FooterSmall",
      "FooterWrap",
      "SubscriptionsSettingsContainer",
      "PublicFolderPage",
      "EmbedDirectlink",
      "OpenInApp",
      "ContactForm",
      "PublicLinkPreview",
      "BonusSteps",
      "NotificationsPopup",
      "FinanceContainer",
      "DriveUpdateModal",
      "NewPrivacyPolicy",
      "CookieConsent",
      "InviteFriendsContainer",
      "UnlockSpaceSteps",
      "StorageQuotaFullModal",
      "VivacomRegistration",
      "UnverifiedUsersAlert",
      "PromoModal",
      "FamilyContainer",
      "FamilyInvitation",
      "AuthorizeOAuth2",
      "UseAppModal",
      "BrandingContainer",
      "BusinessInvitation",
      "RegistrationForm",
      "Button",
      "EditBrandingModal",
      "SearchBar",
      "PhotoSearchInput",
      "OpenAppButton",
      "ShareModal",
      "ChangeUserOrTeamCryptoPass",
      "Alert",
      "UpdateCrypto",
      "CryptoLockButton",
      "DownloadLinkModal",
      "FolderInvitation",
      "AppleSigninDrive",
      "ShareSettingsModal",
      "InfoModal",
      "EditBookmarkModal",
      "EditBookmarkTooltip",
      "DeleteBookmarkModal",
      "ExportBookmark",
      "SystemNotificationPopup",
      "CreditCardModal",
      "PromoStickyBar",
      "LeftMenuBanner",
      "BackupsContainer",
      "DesktopInfo",
      "MobileInfo",
      "DeleteItemModal",
      "DeleteLinkModal",
      "StopBackupModal",
      "SpeedTestContainer",
      "BillingPageContainer",
      "EmailVerifyModal",
      "CreateFolderModal",
      "EditUserModal",
      "EditTeamNameModal",
      "ChangeTeamStatusModal",
      "SetTeamHeadModal",
      "RemoveUserFromTeamModal",
      "InviteUsersToTeamModal",
      "InviteUsersToTeamsModal",
      "ResetMemberPassModal",
      "DeactivateMemberModal",
      "DeactivatePublicFolderPage",
      "DeleteLogoModal",
      "CreateNewTeamModal",
      "EditCompanyInfoModal",
      "RequestMoreSpaceModal",
      "RequestMoreSpaceModalBusinessPro",
      "InviteUsersToAccountModal",
      "CancelInvitationModal",
      "CopyInviteLinkModal",
      "CopyModal",
      "CopyToCloudModal",
      "MoveModal",
      "CreateArchiveModal",
      "PreviewArchiveModal",
      "ExtractArchiveModal",
      "AcceptShareModal",
      "DeclineShareModal",
      "RemoveShareModal",
      "StopAllSharesModal",
      "ActivateUserModal",
      "FileRequestModal",
      "RenameModal",
      "DeleteCommentModal",
      "ItemInfoModal",
      "DownloadArchiveModal",
      "GetPublickLinkModal",
      "ManageMemberQuotaModal",
      "ContentView",
      "LeaveAccountModal",
      "CreatePlaylistModal",
      "DeletePlaylistModal",
      "RenamePlaylistModal",
      "AddToPlaylistModal",
      "TrashRestoreModal",
      "TrashDeleteModal",
      "EmptyTrashModal",
      "RestoreRevisionTimeModal",
      "RevertRevisionModal",
      "RestoreRevisionAsNewFileModal",
      "LoginRegPage",
      "PCloudServicesDropdown",
      "RemoteuploadExceededModal",
      "DownloadVideoModal",
      "DownloadImageSizesModal",
      "PreviewDocErrorModal",
      "PreviewGenericModal",
      "PreviewDocModal",
      "PreviewDocumentModal",
      "NoPdfReaderModal",
      "GenerateSharedLinkModal",
      "PreviewFileCodeModal",
      "VerifyEmailPage",
      "EmailRedirectPagesContainer",
      "UseAppContainer",
      "BreadcrumbDropdown",
      "UploadManagerContainer",
      "UploadButton",
      "DropArea",
      "AudioPlayer",
      "GalleryModal",
      "VideoContainer",
      "TopBar",
      "LeftMenuContainer",
      "BetaModal",
      "CryptoLock",
      "CryptoBuy",
      "CryptoBusiness",
      "CryptoSet",
      "ChatBot",
      "SettingsContainer",
      "TimeHome",
      "ItemsInfoPanel",
    ]
  }
];

function _move(obj) {
  for (var n in obj) {
    if (obj.hasOwnProperty(n)) {
      for (var k = 0; k < _attachList.length; ++k) {
        if (_attachList[k].list.indexOf(n) != -1) {
          _attachList[k].attach[n] = obj[n];
        }
      }
    }
  }
}

let prepComponents = {
  Registration: componentMount(props => <Provider store={store}><Registration {...props} /></Provider>),
  Signin: componentMount(props => <Provider store={store}><Signin {...props} /></Provider>),
  Signup: componentMount(props => <Provider store={store}><Signup {...props} /></Provider>),
  LoginOrSignup: componentMount(props => <Provider store={store}><LoginOrSignup {...props} /></Provider>),
  ModalSignin: componentMount(props => <Provider store={store}><ModalSignin {...props} /></Provider>),
  //Notifications: componentMount(props => <Notifications store={store} {...props} />),
  ChangeEmailChooseNewEmail: componentMount(props => <ChangeEmailChooseNewEmail {...props} />),
  ChangeEmailConfirmation: componentMount(props => <ChangeEmailConfirmation {...props} />),
  DeactivateAccount: componentMount(props => <DeactivateAccount {...props} />),
  UserPassHeader: componentMount(props => <UserPassHeader store={store} {...props} />),
  UserHeader: componentMount(props => <UserHeader store={store} {...props} />),
  //UserDropdown: componentMount(props => <UserDropdown store={store} {...props} />),
  DLStatsSidebar: componentMount(props => <DLStatsSidebar {...props} />),
  DLUsageStats: componentMount(props => <DLUsageStats {...props} />),
  //UploadLink: componentMount(props => <UploadLink {...props} />),
  FooterSmall: componentMount(props => <FooterSmall {...props} />),
  FooterWrap: componentMount(props => <FooterWrap store={store} {...props} />),
  SubscriptionsSettingsContainer: componentMount(props => <SubscriptionsSettingsContainer store={store} {...props} />),
  PublicFolderPage: componentMount(props => <PublicFolderPage store={store} {...props} />),
  EmbedDirectlink: componentMount(props => <EmbedDirectlink {...props} />),
  OpenInApp: componentMount(props => <OpenInApp {...props} />),
  ContactForm: componentMount(props => <ContactForm {...props} />),
  PublicLinkPreview: componentMount(props => <PublicLinkPreview store={store} {...props} />),
  BonusSteps: componentMount(props => <Provider store={store}><BonusSteps {...props} /></Provider>),
  NotificationsPopup: componentMount(props => <NotificationsPopup store={store} {...props} />),
  FinanceContainer: componentMount(props => <Provider store={store}><FinanceContainer {...props} /></Provider>),
  DriveUpdateModal: componentMount(props => <DriveUpdateModal {...props} />),
  NewPrivacyPolicy: componentMount(props => <NewPrivacyPolicy store={store} {...props} />),
  CookieConsent: componentMount(props => <CookieConsent {...props} />),
  InviteFriendsContainer: componentMount(props => <InviteFriendsContainer store={store} {...props} />),
  StorageQuotaFullModal: componentMount(props => <StorageQuotaFullModal {...props} />),
  UnlockSpaceSteps: componentMount(props => <UnlockSpaceSteps store={store} {...props} />),
  VivacomRegistration: componentMount(props => <Provider store={store}><VivacomRegistration {...props} /></Provider>),
  UnverifiedUsersAlert: componentMount(props => <UnverifiedUsersAlert store={store} {...props} />),
  PromoModal: componentMount(props => <PromoModal {...props} />),
  FamilyContainer: componentMount(props => <Provider store={store}><FamilyContainer {...props} /></Provider>),
  FamilyInvitation: componentMount(props => <Provider store={store}><FamilyInvitation {...props} /></Provider>),
  AuthorizeOAuth2: componentMount(props => <Provider store={store}><AuthorizeOAuth2 {...props} /></Provider>),
  UseAppModal: componentMount(props => <Provider store={store}><UseAppModal {...props} /></Provider>),
  BrandingContainer: componentMount(props => <Provider store={store}><BrandingContainer {...props} /></Provider>),
  BusinessInvitation: componentMount(props => <Provider store={store}><BusinessInvitation {...props} /></Provider>),
  RegistrationForm: componentMount(props => <Provider store={store}><RegistrationForm {...props} /></Provider>),
  Button: componentMount(props => <Provider store={store}><Button {...props} /></Provider>),
  EditBrandingModal: componentMount(props => <Provider store={store}><EditBrandingModal {...props} /></Provider>),
  SearchBar: componentMount(props => <Provider store={store}><SearchBar {...props} /></Provider>),
  PhotoSearchInput: componentMount(props => <Provider store={store}><PhotoSearchInput {...props} /></Provider>),
  OpenAppButton: componentMount(props => <OpenAppButton {...props} />),
  ShareModal: componentMount(props => <Provider store={store}><ShareModal {...props} /></Provider>),
  ChangeUserOrTeamCryptoPass: componentMount(props => <Provider store={store}><ChangeUserOrTeamCryptoPass {...props} /></Provider>),
  Alert: componentMount(props => <Provider store={store}><Alert {...props} /></Provider>),
  UpdateCrypto: componentMount(props => <Provider store={store}><UpdateCrypto {...props} /></Provider>),
  CryptoLockButton: componentMount(props => <Provider store={store}><CryptoLockButton {...props} /></Provider>),
  AppleSigninDrive: componentMount(props => <Provider store={store}><AppleSigninDrive {...props} /></Provider>),
  DownloadLinkModal: componentMount(props => <DownloadLinkModal {...props} />),
  FolderInvitation: componentMount(props => <Provider store={store}><FolderInvitation {...props} /></Provider>),
  ShareSettingsModal: componentMount(props => <Provider store={store}><ShareSettingsModal {...props} /></Provider>),
  InfoModal: componentMount(props => <Provider store={store}><InfoModal {...props} /></Provider>),
  EditBookmarkModal: componentMount(props => <Provider store={store}><EditBookmarkModal {...props} /></Provider>),
  EditBookmarkTooltip: componentMount(props => <Provider store={store}><EditBookmarkTooltip {...props} /></Provider>),
  DeleteBookmarkModal: componentMount(props => <Provider store={store}><DeleteBookmarkModal {...props} /></Provider>),
  ExportBookmark: componentMount(props => <Provider store={store}><ExportBookmark {...props} /></Provider>),
  SystemNotificationPopup: componentMount(props => <Provider store={store}><SystemNotificationPopup {...props} /></Provider>),
  CreditCardModal: componentMount(props => <Provider store={store}><CreditCardModal {...props} /></Provider>),
  PromoStickyBar: componentMount(props => <PromoStickyBar {...props} />),
  LeftMenuBanner: componentMount(props => <Provider store={store}><LeftMenuBanner {...props} /></Provider>),
  BackupsContainer: componentMount(props => <Provider store={store}><BackupsContainer {...props} /></Provider>),
  DesktopInfo: componentMount(props => <Provider store={store}><DesktopInfo {...props} /></Provider>),
  MobileInfo: componentMount(props => <Provider store={store}><MobileInfo {...props} /></Provider>),
  DeleteItemModal: componentMount(props => <Provider store={store}><DeleteItemModal {...props} /></Provider>),
  DeleteLinkModal: componentMount(props => <Provider store={store}><DeleteLinkModal {...props} /></Provider>),
  StopBackupModal: componentMount(props => <Provider store={store}><StopBackupModal {...props} /></Provider>),
  SpeedTestContainer: componentMount(props => <Provider store={store}><SpeedTestContainer {...props} /></Provider>),
  BillingPageContainer: componentMount(props => <Provider store={store}><BillingPageContainer {...props} /></Provider>),
  EmailVerifyModal: componentMount(props => <Provider store={store}><EmailVerifyModal {...props} /></Provider>),
  CreateFolderModal: componentMount(props => <Provider store={store}><CreateFolderModal {...props} /></Provider>),
  EditUserModal: componentMount(props => <Provider store={store}><EditUserModal {...props} /></Provider>),
  EditTeamNameModal: componentMount(props => <Provider store={store}><EditTeamNameModal {...props} /></Provider>),
  ChangeTeamStatusModal: componentMount(props => <Provider store={store}><ChangeTeamStatusModal {...props} /></Provider>),
  SetTeamHeadModal: componentMount(props => <Provider store={store}><SetTeamHeadModal {...props} /></Provider>),
  RemoveUserFromTeamModal: componentMount(props => <Provider store={store}><RemoveUserFromTeamModal {...props} /></Provider>),
  InviteUsersToTeamModal: componentMount(props => <Provider store={store}><InviteUsersToTeamModal {...props} /></Provider>),
  InviteUsersToTeamsModal: componentMount(props => <Provider store={store}><InviteUsersToTeamsModal {...props} /></Provider>),
  ResetMemberPassModal: componentMount(props => <Provider store={store}><ResetMemberPassModal {...props} /></Provider>),
  DeactivateMemberModal: componentMount(props => <Provider store={store}><DeactivateMemberModal {...props} /></Provider>),
  DeactivatePublicFolderPage: componentMount(props => <Provider store={store}><DeactivatePublicFolderPage {...props} /></Provider>),
  DeleteLogoModal: componentMount(props => <Provider store={store}><DeleteLogoModal {...props} /></Provider>),
  CreateNewTeamModal: componentMount(props => <Provider store={store}><CreateNewTeamModal {...props} /></Provider>),
  EditCompanyInfoModal: componentMount(props => <Provider store={store}><EditCompanyInfoModal {...props} /></Provider>),
  RequestMoreSpaceModal: componentMount(props => <Provider store={store}><RequestMoreSpaceModal {...props} /></Provider>),
  RequestMoreSpaceModalBusinessPro: componentMount(props => <Provider store={store}><RequestMoreSpaceModalBusinessPro {...props} /></Provider>),
  InviteUsersToAccountModal: componentMount(props => <Provider store={store}><InviteUsersToAccountModal {...props} /></Provider>),
  CancelInvitationModal: componentMount(props => <Provider store={store}><CancelInvitationModal {...props} /></Provider>),
  CopyInviteLinkModal: componentMount(props => <Provider store={store}><CopyInviteLinkModal {...props} /></Provider>),
  CopyModal: componentMount(props => <Provider store={store}><CopyModal {...props} /></Provider>),
  CopyToCloudModal: componentMount(props => <Provider store={store}><CopyToCloudModal {...props} /></Provider>),
  MoveModal: componentMount(props => <Provider store={store}><MoveModal {...props} /></Provider>),
  CreateArchiveModal: componentMount(props => <Provider store={store}><CreateArchiveModal {...props} /></Provider>),
  PreviewArchiveModal: componentMount(props => <Provider store={store}><PreviewArchiveModal {...props} /></Provider>),
  ExtractArchiveModal: componentMount(props => <Provider store={store}><ExtractArchiveModal {...props} /></Provider>),
  AcceptShareModal: componentMount(props => <Provider store={store}><AcceptShareModal {...props} /></Provider>),
  DeclineShareModal: componentMount(props => <Provider store={store}><DeclineShareModal {...props} /></Provider>),
  RemoveShareModal: componentMount(props => <Provider store={store}><RemoveShareModal {...props} /></Provider>),
  StopAllSharesModal: componentMount(props => <Provider store={store}><StopAllSharesModal {...props} /></Provider>),
  RenameModal: componentMount(props => <Provider store={store}><RenameModal {...props} /></Provider>),
  ActivateUserModal: componentMount(props => <Provider store={store}><ActivateUserModal {...props} /></Provider>),
  FileRequestModal: componentMount(props => <Provider store={store}><FileRequestModal {...props} /></Provider>),
  DeleteCommentModal: componentMount(props => <Provider store={store}><DeleteCommentModal {...props} /></Provider>),
  ItemInfoModal: componentMount(props => <Provider store={store}><ItemInfoModal {...props} /></Provider>),
  DownloadArchiveModal: componentMount(props => <Provider store={store}><DownloadArchiveModal {...props} /></Provider>),
  GetPublickLinkModal: componentMount(props => <Provider store={store}><GetPublickLinkModal {...props} /></Provider>),
  ManageMemberQuotaModal: componentMount(props => <Provider store={store}><ManageMemberQuotaModal {...props} /></Provider>),
  ContentView: componentMount(props => <Provider store={store}><ContentView {...props} /></Provider>),
  LeaveAccountModal: componentMount(props => <Provider store={store}><LeaveAccountModal {...props} /></Provider>),
  LeftMenuContainer: componentMount(props => <Provider store={store}><LeftMenuContainer {...props} /></Provider>),
  CreatePlaylistModal: componentMount(props => <Provider store={store}><CreatePlaylistModal {...props} /></Provider>),
  DeletePlaylistModal: componentMount(props => <Provider store={store}><DeletePlaylistModal {...props} /></Provider>),
  RenamePlaylistModal: componentMount(props => <Provider store={store}><RenamePlaylistModal {...props} /></Provider>),
  AddToPlaylistModal: componentMount(props => <Provider store={store}><AddToPlaylistModal {...props} /></Provider>),
  TrashRestoreModal: componentMount(props => <Provider store={store}><TrashRestoreModal {...props} /></Provider>),
  TrashDeleteModal: componentMount(props => <Provider store={store}><TrashDeleteModal {...props} /></Provider>),
  EmptyTrashModal: componentMount(props => <Provider store={store}><EmptyTrashModal {...props} /></Provider>),
  RestoreRevisionTimeModal: componentMount(props => <Provider store={store}><RestoreRevisionTimeModal {...props} /></Provider>),
  RevertRevisionModal: componentMount(props => <Provider store={store}><RevertRevisionModal {...props} /></Provider>),
  RestoreRevisionAsNewFileModal: componentMount(props => <Provider store={store}><RestoreRevisionAsNewFileModal {...props} /></Provider>),
  LoginRegPage: componentMount(props => <Provider store={store}><LoginRegPage {...props} /></Provider>),
  PCloudServicesDropdown: componentMount(props => <Provider store={store}><PCloudServicesDropdown {...props} /></Provider>),
  RemoteuploadExceededModal: componentMount(props => <Provider store={store}><RemoteuploadExceededModal {...props} /></Provider>),
  DownloadVideoModal: componentMount(props => <Provider store={store}><DownloadVideoModal {...props} /></Provider>),
  DownloadImageSizesModal: componentMount(props => <Provider store={store}><DownloadImageSizesModal {...props} /></Provider>),
  PreviewDocErrorModal: componentMount(props => <Provider store={store}><PreviewDocErrorModal {...props} /></Provider>),
  PreviewGenericModal: componentMount(props => <Provider store={store}><PreviewGenericModal {...props} /></Provider>),
  PreviewDocModal: componentMount(props => <Provider store={store}><PreviewDocModal {...props} /></Provider>),
  PreviewDocumentModal: componentMount(props => <Provider store={store}><PreviewDocumentModal {...props} /></Provider>),
  NoPdfReaderModal: componentMount(props => <Provider store={store}><NoPdfReaderModal {...props} /></Provider>),
  GenerateSharedLinkModal: componentMount(props => <Provider store={store}><GenerateSharedLinkModal {...props} /></Provider>),
  PreviewFileCodeModal: componentMount(props => <Provider store={store}><PreviewFileCodeModal {...props} /></Provider>),
  VerifyEmailPage: componentMount(props => <Provider store={store}><VerifyEmailPage {...props} /></Provider>),
  EmailRedirectPagesContainer: componentMount(props => <Provider store={store}><EmailRedirectPagesContainer {...props} /></Provider>),
  UseAppContainer: componentMount(props => <Provider store={store}><UseAppContainer {...props} /></Provider>),
  BreadcrumbDropdown: componentMount(props => <Provider store={store}><BreadcrumbDropdown {...props} /></Provider>),
  UploadManagerContainer: componentMount(props => <Provider store={store}><UploadContextProvider {...props}><UploadManagerContainer {...props} /></UploadContextProvider></Provider>),
  UploadButton: componentMount(props => <Provider store={store}><UploadButton {...props} /></Provider>),
  DropArea: componentMount(props => <Provider store={store}><DropArea {...props} /></Provider>),
  AudioPlayer: componentMount(props => <Provider store={store}><PlayerContextProvider {...props}><AudioPlayer {...props} /></PlayerContextProvider></Provider>),
  GalleryModal: componentMount(props => <Provider store={store}><GalleryModal {...props} /></Provider>),
  VideoContainer: componentMount(props => <Provider store={store}><VideoContainer {...props} /></Provider>),
  TopBar: componentMount(props => <Provider store={store}><TopBar {...props} /></Provider>),
  BetaModal: componentMount(props => <Provider store={store}><BetaModal {...props} /></Provider>),
  CryptoLock: componentMount(props => <Provider store={store}><CryptoLock {...props} /></Provider>),
  CryptoBuy: componentMount(props => <Provider store={store}><CryptoBuy {...props} /></Provider>),
  CryptoBusiness: componentMount(props => <Provider store={store}><CryptoBusiness {...props} /></Provider>),
  CryptoSet: componentMount(props => <Provider store={store}><CryptoSet {...props} /></Provider>),
  ChatBot: componentMount(props => <Provider store={store}><ChatBot {...props} /></Provider>),
  SettingsContainer: componentMount(props => <Provider store={store}><SettingsContainer {...props} /></Provider>),
  TimeHome: componentMount(props => <Provider store={store}><TimeHome {...props} /></Provider>),
  ItemsInfoPanel: componentMount(props => <Provider store={store}><ItemsInfoPanel {...props} /></Provider>),
};

self.trackTooltip = trackTooltip;

// go
_move(utils);
_move(icons);
_move(apiAll);
_move(libs);
_move(linkstats);
_move(constants);
_move(crypto);
_move(FooterRegistration);
_move(sendanalyticsevent);
_move(prices);
_move(sharedConstants);
_move(sharedUtils);
_move(sharedPlansUtils);
_move(ContentData);

_move(prepComponents);
_move({ sendCJTokenOnLogin }, _attachList);
_move({ initCrashlytics }, _attachList);
