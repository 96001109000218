import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import * as Style from "./SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";
import { boldConverter } from "../lib/componentifyConverters";
import InputPassword from "../components/InputPassword";

const ResetPasswordContainer = ({ code }) => {
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [newpasswordRepeat, setNewPasswordRepeat] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const changePassword = useCallback(
    (e) => {
      e.preventDefault();

      if (!newpassword || !newpasswordRepeat || !oldpassword) {
        setError(__("Passwords are required.", "Passwords are required."));
        return;
      }

      if (newpassword !== newpasswordRepeat) {
        setError(__("Both new passwords should match.", "Both new passwords should match."));
        return;
      }

      setLoading(true);

      apiMethod(
        "changepassword",
        { code, newpassword, oldpassword },
        () => {
          HFN.message("Your password has been changed.");
          HFN.pages.goto("filemanager");
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    },
    [code, newpassword, newpasswordRepeat, oldpassword]
  );

  const renderForm = () => (
    <Style.Form method="post" onSubmit={changePassword}>
      {error && <div className="error">{error}</div>}
      <InputPassword
        name="oldpassword"
        placeholder={__("Old Password", "Old Password")}
        value={oldpassword}
        onChange={(e) => setOldPassword(e.target.value)}
        revealPassword
      />
      <InputPassword
        name="newpassword"
        placeholder={__("New Password", "New Password")}
        value={newpassword}
        onChange={(e) => setNewPassword(e.target.value)}
        revealPassword
      />
      <InputPassword
        name="repeatpassword"
        placeholder={__("Repeat Password", "Repeat Password")}
        value={newpasswordRepeat}
        onChange={(e) => setNewPasswordRepeat(e.target.value)}
        revealPassword
      />
      <SubmitButton loading={loading} active={!loading}>
        {__("Continue", "Continue")}
      </SubmitButton>
    </Style.Form>
  );

  const renderNoCode = () => (
    <Style.WarningMessage>
      <WarnIcon />
      <Style.ComponentifyWrapper>
        <Componentify text={__("invalid_data_arrived")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.WarningMessage>
  );

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("Change Password", "Change Password")}</Style.Title>
      {!code ? renderNoCode() : renderForm()}
    </Style.Container>
  );
};

ResetPasswordContainer.propTypes = {
  code: PropTypes.string,
};

export default ResetPasswordContainer;
