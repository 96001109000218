import React, { useState } from "react";
import { __ } from "../../../../../../lib/translate";
import { ButtonWrapper, LinkButton } from "../styles";
import Modal, {
    OvalModalClose,
    OvalModalWrap
} from "../../../../../Modal";
import styled, { css } from "styled-components";
import Button from "../../../../../ButtonDefault";
import { TSpan } from "../../../../../intl";

const DisconnectButton = ({ unlinkAccount, type, name, isMobile = false }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <DisconnectWrapper
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {!isMobile && (
                <LinkButton
                    className="inline"
                    onClick={handleOpenModal}
                >
                    {__("Disconnect")}
                </LinkButton>
            )}
            {isMobile && (
                <ButtonWrapper>
                    <Button
                        size="bigger"
                        padding="12px 20px"
                        fontWeight="400"
                        textSize={15}
                        borderRadius="50"
                        minWidth="81px"
                        color="lightCyan"
                        onClick={handleOpenModal}
                    >
                        {__("Disconnect")}
                    </Button>
                </ButtonWrapper>
            )}
            <Modal onClose={handleCloseModal} opened={isModalOpen}>
                <CustomOvalModalWrap
                    className="responsive-modal"
                    style={{ minWidth: "320px" }}
                >
                    <OvalModalClose onClick={handleCloseModal} />
                    <ModalBodyWrapper>
                        <ModalTitle>
                            {__(
                                "backups_disconnect_modal_title",
                                "Disconnect %backupName%",
                                {
                                    backupName: name
                                }
                            )}
                        </ModalTitle>
                        <ModalContent>
                            {__(
                                "backups_disconnect_modal_subtitle",
                                "Are you sure you want to disconnect your backup account?"
                            )}
                        </ModalContent>
                        <ModalContent>
                            {type === "ongoing"
                                ? __(
                                      "backups_disconnect_modal_text_ongoing ",
                                      "Disconnecting your %backupName% account will stop automatic backups to this account. Your previously backed-up files will remain safe, but new files won’t be synced. You can reconnect anytime.",
                                      { backupName: name }
                                  )
                                : __(
                                      "backups_disconnect_modal_text_onetime",
                                      "Disconnecting your %backupName% account will prevent you from creating one-time backups. Existing backups remain safe and accessible. Reconnection is needed for future backups to this account.",
                                      { backupName: name }
                                  )}
                        </ModalContent>
                        <ModalButtonWrapper>
                            <CustomButton onClick={handleCloseModal}>
                                {__("Cancel")}
                            </CustomButton>
                            <CustomButton
                                $danger={true}
                                onClick={unlinkAccount}
                            >
                                {__("Disconnect account")}
                            </CustomButton>
                        </ModalButtonWrapper>
                    </ModalBodyWrapper>
                </CustomOvalModalWrap>
            </Modal>
        </DisconnectWrapper>
    );
};

export default DisconnectButton;

const DisconnectWrapper = styled.div`
    display: inline-block;
`;
const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    padding: var(--spacing-lg);
`;
const ModalTitle = styled.div`
    color: var(--text-primary);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
`;
const ModalContent = styled.div`
    color: var(--text-primary);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
const CustomOvalModalWrap = styled(OvalModalWrap)`
    width: 560px;
`;
const ModalButtonWrapper = styled.div`
    display: flex;
    gap: var(--spacing-md);

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;
const CustomButton = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    border-radius: 5px;
    background: var(--color-grey-400);
    color: var(--button-secondary-label-default);
    cursor: pointer;

    ${({ $danger }) =>
        $danger &&
        css`
            background: var(--color-functional-danger500);
            color: var(--color-base-white);
        `}
`;
