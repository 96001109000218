// @flow
import React, { useCallback, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { PAGE_CONFIG } from "./constants";
import { getHashPage, getSiteLinkFromPlatform, truncateEmailUtility } from "../../lib/utils";
import { getNames, userInitials } from "../../lib/user-utils";
import { useSelector } from "react-redux";
import Popover from "../../components/Popover";
import { style } from "@mui/system";
import { __ } from "../../lib/translate";
import { URLMY } from "@pcloud/web-utilities/dist/config";
import PCloudIcon from '../../../web-shared/images/svg/menu/pCloud.svg';
import apiMethod from "../../api/apiMethod";

type Props = { onSuccessInvitation: () => void };

const EmailRedirectPagesContainer = ({ onSuccessInvitation = () => { }, ...rest }: Props) => {
  const pageHash = getHashPage();
  const { image: Image, formComponent: FormComponent } = PAGE_CONFIG[pageHash];
  const userInfo = useSelector(({ user }) => user.userinfo);
  const names = getNames(userInfo);

  const truncateEmail = useMemo(() => {
    return truncateEmailUtility(userInfo.email, names, !names ? 190 : undefined);
  }, [userInfo.email, names]);

  const goToMyAccount = useCallback(() => {
    window.open(URLMY, '_blank');
  }, []);

  const logout = useCallback(() => {
    setcookie("pcauth", "", -1);
    setcookie("locationid", "", -1);
    HFN.setDefaultLocations();

    apiMethod('logout', {}, function () {
      HFN.uploadControl.destroy();
      if (HFN.config.isDlink()) {
        location.reload();
      } else {
        const currentPage = HFN.pages.getCurrent();
        sessionStorage.removeItem('pmdata');
        setcookie('pmtinit', '', -1);
        HFN.promoModalTimeout && clearTimeout(HFN.promoModalTimeout);

        if (currentPage && currentPage.reqauth) {
          if (HFN.config.label.hasTradeSite) {
            location.href = getSiteLinkFromPlatform();
          } else {
            location.href = "/#page=login";
          }
        } else {
          HFN.logout();
        }
      }
    });
  }, []);

  return (
    <FlexContainer>
      <LeftBox>
        <Image />
      </LeftBox>

      <RightBox>
        <ProfileInitialsContainer>
          {(userInfo && userInfo.email) && <>
            <Popover
              trigger="click"
              render={
                <UserInfoContainer>
                  <UserInfo>
                    <ProfileInitials $isInPopover>{userInitials(userInfo)}</ProfileInitials>
                    <UserInfoTexts $hasNames={names}>
                      <FirstText>
                        {names || truncateEmail}
                      </FirstText>
                      {names && <SeccondText>
                        {truncateEmail}
                      </SeccondText>}
                    </UserInfoTexts>
                  </UserInfo>
                  <LinkContainer onClick={goToMyAccount}><PCloudIcon />{__("verify_mail_my", "verify_mail_my")}</LinkContainer>
                  <LinkContainer onClick={logout}><i className="smallIcon fa-light fa-arrow-right-from-bracket" />{__("Logout", "Logout")}</LinkContainer>
                </UserInfoContainer>
              }
              cssPosition="absolute"
              position="bottom"
              align="right"
              valign="top"
              flyin="top"
            >
              <ProfileInitials>{userInitials(userInfo)}</ProfileInitials>
            </Popover >
          </>}
        </ProfileInitialsContainer>
        <FormComponent {...rest} onSuccessInvitation={onSuccessInvitation} />
      </RightBox>
    </FlexContainer>
  );
};

export default EmailRedirectPagesContainer;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-family: Roboto;

  @media screen and (max-width: 910px) {
    flex-direction: column;
  }
`;

const LeftBox = styled.div`
  position: relative;
  z-index: 2;
  background-color: #fbfbfb;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 910px) {
    width: 100%;
    display: none;
  }
`;

const RightBox = styled.div`
  position: relative;
  z-index: 3;
  background-color: #ffffff;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  @media screen and (max-width: 910px) {
    width: 100%;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;

export const InfoBoxWrapper = styled.div`
  max-width: 486px;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 910px) {
    min-width: 0px;
    text-align: center;
    max-width: 400px;
    padding: 60px 20px;
  }
`;

const ProfileInitialsContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: var(--spacing-lg);
  top: var(--spacing-lg);
`

const ProfileInitials = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: var(--color-base-white);
  font-size: 13px;
  font-weight: 400;
  background-color: ${({ $isInPopover }) => $isInPopover ? "var(--color-grey-500)" : "var(--color-primary-700)"};
  border-radius: 50%;
  position: relative;
  ${({ $isInPopover }) => !$isInPopover && css`
    cursor: pointer; 
  `}
`

const UserInfoContainer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-minimum, 4px);
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  background: var(--color-base-white);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  box-sizing: border-box;
`

const UserInfo = styled.div`
  padding: var(--spacing-md) var(--spacing-md);
  display: flex;
  gap: var(--spacing-sm);
`

const FirstText = styled.div`
  color: var(--text-primary, #282C2F);
  font-variant-numeric: lining-nums proportional-nums;
  /* Body/Base */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const SeccondText = styled.div`
  color: var(--text-secondary, #8C959D);
  /* Body/Help */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const LinkContainer = styled.div`
  display: flex;
  padding: 12px var(--spacing-md);
  align-items: center;
  gap: 8px;
  color: var(--text-primary, #282C2F);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-top: 1px solid var(--divider-secondary);
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  & .smallIcon {
    width: 20px;
    text-align: center;
  }
`

const UserInfoTexts = styled.div`
  ${({ $hasNames }) => !$hasNames && css`
    display: flex;
    align-items: center;
  `}
`