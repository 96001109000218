import React from "react";
import GPhotosIcon from "../../../root/img/backup/googlephotos.svg";
import DropboxIcon from "../../../root/img/backup/dropbox.svg";
import InstagramIcon from "../../../root/img/backup/instagram.svg";
import FacebookIcon from "../../../root/img/backup/facebook.svg";
import OnedriveIcon from "../../../root/img/backup/onedrive.svg";
import GDriveIcon from "../../../root/img/backup/googledrive.svg";
import MacSvg from "../../../root/img/backup/mac.svg";
import WindowsSvg from "../../../root/img/backup/windows.svg";
import LinuxSvg from "../../../root/img/backup/linux.svg";
import MobileBackupIcon from "../../../root/img/backup/mobile_backup.svg";

export const TABS = [
    {
        id: "all",
        title: "backup_tabs_all",
        icon: 'fa-regular fa-cloud-arrow-up',
        iconActive: 'fa-solid fa-cloud-arrow-up'
    },
    {
        id: "desktop",
        title: "Desktop",
        icon: 'fa-regular fa-display',
        iconActive: 'fa-solid fa-display'
    },
    {
        id: "mobile",
        title: "Mobile",
        icon: 'fa-regular fa-mobile',
        iconActive: 'fa-solid fa-mobile'
    },
    {
        id: "thirdparty",
        title: "Third-party",
        icon: 'fa-regular fa-clouds',
        iconActive: 'fa-solid fa-clouds'
    }
];
export const TABS_MOBILE = [
    {
        id: "all",
        title: "backup_tabs_all",
        icon: 'fa-regular fa-cloud-arrow-up',
        iconActive: 'fa-solid fa-cloud-arrow-up'
    },
    {
        id: "mobile",
        title: "Mobile",
        icon: 'fa-regular fa-mobile',
        iconActive: 'fa-solid fa-mobile'
    },
    {
        id: "desktop",
        title: "Desktop",
        icon: 'fa-regular fa-display',
        iconActive: 'fa-solid fa-display'
    },
    {
        id: "thirdparty",
        title: "Third-party",
        icon: 'fa-regular fa-clouds',
        iconActive: 'fa-solid fa-clouds'
    }
];

export const SERVICES_INFO = {
    gphotos: {
        name: "Google Photos",
        icon: <GPhotosIcon />,
        nextBackupTooltip: "backup_cloud_tooltip",
    },
    gdriven: {
        name: "Google Drive",
        icon: <GDriveIcon />,
        nextBackupTooltip: "backup_cloud_tooltip"
    },
    dbxn: {
        name: "Dropbox",
        icon: <DropboxIcon />,
        nextBackupTooltip: "backup_cloud_tooltip"
    },
    dbxb: {
        name: "Dropbox Business",
        icon: <DropboxIcon />,
        nextBackupTooltip: "backup_cloud_tooltip"
    },
    fb: {
        name: "Facebook",
        icon: <FacebookIcon />,
        nextBackupTooltip: "backups_socialmedia_tooltip"
    },
    ig: {
        name: "Instagram",
        icon: <InstagramIcon />,
        nextBackupTooltip: "backups_socialmedia_tooltip"
    },
    odn: {
        name: "One Drive",
        icon: <OnedriveIcon />
    },
    linuxdesk: {
        name: "Linux",
        icon: <LinuxSvg />
    },
    windowsdesk: {
        name: "Windows",
        icon: <WindowsSvg />
    },
    macdesk: {
        name: "Mac",
        icon: <MacSvg />
    },
    mobilephone: {
        name: "Mobile",
        icon: <MobileBackupIcon />
    }
};

export const DEFAULT_CATEGORY_DATA = [
    {
        id: "mobile",
        title: "Mobile",
        categories: ["mobile"],
        items: []
    },
    {
        id: "desktop",
        title: "Desktop",
        categories: ["desktop"],
        items: []
    },
    {
        id: "one-time",
        title: "One-time",
        categories: ["thirdparty"],
        items: []
    },
    {
        id: "ongoing",
        title: "Ongoing",
        categories: ["thirdparty"],
        items: []
    }
];

export const DEFAULT_ONGOING_BACKUPS_STATE = [
    {
        sourceid: "gdriven",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: true,
        deprecated: false,
        mode: 0,
        source: "Google Drive",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.GDrive,
        type: "onetime",
        is_allowed_to_backup: true
    },
    {
        sourceid: "odn",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: false,
        mode: 0,
        source: "OneDrive",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.OneDrive,
        type: "onetime",
        is_allowed_to_backup: true
    },
    {
        sourceid: "dbxn",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: false,
        mode: 0,
        source: "Dropbox",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.Dropbox,
        type: "onetime",
        is_allowed_to_backup: true
    },
    {
        sourceid: "dbxb",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: false,
        mode: 0,
        source: "DropboxBusiness",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.DropboxBusiness,
        type: "onetime",
        is_allowed_to_backup: true
    },
    {
        sourceid: "gphotos",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: false,
        mode: 0,
        source: "Google Photos",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.GPhotos,
        type: "ongoing",
        is_allowed_to_backup: true
    },
    {
        sourceid: "fb",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: false,
        mode: 0,
        source: "Facebook",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.Facebook,
        type: "ongoing",
        is_allowed_to_backup: true
    },
    {
        sourceid: "ig",
        backup_folder: null,
        next_execution: null,
        backuped_albums: 0,
        total_albums: 0,
        is_aborted: false,
        is_running: false,
        is_paused: false,
        is_stopped: false,
        is_inactive: true,
        unavailable: false,
        deprecated: true,
        deprecatedTooltip: "backup_service_deprecated_ig_tooltip",
        mode: 0,
        source: "Instagram",
        created: null,
        downloaded_photos: null,
        exit_code: null,
        finished: null,
        started: null,
        srcobj: window.Instagram,
        type: "ongoing",
        is_allowed_to_backup: true
    }
];

export const DEFAULT_MODAL_SETTINGS = {
    isOpened: false,
    isLoading: false,
    primaryClick: () => {},
    primaryText: "",
    secondaryClick: () => {},
    secondaryText: "",
    title: "",
    description: "",
    primaryColor: "",
    onClose: () => {}
};

export const SOURCES = {
    odn: window.odSource,
    dbxn: window.dbxSource,
    dbxb: window.dbxBSource,
    fb: window.fbSource,
    ig: window.igSource,
    pi: window.piSource,
    copy: window.copySource,
    gdriven: window.gdriveSource,
    gphotos: window.gphotosSource
};
