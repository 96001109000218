import React from "react";
import { __ } from "../../../../../lib/translate";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import EmptyDesktopIcon from "../../../../../../root/img/grid/empty-state/myshares-desktop.svg";
import EmptyMobileIcon from "../../../../../../root/img/grid/empty-state/myshares-mobile.svg";
import {
    EmptyStateMessage,
    EmptyStateMessageSubTitle,
    EmptyStateMessageTitle,
    EmptyStateWrapper,
    ImageContainer
} from "../styles";
import styled from "styled-components";

const SharedWithMe = ({ noItemsLabel }) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;

    return (
        <EmptyStateWrapper $isMobile={isMobile}>
            <EmptyStateMessage $isMobile={isMobile}>
                <EmptyStateMessageTitle>
                    {__(...noItemsLabel)}
                </EmptyStateMessageTitle>
                <EmptyStateMessageSubTitleCustom>
                    {__(
                        "sharedwithme_empty_message",
                        "If someone has shared a folder with you, you can copy the contents to your pCloud account."
                    )}
                </EmptyStateMessageSubTitleCustom>
            </EmptyStateMessage>

            <ImageContainer $isMobile={isMobile}>
                {!isMobile && <EmptyDesktopIcon />}
                {isMobile && <EmptyMobileIcon />}
            </ImageContainer>

            <div />
        </EmptyStateWrapper>
    );
};

export default SharedWithMe;

const EmptyStateMessageSubTitleCustom = styled(EmptyStateMessageSubTitle)`
    padding: 16px 0;
`;
