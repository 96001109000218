// @flow

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { __ } from "../../lib/translate";
import * as Style from "./preferences.style";
import { planBoxPeriodTexts, productNameMap, STORAGE_PLANS_PER_PERIOD } from "@pcloud/web-utilities/dist/plans/plansInfo";
import { ADDITIONAL_TRAFFIC_ID, BUSINESS_PLAN_2_API_ID, BUSINESS_PLAN_2_ID, BUSINESS_PLAN_API_ID, BUSINESS_PLAN_ID, CRYPTO_PLAN_ID, CUSTOM4TB_PLAN_ID, CUSTOM_PLAN_ID, FAMILY_CUSTOM_PLAN_ID } from "@pcloud/web-utilities/dist/plans/constants.plans";
import { siteUrl } from "@pcloud/web-utilities/dist/config";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { BUSINESS_PLANS_ID_MAP_FROM_API } from "../../../web-shared/constants";
import hashManager from "../../lib/hashManager";

const UserPlanInfoSetting = () => {
  const currentPlan = useSelector(({ user }) => user.userinfo.plan);
  const quota = useSelector(({ user }) => user.userinfo.quota);
  const lastSubscription = useSelector(({ user }) => user.userinfo.lastsubscription);
  const additionalTraffic = useSelector(({ user }) => user.userinfo.additionaltraffic);
  const planParams = useSelector(({ user }) => user.userinfo.planparams);
  const additionalTrafficLifetime = useSelector(({ user }) => user.userinfo.additionaltrafficlifetime);
  const premiumLifetime = useSelector(({ user }) => user.userinfo.premiumlifetime);
  const encryptionLifetimePlan = useSelector(({ user }) => user.userinfo.cryptolifetime);
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const isBusinessMember = isBusiness && useSelector(({ user }) => !user.userinfo.account.owner);
  const isFamily = useSelector(({ user }) => user.userinfo.family);
  const isFamilyMember = isFamily && useSelector(({ user }) => !user.userinfo.family.owner);
  const isVivacom = useSelector(({ user }) => !!user.userinfo.vivapcloud);
  const businessBillingInfo = useSelector(({ business }) => business.billingInfo);
  const businessAccountInfo = useSelector(({ business }) => business.accountInfo);

  const renderBasicpCloudPlan = () => {
    if (!currentPlan && !isBusiness && !isVivacom) {
      return (
        <Style.Row>
          <div>
            <Style.Title ><SkipCapitalize>pCloud</SkipCapitalize>{" " + __("plan_name_0", "Basic")}</Style.Title>
            <Style.Description></Style.Description>
          </div>
          <Style.Link href={siteUrl("/cloud-storage-pricing-plans")}>
            {__("upgrade", "Upgrade")}
          </Style.Link>
        </Style.Row>
      )
    }
  }

  const renderLifetimePremiumPlan = () => {
    const canAddStorage = !planParams || (planParams.storage < 8 && currentPlan === CUSTOM4TB_PLAN_ID) || (planParams.storage < 32 && currentPlan === CUSTOM_PLAN_ID) || currentPlan === FAMILY_CUSTOM_PLAN_ID;
    
    if (currentPlan && premiumLifetime) {
      let quotaCalculated = "";

      if (currentPlan === CUSTOM_PLAN_ID || currentPlan === FAMILY_CUSTOM_PLAN_ID) {
        quotaCalculated = renderQuota(quota);
      }

      return (
        <Style.Row>
          <Style.Title>{`${productNameMap[currentPlan].long} ${quotaCalculated || ""} (${planBoxPeriodTexts.lifetime})`}</Style.Title>
          {canAddStorage ? (
            <Style.Link href={siteUrl("/cloud-storage-pricing-plans")}>
              {__("add_storage", "Add storage")}
            </Style.Link>
          ) : null}
        </Style.Row>
      )
    }
  }

  const renderLifetimeEncryptionPlan = () => {
    if (encryptionLifetimePlan) {
      return (
        <Style.Row>
          <Style.Title>{`${productNameMap[CRYPTO_PLAN_ID].long} (${planBoxPeriodTexts.lifetime})`}</Style.Title>
        </Style.Row>
      )
    }
  }

  const renderSubscriptionPlans = () => {
    if (lastSubscription?.products && !isBusiness) {
      return lastSubscription.products.filter(productid => {
        const productisPremiumLifetime = currentPlan == productid && premiumLifetime;
        const isAdditionalTraffic = productid === ADDITIONAL_TRAFFIC_ID;
        const isBusiness = productid === BUSINESS_PLAN_ID || productid === BUSINESS_PLAN_2_ID;

        return !(productisPremiumLifetime || isAdditionalTraffic || isBusiness)
      }).map(productid => {
        const planName = productNameMap[productid].long;

        let Button = null;

        if (STORAGE_PLANS_PER_PERIOD[lastSubscription.period].indexOf(productid) !== -1) {
          Button = (
            <Style.Link href={siteUrl("/cloud-storage-pricing-plans")}>
              {__("Upgrade")}
            </Style.Link>
          )
        } else {
          Button = (
            <Style.Link href={siteUrl("/manage-subscriptions")}>
              {__("manage_subscription", "Manage Subscription")}
            </Style.Link>
          )
        };

        return (
          <Style.Row>
            <Style.Title>{`${planName} (${planBoxPeriodTexts[lastSubscription.period]})`}</Style.Title>
            {Button}
          </Style.Row>
        )
      })
    }
  };

  const renderBusinessPlan = () => {
    if (!isBusiness) {
      return null;
    }

    const businessPlanId = BUSINESS_PLANS_ID_MAP_FROM_API[businessAccountInfo?.account?.businessplanid];
    let period = "";
    console.log("businessPlanId", businessPlanId)
    if (businessBillingInfo?.is_trial) {
      period = __("free_trial", "Free Trial");
    } else if (businessBillingInfo?.billing?.period === 0) {
      period = __("Monthly");
    } else if (businessBillingInfo?.billing?.period === 1) {
      period = __("annual_payment", "Annual");
    }

    return (
      <Style.Row>
        <Style.Title>{`${productNameMap[businessPlanId]?.long} ${period ? "(" + period + ")" : ""}`}</Style.Title>
        {
          businessAccountInfo?.can_manage_billing ? (
            <Style.Button onClick={() => {hashManager.pushState({ page: "b_billing" })}}>
              {businessBillingInfo?.is_trial ? __("Activate") : __("Billing", "Billing")}
            </Style.Button>
          ) : null
        }
      </Style.Row>
    )
  }

  const renderAdditionalTrafficPlan = () => {
    if (additionalTraffic) {
      const canAddTraffic = planParams.additionaltraffic < 10;
      return (
        <Style.Row>
          <Style.Title>
            {`${__(productNameMap[ADDITIONAL_TRAFFIC_ID].long, null, { amount: (planParams.additionaltraffic * 2) + " " +__("TB")})} (${__(additionalTrafficLifetime ? "lifetime" : planBoxPeriodTexts[lastSubscription.period], lastSubscription.period)})`}
          </Style.Title>
          {canAddTraffic ? (
            <Style.Link href={siteUrl("/additional-traffic")}>
              {__("add_traffic", "Add traffic")}
            </Style.Link>
          ) : null}
        </Style.Row>
      )
    }
  }

  return (
    <>
      {renderBasicpCloudPlan()}
      {renderLifetimePremiumPlan()}
      {renderLifetimeEncryptionPlan()}
      {renderAdditionalTrafficPlan()}
      {renderSubscriptionPlans()}
      {renderBusinessPlan()}
    </>
  );
};

export default UserPlanInfoSetting;

const SkipCapitalize = styled.span`
  text-transform: none;
`;
