import React from "react";

import * as Style from "../preferences.style";
import { __ } from "../../../lib/translate";

const BackupAccountUI = ({
  type,
  app,
  account,
  linked = false,
  unavailable = false,
  deprecated = false,
  onLink = () => {},
  onUnlink = () => {}
}) => {
  if (deprecated && !linked) {
    return null;
  }

  return (
    <Style.BodyRow key={`row-${type}`}>
      <Style.BodyCol key={`col-0-${type}`}>{app}</Style.BodyCol>
      <Style.BodyCol key={`col-1-${type}`}>{account || "-"}</Style.BodyCol>
      <Style.BodyCol key={`col-2-${type}`}>
        {
          (!unavailable || linked) ? (
            <Style.Button key={`but-${type}`} onClick={() => linked ? onUnlink() : onLink()}>
              {linked ? __('Unlink') : __('settings_accounts_link', "Link")}
            </Style.Button>
          ) : (
            <Style.Warning>
              {__("backup_warning_unavailable", "Option temporarily unavailable")}
            </Style.Warning>
          )
        }
      </Style.BodyCol>
    </Style.BodyRow>
  );
}

export default BackupAccountUI;
