import ImageBusiness from "../../../root/img/svg/wellcome/business.svg";
import ImageInvite from "../../../root/img/svg/wellcome/invite.svg";
import ImageFamily from "../../../root/img/svg/wellcome/family.svg";
import ImageVerify from "../../../root/img/svg/wellcome/verify.svg";
import ImageDeleteAccount from "../../../root/img/svg/wellcome/delete-account.svg";
import ImageChangeEmail from "../../../root/img/svg/wellcome/change-email.svg";
import ImageResetPassword from "../../../root/img/svg/wellcome/reset-password.svg";
import ImageUnsubscribe from "../../../root/img/svg/wellcome/unsubscribe.svg";
import ImageBusinessActivate from "../../../root/img/svg/wellcome/business_activate.svg";
import ImageResetCrypto from "../../../root/img/svg/wellcome/reset_crypto.svg";

import FolderInvitation from "../../components/shares/FolderInvitation";
import BusinessInvitation from "../Business/BusinessInvitation";
import FamilyInvitation from "../FamilyPlan/FamilyInvitation";
import DeactivateAccount from "../DeactivateAccount";
import VerifyEmailPage from "../../components/VerifyEmailPage";
import ChangeEmailChooseNewEmail from "../ChangeEmailChooseNewEmail";
import ResetPasswordContainer from "../ResetPasswordContainer";
import ChangeEmailConfirmation from "../ChangeEmailConfirmation";
import ChangePasswordContainer from "../ChangePasswordContainer";
import UnsubscribeContainer from "../UnsubscribeContainer";
import ResetCryptoContainer from "../ResetCryptoContainer";
import BusinessActivationContainer from "../BusinessActivationContainer";

import { PAGES } from "../../config/constants";

export const PAGES_EMAIL_REDIRECTS = [
  PAGES.VERIFY_MAIL,
  PAGES.RESET_PASSWORD,
  PAGES.SET_PASSWORD,
  PAGES.CHOOSE_NEW_MAIL,
  PAGES.DEACTIVATE_ACCOUNT,
  PAGES.FAMILY_INVITE,
  PAGES.INVITE_FOLDER,
  PAGES.BUSINESS_INVITE,
  PAGES.BUSINESS_ACTIVATE_ACCOUNT,
  PAGES.RESET_CRYPTO,
  PAGES.CHANGE_EMAIL,
  PAGES.CHANGE_PASSWORD,
  PAGES.UNSUBSCRIBE,
]

export const PAGE_CONFIG = {
  [PAGES.VERIFY_MAIL]: {
    image: ImageVerify,
    formComponent: VerifyEmailPage
  },
  [PAGES.RESET_PASSWORD]: {
    image: ImageResetPassword,
    formComponent: ResetPasswordContainer
  },
  [PAGES.SET_PASSWORD]: {
    image: ImageResetPassword,
    formComponent: ResetPasswordContainer
  },
  [PAGES.CHOOSE_NEW_MAIL]: {
    image: ImageChangeEmail,
    formComponent: ChangeEmailChooseNewEmail
  },
  [PAGES.DEACTIVATE_ACCOUNT]: {
    image: ImageDeleteAccount,
    formComponent: DeactivateAccount
  },
  [PAGES.FAMILY_INVITE]: {
    image: ImageFamily,
    formComponent: FamilyInvitation
  },
  [PAGES.INVITE_FOLDER]: {
    image: ImageInvite,
    formComponent: FolderInvitation
  },
  [PAGES.BUSINESS_INVITE]: {
    image: ImageBusiness,
    formComponent: BusinessInvitation
  },
  [PAGES.BUSINESS_ACTIVATE_ACCOUNT]: {
    image: ImageBusinessActivate,
    formComponent: BusinessActivationContainer
  },
  [PAGES.RESET_CRYPTO]: {
    image: ImageResetCrypto,
    formComponent: ResetCryptoContainer
  },
  [PAGES.CHANGE_EMAIL]: {
    image: ImageChangeEmail,
    formComponent: ChangeEmailConfirmation
  },
  [PAGES.CHANGE_PASSWORD]: {
    image: ImageResetPassword,
    formComponent: ChangePasswordContainer
  },
  [PAGES.UNSUBSCRIBE]: {
    image: ImageUnsubscribe,
    formComponent: UnsubscribeContainer
  },
};