import React from "react";
import styled from "styled-components";
import { TABS, TABS_MOBILE } from "../configurations";

const Tabs = ({ category, setCategory }) => {
    const isMobile = HFN.config.isMobile();

    return (
        <Wrapper>
            {(isMobile ? TABS_MOBILE : TABS).map((item, index) => {
                const isActive = category === item.id;

                if (
                    category.id === "desktop" &&
                    isMobile &&
                    category.hasOwnProperty("items") &&
                    category.items.length === 0
                ) {
                    return null;
                }

                return (
                    <Tab
                        key={index}
                        className={isActive ? "active" : ""}
                        onClick={() => {
                            if (typeof gtag === "function") {
                                gtag("event", "backup_click", {
                                  action: "filter",
                                  type: item.id
                                })
                              }
                            setCategory(item.id)
                        }}
                    >
                        <IconWrapper className={item.iconActive + " icon-active"} />
                        <IconWrapper className={item.icon + " icon-normal"} />
                        <Title>{__(item.title)}</Title>
                    </Tab>
                );
            })}
        </Wrapper>
    );
};

export default Tabs;

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 0 8px;
    margin-bottom: 8px;
    gap: 16px;
    overflow: auto;
`;

const Tab = styled.div`
    display: flex;
    padding: var(--spacing-sm) var(--spacing-md);
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: var(--radius-full);
    background-color: var(--surface-primary);
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    & .icon-active {
        display: none;
    }
    & .icon-normal {
        display: block;
    }

    &.active {
        background-color: var(--color-primary-100);
    }
    &.active,
    &:hover {
        & .icon-active {
            display: block;
        }
        & .icon-normal {
            display: none;
        }
    }
`;

const IconWrapper = styled.i`
    background: linear-gradient(180deg, #156A73 0%, #001C1F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
`;

const Title = styled.span`
    color: var(--text-primary);
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: 24px;
`;
