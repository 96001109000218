import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { TDiv, TSpan, TString, HtmlTString } from "../components/intl";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputText from "../components/InputText";
import * as Style from "./SharedInvitationComponents";
import styles from "../styles/forms.less";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";

import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";
import { boldConverter } from "../lib/componentifyConverters";
import InputPassword from "../components/InputPassword";

const ResetPasswordContainer = ({ code, isSetPassword }) => {
  const [newpassword, setNewpassword] = useState("");
  const [newpasswordRepeat, setNewpasswordRepeat] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const resetPassword = useCallback(
    (e) => {
      e.preventDefault();

      if (!newpassword || !newpasswordRepeat) {
        setError(__("Passwords are required.", "Passwords are required."));
        return;
      }

      if (newpassword !== newpasswordRepeat) {
        setError(__("Both passwords should match", "Both passwords should match."));
        return;
      }

      setLoading(true);

      apiMethod(
        "resetpassword",
        { code, newpassword },
        (ret) => {
          HFN.message("Success. Your password has been reset.");
          HFN.pages.goto("login");
          setDone(true);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setLoading(false);
          },
        }
      );
    },
    [code, newpassword, newpasswordRepeat]
  );

  const renderForm = () => (
    <Style.Form method="post" onSubmit={resetPassword}>
      {error && <div className="error">{error}</div>}
      <InputPassword
        name="newpassword"
        placeholder={__("New Password", "New Password")}
        value={newpassword}
        onChange={(e) => setNewpassword(e.target.value)}
        revealPassword
      />
      <InputPassword
        name="repeatpassword"
        placeholder={__("Repeat Password", "Repeat Password")}
        value={newpasswordRepeat}
        onChange={(e) => setNewpasswordRepeat(e.target.value)}
        revealPassword
      />
      <SubmitButton loading={loading} active={!loading}>
        {__("Continue")}
      </SubmitButton>
    </Style.Form>
  );

  const renderDone = () => (
    <Style.ComponentifyWrapper>
      <Componentify
        text={__(
          "change_email_mail_sent",
          "We've sent a link to your email <b>%newemail%</b> with further instructions."
        ).replace("%newemail%", newpassword)}
        converters={[boldConverter]}
      />
    </Style.ComponentifyWrapper>
  );

  const renderNoCode = () => (
    <Style.WarningMessage>
      <WarnIcon />
      <Style.ComponentifyWrapper>
        <Componentify text={__("invalid_data_arrived")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.WarningMessage>
  );

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>
        {isSetPassword ? __("Set up a password", "Set up a password") : __("Reset Password", "Reset Password")}
      </Style.Title>
      {!code ? renderNoCode() : done ? renderDone() : renderForm()}
    </Style.Container>
  );
};

ResetPasswordContainer.propTypes = {
  code: PropTypes.string,
  isSetPassword: PropTypes.bool,
};

export default ResetPasswordContainer;
