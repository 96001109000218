import React from "react";
import * as ContentData from "./ContentData";
import { FolderGrid, FolderList, LeftSideview } from "./Templates";
import { getViewType } from "./../../lib/utils";
import {
	LIST_VIEW, GRID_VIEW, GRID_ASPECT_RATIO_VIEW, LARGE_LIST_VIEW,
	BROWSE_CONTENT, BROWSE_DELETED_CONTENT, BROWSE_REVISION_CONTENT, BROWSE_PHOTOSEARCH_RESULTS, BROWSE_SONGS, BROWSE_ALBUMS, BROWSE_ARTIST, BROWSE_ARTISTS,
	BROWSE_ALBUM, BROWSE_PLAYLIST, BROWSE_PLAYLISTS, BROWSE_CONTENT_REWIND, BROWSE_BOOKMARKS, BROWSE_FILEREQUESTS,
	BROWSE_SHAREDLINKS, BROWSE_MYSHARES, BROWSE_SHAREDWITHME
} from "../../config/constants";

const ContentTemplate = ({
	listGridRef,
	contentType,
	viewType,
	columns,
	contentData,
	contentDataInitDone,
	currentKeys,
	sortOptions,
	sortIsSet,
	isMobileView,
	isMobileDevice,
	selectedFolders,
	selectedFiles,
	onSingleItemSelect,
	onContentClick,
	onContentContextClick,
	onAllItemsSelect,
	onAllFoldersSelect,
	onAllFilesSelect,
	onColumnSortClick,
	renderContentOptions,
	renderViewAndSortMenus,
	renderSelectByTypeMenu,
	hasExploreCards,
	scrollTo,
	overwriteOptionsOnClick
}) => {
	let template;
	let props = {
		listGridRef: listGridRef,
		contentType: contentType,
		columns: columns,
		contentData: contentData,
		contentDataInitDone: contentDataInitDone,
		currentKeys: currentKeys,
		sortOptions: sortOptions,
		sortIsSet: sortIsSet,
		isMobileView: isMobileView,
		isMobileDevice: isMobileDevice,
		onSingleItemSelect: onSingleItemSelect,
		selectedFolders: selectedFolders,
		selectedFiles: selectedFiles,
		onContentClick: onContentClick,
		onContentContextClick: onContentContextClick,
		onAllItemsSelect: onAllItemsSelect,
		onAllFoldersSelect: onAllFoldersSelect,
		onAllFilesSelect: onAllFilesSelect,
		onColumnSortClick: onColumnSortClick,
		renderContentOptions: renderContentOptions,
		renderViewAndSortMenus: renderViewAndSortMenus,
		renderSelectByTypeMenu: renderSelectByTypeMenu,
		hasExploreCards: hasExploreCards,
		scrollTo: scrollTo,
		overwriteOptionsOnClick: overwriteOptionsOnClick
	};

	switch (contentType) {
		case BROWSE_CONTENT:
		case BROWSE_ALBUMS:
			switch (viewType) {
				case LIST_VIEW:
				case LARGE_LIST_VIEW:
					props = {
						noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
						...props
					};
					template = <FolderList {...props} />;
					break;
				case GRID_VIEW:
				case GRID_ASPECT_RATIO_VIEW:
					props = {
						onItemClick: ContentData.TEMPLATES[contentType].onClick,
						getItemName: ContentData.TEMPLATES[contentType].getItemName ?? false,
						noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
						...props
					};
					template = <FolderGrid {...props} />;
					break;
				default:
					template = <div>Missing content view template for <b>"{contentType}" ({viewType} view)</b></div>;
					break;
			}
			break;
		case BROWSE_CONTENT_REWIND:
		case BROWSE_DELETED_CONTENT:
			switch (viewType) {
				case GRID_VIEW:
				case GRID_ASPECT_RATIO_VIEW:
					props = {
						onItemClick: ContentData.TEMPLATES[contentType].onClick,
						getItemName: ContentData.TEMPLATES[contentType].getItemName ?? false,
						noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
						...props
					};
					template = <FolderGrid {...props} />;
					break;
				default:
					props = {
						noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
						...props
					};
					template = <FolderList {...props} />;
			}
			break;
		case BROWSE_PHOTOSEARCH_RESULTS:
			props = {
				onItemClick: ContentData.TEMPLATES[contentType].onClick,
				noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
				...props
			};
			template = <FolderGrid {...props} />;
			break;
		case BROWSE_REVISION_CONTENT:
		case BROWSE_SONGS:
		case BROWSE_ARTISTS:
		case BROWSE_ARTIST:
		case BROWSE_PLAYLISTS:
		case BROWSE_BOOKMARKS:
		case BROWSE_FILEREQUESTS:
		case BROWSE_SHAREDLINKS:
		case BROWSE_MYSHARES:
		case BROWSE_SHAREDWITHME:
			props = {
				noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
				...props
			};
			template = <FolderList {...props} />;
			break;
		case BROWSE_ALBUM:
		case BROWSE_PLAYLIST:
			props = {
				noItemsLabel: ContentData.TEMPLATES[contentType].noItemsLabel,
				...props
			};
			template = <div className="audioSideviewWrapper">
				<LeftSideview {...props} />
				<FolderList {...props} />
			</div>;
			break;
		default:
			template = <div>Missing content view template for <b>"{contentType}"</b>.</div>;
	}

	return template;
};

export default ContentTemplate;
