import React, { useEffect, useState, useRef, useCallback, createContext, forwardRef, useMemo, memo } from "react";
import { VariableSizeGrid, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import debounce from "lodash/debounce";

import { TEMPLATES } from "../../ContentData";
import { getIconType, getViewType } from "../../../../lib/utils";
import FileIcon from "../../../FileIcon";
import {
	Row, GridItemNameMobileWrapper, GridItemAlbumNameMobileWrapper, GridItemNameWrapper, GridItemName,
	GridTitle, GridCellWrapper, IconButton, CheckboxButton, StickyRowWrapperGrid, StickyLabel
} from "../../UI/Style";
import { __ } from "../../../../lib/translate";
import { GRID_ASPECT_RATIO_VIEW, BROWSE_ALBUMS } from "../../../../config/constants";
import { getHeaderElements } from "../../ContentViewUtils";
import EmptyState from "../../UI/EmptyState";
import Credits from "../../UI/Footer/Credits";

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

const GRID_FULL_WIDTH = 'calc(100% - var(--spacing-lg) + 8px)';
const VIEW_SORT_ROW_HEIGHT = 48;
const FOOTER_HEIGHT = 60;
const GRID_ITEM_MIN_WIDTH = 180;
let gridViewFoldersHeight = 56;
const FOLDERS_LABEL_HEIGHT = 34;
let gridViewFilesLabelHeight = 56;
const FILES_HEIGHT_ASPECT_RATIO = 1;

const renderCellContent = (item, getItemName, onSingleItemSelect, onItemClick, onContentClick, onContentContextClick, selectedFolders, selectedFiles, contentType, isMobileDevice) => {
	const iconType = useMemo(() => getIconType(item), [item]);
	const nameValue = getItemName ? getItemName(item) : item.name;
	const nameLength = [...nameValue].length;
	const nameViewLength = item.isfolder ? 14 : (isMobileDevice ? 14 : 20);
	const nameTooltipLength = nameLength > nameViewLength ? HFN.strFit(nameValue, 140) : '';
	const isAspectRatioGrid = getViewType(contentType) === GRID_ASPECT_RATIO_VIEW;
	const isFolder = item.isfolder;
	const isAlbum = item.isalbum;
	const isVideo = item.category === HFN.CATEGORY.VIDEO;
	const classList = ["cellContent", "selectable"];
	if (item && (selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString()))) {
		classList.push("selected");
	}
	const fileIconClassList = ["fileIconWrapper", "clickable"];
	if (isAspectRatioGrid) {
		classList.push("noCrop");
	}
	if (isFolder && !isMobileDevice) {
		classList.push("folder");
		fileIconClassList.push("invisibleOnHover");
	}
	if (isAlbum) {
		fileIconClassList.push("album");
	}
	if (isVideo) {
		fileIconClassList.push("playButton");
	}
	if (isMobileDevice) {
		classList.push("mobile");
	}
	const checkBoxClassList = ["checkBox", "clickable"];
	if (isFolder) {
		checkBoxClassList.push("displayOnHover");
	} else {
		checkBoxClassList.push("visibleOnHover");
	}

	return (
		<div className={classList.join(" ")}
			data-tooltip-content={isFolder ? nameTooltipLength : ''}
			data-tooltip-delay-show={500}
			data-tooltip-id="cv-tooltip"
			data-id={item.id}
			onClick={(event) => onContentClick(event, item)}
			onContextMenu={onContentContextClick}
			onDoubleClick={onItemClick ? (event) => onItemClick(event, item) : () => { }}
		>
			{(isFolder && isMobileDevice) &&
				<div className="checkBox clickable mobile">
					<CheckboxButton
						className={"mobile " + ((selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString())) ? "checked" : null)}
						onClick={(event) => { onSingleItemSelect(item, true, event) }}
						onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
					/>
				</div>
			}
			{isAlbum && item.songs_count && <div className="counter">
				{item.songs_count}
			</div>}
			{isAspectRatioGrid &&
				<div className={fileIconClassList.join(" ")} data-id={item.id} onClick={(event) => onItemClick(event, item)}>
					<FileIcon item={iconType} type={HFN.ICONS.SVG_GRID} metadata={item} thumbOptions={{ crop: 0 }} />
				</div>
			}
			{!isAspectRatioGrid &&
				<div className={fileIconClassList.join(" ")} data-id={item.id} onClick={(event) => onItemClick(event, item)}>
					<FileIcon item={iconType} type={HFN.ICONS.SVG_GRID} metadata={item} thumbOptions={{ crop: 1 }} />
				</div>
			}
			{isFolder ?
				<GridItemNameWrapper className={(!isFolder && isMobileDevice) ? "mobile" : ""}>
					<GridItemName
						data-id={item.id}
						onClick={(event) => onItemClick(event, item)}>
						{nameValue}
					</GridItemName>
				</GridItemNameWrapper>
				:
				<GridItemNameMobileWrapper className={(isMobileDevice ? "mobile" : "") + (isAlbum ? " album" : "")}>
					{isAlbum ?
						<GridItemAlbumNameMobileWrapper
							className={(!isFolder && isMobileDevice) ? "mobile" : ""}
							data-id={item.id}
							onClick={(event) => onItemClick(event, item)}
						>
							<GridItemNameWrapper className={(!isFolder && isMobileDevice) ? "mobile left" : "left"}>
								<GridItemName
									data-tooltip-content={nameTooltipLength}
									data-tooltip-id="cv-tooltip"
									className="left"
								>
									{nameValue}
								</GridItemName>
							</GridItemNameWrapper>
							<GridItemNameWrapper className={(!isFolder && isMobileDevice) ? "mobile left second-line" : "left second-line"}>
								<GridItemName className="left second-line">
									{item.artist}
								</GridItemName>
							</GridItemNameWrapper>
						</GridItemAlbumNameMobileWrapper>
						:
						<GridItemNameWrapper className={(!isFolder && isMobileDevice) ? "mobile" : ""}>
							<GridItemName
								data-id={item.id}
								onClick={(event) => onItemClick(event, item)}
								data-tooltip-content={nameTooltipLength}
								data-tooltip-id="cv-tooltip"
							>
								{HFN.strFit(nameValue, nameViewLength)}
							</GridItemName>
						</GridItemNameWrapper>
					}
					{isMobileDevice &&
						<IconButton data-id={item.id} className="mobile" onClick={(event) => { onSingleItemSelect(item, false, event); onContentContextClick(event); }} onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}>
							<i className="baseIcon fa-regular fa-ellipsis-vertical" />
						</IconButton>}
				</GridItemNameMobileWrapper>
			}
			{isFolder &&
				<IconButton data-id={item.id} className={isMobileDevice ? "mobile" : "displayOnHover"} onClick={(event) => { onSingleItemSelect(item, false, event); onContentContextClick(event); }} onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}>
					<i className="baseIcon fa-regular fa-ellipsis-vertical" />
				</IconButton>
			}
			{(!isFolder || (isFolder && !isMobileDevice)) &&
				<div className={isMobileDevice ? "checkBox clickable mobile" : checkBoxClassList.join(" ")}>
					<CheckboxButton
						className={(isMobileDevice ? "mobile " : "") + ((selectedFolders.has(item.id.toString()) || selectedFiles.has(item.id.toString())) ? "checked" : null)}
						onClick={(event) => { onSingleItemSelect(item, true, event) }}
						onDoubleClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
					/>
				</div>
			}
		</div>
	);
};

const FoldersLabel = (selectedFoldersSize, foldersCount, isMobileDevice, onAllFoldersSelect) => {
	return (
		<GridTitle className={(isMobileDevice ? "mobile " : "") + "gridTitle"}>
			<CheckboxButton
				data-tooltip-content={__("select_all_folders", "Select all folders")}
				data-tooltip-id="cv-tooltip"
				data-tooltip-place="right"
				className={(isMobileDevice ? "mobile " : "") + ((selectedFoldersSize && foldersCount === selectedFoldersSize) ? "checked" : (selectedFoldersSize > 0 ? "intermediate" : ""))}
				onClick={() => onAllFoldersSelect()}
			/>
			{__('Folders')}
		</GridTitle>
	)
}

const FilesLabel = (selectedFilesSize, filesCount, isMobileDevice, onAllFilesSelect, renderSelectByTypeMenu) => {
	return (
		<GridTitle className={(isMobileDevice ? "mobile " : "") + "gridTitle filesTitle"}>
			<CheckboxButton
				data-tooltip-content={__("select_all_files", "Select all files")}
				data-tooltip-id="cv-tooltip"
				data-tooltip-place="right"
				className={(isMobileDevice ? "mobile " : "") + ((selectedFilesSize && filesCount === selectedFilesSize) ? "checked" : (selectedFilesSize > 0 ? "intermediate" : ""))}
				onClick={() => onAllFilesSelect()}
			/>
			{renderSelectByTypeMenu("hover-menu-button negative-margin", true)}
			{__('Files')}
		</GridTitle>
	)
}

const GridCell = ({ data, columnIndex, rowIndex, style }) => {
	const { splittedKeys, columnCount, rowsCountBeforeFolders, rowsCountBeforeFiles, contentData, contentType,
		getItemName, onItemClick, onContentClick, onContentContextClick, onSingleItemSelect, onAllFoldersSelect, onAllFilesSelect,
		selectedFolders, selectedFiles, headerGridItems, isMobileDevice, renderSelectByTypeMenu, rowCount } = data;
	const isHeaderRow = rowIndex < rowsCountBeforeFolders - 1;
	const isFoldersLabelRow = rowIndex === rowsCountBeforeFolders - 1;
	const folderRowsCount = isMobileDevice ? splittedKeys.foldersCount : Math.ceil(splittedKeys.foldersCount / columnCount);
	const isFilesLabelRow = rowIndex === folderRowsCount + rowsCountBeforeFolders;

	if (isHeaderRow) {
		return columnIndex === 0 ? (
			<div className="headerBoxWrapper" style={{ ...style, width: isMobileDevice ? "100%" : GRID_FULL_WIDTH, marginLeft: 8 }}>
				{headerGridItems[rowIndex].element({ isMobileDevice: isMobileDevice })}
			</div>
		) : null;
	}

	if (contentType !== BROWSE_ALBUMS) {
		if (isFoldersLabelRow && splittedKeys.foldersCount) {
			return columnIndex === 0 ? (
				<div className="gridTitleWrapper" style={{ ...style, width: isMobileDevice ? "100%" : GRID_FULL_WIDTH }}>
					{FoldersLabel(selectedFolders.size, splittedKeys.foldersCount, isMobileDevice, onAllFoldersSelect)}
				</div>
			) : null;
		} else if (isFilesLabelRow && splittedKeys.filesCount && !(isMobileDevice && !splittedKeys.foldersCount)) {
			return columnIndex === 0 ? (
				<div className="gridTitleWrapper" style={{ ...style, width: isMobileDevice ? "100%" : GRID_FULL_WIDTH }}>
					{FilesLabel(selectedFiles.size, splittedKeys.filesCount, isMobileDevice, onAllFilesSelect, renderSelectByTypeMenu)}
				</div>
			) : null;
		}
	}

	const isFileRow = rowIndex > folderRowsCount + rowsCountBeforeFolders - 1;
	const adjustedRowIndex = isFileRow ? rowIndex - (folderRowsCount + rowsCountBeforeFolders + rowsCountBeforeFiles) : rowIndex - rowsCountBeforeFolders - (isMobileDevice && columnIndex !== 0 ? 1 : 0);
	const itemIndex = (isFileRow || !isMobileDevice) ? adjustedRowIndex * columnCount + columnIndex : adjustedRowIndex;
	const item = isFileRow ? contentData.items[splittedKeys.files[itemIndex]] : contentData.items[splittedKeys.folders[itemIndex]];

	if (rowIndex === rowCount && columnIndex === 0) {
		return (
			<Row
				className="credits"
				key="last-row"
				style={{ ...style, width: isMobileDevice ? "100%" : GRID_FULL_WIDTH, top: "auto", bottom: 0 }}
			>
				<Credits />
			</Row>
		)
	}

	if (!item || (!isFileRow && isMobileDevice && columnIndex !== 0)) {
		return null;
	};

	const classList = ["selectable"];

	classList.push(isFileRow ? "itemFile" : "itemFolder");

	if (!isFileRow && isMobileDevice) {
		style = { ...style, width: "100%" };
	}

	return (
		<GridCellWrapper
			style={style}
			key={`${item.id}_cell`}
			className={classList.join(" ")}
			data-id={item.id}
		>
			{renderCellContent(item, getItemName, onSingleItemSelect, onItemClick, onContentClick, onContentContextClick, selectedFolders, selectedFiles, contentType, isMobileDevice)}
		</GridCellWrapper>
	)
};

const ItemWrapper = memo(({ data, columnIndex, rowIndex, style }) => {
	const { ItemRenderer, stickyIndices, ...rest } = data;
	if (columnIndex === 0 && stickyIndices && stickyIndices.includes(rowIndex)) {
		return null;
	}

	return <ItemRenderer columnIndex={columnIndex} rowIndex={rowIndex} style={style} data={rest} />;
}, areEqual);

const StickyRow = ({ isMobileDevice, style, showStickyBar, renderContentOptions, renderStickyHeaderLabelRow, contentType }) => (
	<StickyRowWrapperGrid style={style} className={(showStickyBar) ? ("sticky" + (isMobileDevice ? " mobile" : "") + (contentType === BROWSE_ALBUMS ? " fullWidth" : "")) : ""}>
		{renderStickyHeaderLabelRow(isMobileDevice)}
		{!isMobileDevice && renderContentOptions()}
	</StickyRowWrapperGrid>
);

const innerElementType = forwardRef(({ children, style, ...rest }, ref) => {
	return <StickyListContext.Consumer>
		{({ stickyIndices, showStickyBar, renderContentOptions, renderStickyHeaderLabelRow, contentType, headerGridItems, headerItemsHeight, isMobileDevice }) => (
			<div className="innerContent" ref={ref} style={{ ...style, position: "relative", minHeight: "100%" }} {...rest}>
				{stickyIndices.map((index) => (
					<StickyRow
						isMobileDevice={isMobileDevice}
						showStickyBar={showStickyBar}
						renderContentOptions={renderContentOptions}
						renderStickyHeaderLabelRow={renderStickyHeaderLabelRow}
						contentType={contentType}
						key={index}
						style={{ top: headerItemsHeight, left: isMobileDevice ? 0 : 8, width: isMobileDevice ? "100%" : GRID_FULL_WIDTH, height: headerGridItems[index].height }}
					/>
				))}
				{children}
			</div>
		)}
	</StickyListContext.Consumer>
});

const StickyList = ({ handleScroll, children, stickyIndices, showStickyBar, renderContentOptions, renderStickyHeaderLabelRow, renderSelectByTypeMenu, contentType, headerGridItems, headerItemsHeight, listGridRef, scrollTo, itemData, isMobileDevice, ...rest }) => {
	const getFileRowIndex = (itemKey) => {
		const foundIndex = itemData.splittedKeys.files.indexOf(itemKey);

		if (foundIndex !== -1) {
			const scrollToItemRow = Math.ceil((foundIndex + 1) / itemData.columnCount);
			const folderRowsCount = isMobileDevice ? itemData.splittedKeys.foldersCount : Math.ceil(itemData.splittedKeys.foldersCount / itemData.columnCount);
			return scrollToItemRow + folderRowsCount + itemData.rowsCountBeforeFiles + itemData.rowsCountBeforeFolders - 1;
		}
		return -1;
	};

	const getFolderRowIndex = (itemKey) => {
		const foundIndex = itemData.splittedKeys.folders.indexOf(itemKey);

		if (foundIndex !== -1) {
			const scrollToItemRow = Math.ceil((foundIndex + 1) / itemData.columnCount);
			return scrollToItemRow + itemData.rowsCountBeforeFolders - 1;
		}
		return -1;
	};

	useEffect(() => {
		if (scrollTo && listGridRef && listGridRef.current) {
			const itemRowIndex = scrollTo[0] === "d" ? getFolderRowIndex(scrollTo) : getFileRowIndex(scrollTo);

			if (itemRowIndex === -1) {
				return;
			}

			listGridRef.current.scrollToItem({
				rowIndex: itemRowIndex,
				align: "center"
			});
		}
	}, [scrollTo]);

	return (
		<StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices, showStickyBar, renderContentOptions, renderStickyHeaderLabelRow, renderSelectByTypeMenu, contentType, headerGridItems, headerItemsHeight, isMobileDevice }}>
			<VariableSizeGrid
				ref={listGridRef}
				itemData={{ ItemRenderer: children, stickyIndices, showStickyBar, renderContentOptions, renderStickyHeaderLabelRow, renderSelectByTypeMenu, contentType, headerGridItems, isMobileDevice, ...itemData }}
				onScroll={handleScroll}
				overscanRowCount={2}
				overscanColumnCount={6}
				{...rest}
			>
				{ItemWrapper}
			</VariableSizeGrid>
		</StickyListContext.Provider>
	);
};

const FolderGrid = ({
	contentType,
	currentKeys,
	contentData,
	contentDataInitDone,
	getItemName,
	onItemClick,
	onContentClick,
	onContentContextClick,
	onSingleItemSelect,
	onAllFoldersSelect,
	onAllFilesSelect,
	selectedFolders,
	selectedFiles,
	renderContentOptions,
	renderSelectByTypeMenu,
	renderViewAndSortMenus,
	noItemsLabel,
	isMobileView,
	isMobileDevice,
	listGridRef,
	hasExploreCards,
	scrollTo
}) => {
	const keysLen = currentKeys.length;

	if (!contentDataInitDone) {
		return null;
	}

	if (!contentData || !contentData.items || !keysLen || !Object.keys(contentData.items).length) {
		return (
			<EmptyState noItemsLabel={noItemsLabel} />
		)
	}

	const splittedKeys = {
		folders: [],
		foldersCount: 0,
		files: [],
		filesCount: 0
	};

	currentKeys.map((key) => {
		if (contentData.items[key]) {
			if (contentData.items[key].isfolder) {
				splittedKeys.folders.push(key);
			} else {
				splittedKeys.files.push(key);
			}
		}
	});

	splittedKeys.foldersCount = splittedKeys.folders.length;
	splittedKeys.filesCount = splittedKeys.files.length;

	const headerGridItems = [
		...(TEMPLATES[contentType].HEADER_PROMO_BLOCKS ? getHeaderElements(contentType, contentData, hasExploreCards) : []),
		{
			height: VIEW_SORT_ROW_HEIGHT
		},
	];
	if (TEMPLATES[contentType].VIEWS_MENU.length <= 1 && !TEMPLATES[contentType].SORTING_MENU) {
		headerGridItems.pop();
	}
	let headerItemsHeight = 0;
	headerGridItems.map((item) => {
		headerItemsHeight += item.height;
	});
	headerItemsHeight -= headerGridItems[headerGridItems.length - 1] ? headerGridItems[headerGridItems.length - 1].height : 0;

	let rowsCountBeforeFolders = headerGridItems.length + 1;
	let rowsCountBeforeFiles = 1;

	const [columnCount, setColumnCount] = useState(7);
	const [containerWidth, setContainerWidth] = useState(window.innerWidth);
	const [showStickyBar, setShowStickyBar] = useState(false);
	const [stickyFoldersLabelStyle, setStickyFoldersLabelStyle] = useState({});
	const [stickyFilesLabelStyle, setStickyFilesLabelStyle] = useState({});
	const [estimatedRowHeight, setEstimatedRowHeight] = useState(null);
	const [isFirstResize, setIsFirstResize] = useState(true);

	const handleScroll = (e, isMobileDevice) => {
		const { scrollTop } = e;
		const folderRowsHeight = folderRowsCount > 0 ? (folderRowsCount * gridViewFoldersHeight) : -(FOLDERS_LABEL_HEIGHT * 2);

		if (scrollTop < headerItemsHeight) {
			if (showStickyBar) {
				setShowStickyBar(false);
			}
			if (folderRowsCount) {
				setStickyFoldersLabelStyle({
					top: (isMobileDevice ? 0 : VIEW_SORT_ROW_HEIGHT) + "px",
					display: "flex",
					height: isMobileDevice ? VIEW_SORT_ROW_HEIGHT : FOLDERS_LABEL_HEIGHT,
					width: isMobileDevice ? "50%" : "100%"
				});
			}
		} else if (scrollTop >= headerItemsHeight) {
			if (!showStickyBar) {
				setShowStickyBar(true);
			}
			if (folderRowsCount) {
				setStickyFoldersLabelStyle({
					top: (isMobileDevice ? 0 : VIEW_SORT_ROW_HEIGHT) + "px",
					display: "flex",
					height: isMobileDevice ? VIEW_SORT_ROW_HEIGHT : FOLDERS_LABEL_HEIGHT,
					width: isMobileDevice ? "50%" : "100%"
				});
			}
		}

		if (fileRowsCount && scrollTop < headerItemsHeight + (FOLDERS_LABEL_HEIGHT * 2) + folderRowsHeight - (folderRowsCount ? (isMobileDevice ? -10 : 0) : 0)) {
			setStickyFilesLabelStyle({
				display: "none"
			});
		}

		if (folderRowsCount && scrollTop >= headerItemsHeight + FOLDERS_LABEL_HEIGHT + folderRowsHeight - (isMobileDevice ? -10 : 8)) {
			setStickyFoldersLabelStyle({
				display: "none"
			});
		}

		if (fileRowsCount && scrollTop >= headerItemsHeight + (FOLDERS_LABEL_HEIGHT * 2) + folderRowsHeight - (folderRowsCount ? (isMobileDevice ? -6 : 22) : 0)) {
			setStickyFilesLabelStyle({
				top: (isMobileDevice ? 0 : VIEW_SORT_ROW_HEIGHT) + "px",
				display: "flex",
				height: isMobileDevice ? VIEW_SORT_ROW_HEIGHT : FOLDERS_LABEL_HEIGHT,
				width: isMobileDevice ? "50%" : "100%"
			});
		}
	};

	const handleGridResizeDebounced = useCallback(
		debounce((width) => {
			updateGridLayout(width);
		}, 150),
		[]
	);

	const handleGridResize = (width) => {
		if (isFirstResize) {
			updateGridLayout(width);
			setIsFirstResize(false);
		} else {
			handleGridResizeDebounced(width);
		}
	};

	const updateGridLayout = (width) => {
		const wrapper = document.getElementById("elements-container").getBoundingClientRect();

		let columnCnt = 10;
		let i = 0;
		for (i = 2; i < columnCnt; ++i) {
			if (wrapper.width < (GRID_ITEM_MIN_WIDTH * (i + 1))) {
				columnCnt = i;
				break;
			}
		}

		setContainerWidth(width);
		setColumnCount(columnCnt);
		setEstimatedRowHeight((headerItemsHeight + (folderRowsCount * gridViewFoldersHeight) + (fileRowsCount * getFileItemHeight()) + labelsHeight) / rowCount);

		if (listGridRef && listGridRef.current) {
			listGridRef.current.resetAfterIndices({
				columnIndex: 0,
				rowIndex: 0,
				shouldForceUpdate: true
			});
		}
	};

	if (isMobileDevice) {
		gridViewFilesLabelHeight = 74;
		gridViewFoldersHeight = 48;
	}
	let labelsHeight = FOLDERS_LABEL_HEIGHT + gridViewFilesLabelHeight;
	if (splittedKeys.foldersCount === 0 || (isMobileDevice && headerGridItems.length === 1)) {
		--rowsCountBeforeFolders;
		labelsHeight -= FOLDERS_LABEL_HEIGHT;
	}
	if (splittedKeys.filesCount === 0) {
		--rowsCountBeforeFiles;
		labelsHeight -= gridViewFilesLabelHeight;
	}
	if (isMobileDevice && headerGridItems.length > 1) {
		--rowsCountBeforeFolders;
	}
	if (isMobileDevice && splittedKeys.foldersCount === 0) {
		--rowsCountBeforeFiles;
	}
	if (!isMobileDevice && contentType === BROWSE_ALBUMS) {
		rowsCountBeforeFolders = 0;
	}

	const folderRowsCount = isMobileDevice ? splittedKeys.foldersCount : Math.ceil(splittedKeys.foldersCount / columnCount);
	const fileRowsCount = Math.ceil(splittedKeys.filesCount / columnCount);
	const rowCount = folderRowsCount + fileRowsCount + rowsCountBeforeFolders + rowsCountBeforeFiles;

	const getFileItemHeight = useCallback(() => {
		return (containerWidth / columnCount) * FILES_HEIGHT_ASPECT_RATIO + 16 + (contentType === BROWSE_ALBUMS ? 12 : 0);
	}, [containerWidth, columnCount, FILES_HEIGHT_ASPECT_RATIO]);

	const getColumnWidth = useCallback(() => {
		return containerWidth / columnCount;
	}, [containerWidth, columnCount]);

	const getRowHeight = useCallback((rowIndex) => {
		if (rowIndex < rowsCountBeforeFolders - (folderRowsCount ? 1 : 0)) {
			return headerGridItems[rowIndex].height;
		}

		if (rowIndex === rowCount) {
			return FOOTER_HEIGHT;
		}

		const isFilesLabelRow = rowIndex === folderRowsCount + rowsCountBeforeFolders - (isMobileDevice && folderRowsCount === 0 ? 1 : 0);

		if (isFilesLabelRow) {
			return folderRowsCount ? gridViewFilesLabelHeight : (isMobileDevice ? VIEW_SORT_ROW_HEIGHT : (contentType === BROWSE_ALBUMS ? VIEW_SORT_ROW_HEIGHT : FOLDERS_LABEL_HEIGHT));
		} else if (rowIndex === rowsCountBeforeFolders - 1) {
			return (isMobileDevice ? VIEW_SORT_ROW_HEIGHT : FOLDERS_LABEL_HEIGHT);
		}

		const isFileRow = rowIndex > folderRowsCount + rowsCountBeforeFolders - (isMobileDevice && folderRowsCount === 0 ? 1 : 0);

		if (isFileRow) {
			return getFileItemHeight();
		}

		return gridViewFoldersHeight;
	}, [headerGridItems, columnCount, containerWidth, splittedKeys.foldersCount, rowsCountBeforeFolders, contentType]);

	const renderStickyHeaderLabelRow = (isMobileDevice) => {
		return (
			<>
				{splittedKeys.foldersCount > 0 && contentType !== BROWSE_ALBUMS && <StickyLabel style={stickyFoldersLabelStyle}>
					{FoldersLabel(selectedFolders.size, splittedKeys.foldersCount, isMobileDevice, onAllFoldersSelect)}
				</StickyLabel>}
				{splittedKeys.filesCount > 0 && contentType !== BROWSE_ALBUMS && <StickyLabel style={stickyFilesLabelStyle}>
					{FilesLabel(selectedFiles.size, splittedKeys.filesCount, isMobileDevice, onAllFilesSelect, renderSelectByTypeMenu)}
				</StickyLabel>}
				{isMobileDevice && renderViewAndSortMenus()}
			</>
		)
	}

	return (
		<AutoSizer onResize={({ width }) => handleGridResize(width - (isMobileDevice ? 0 : 16))}>
			{({ width, height }) => (
				<StickyList
					className="sticky-list-wrapper"
					handleScroll={(event) => handleScroll(event, isMobileDevice)}
					showStickyBar={showStickyBar}
					renderContentOptions={renderContentOptions}
					renderSelectByTypeMenu={renderSelectByTypeMenu}
					renderStickyHeaderLabelRow={renderStickyHeaderLabelRow}
					headerGridItems={headerGridItems}
					headerItemsHeight={headerItemsHeight}
					listGridRef={listGridRef}
					innerElementType={innerElementType}
					stickyIndices={[headerGridItems.length - 1]}
					contentType={contentType}
					width={width}
					height={height}
					columnCount={columnCount}
					columnWidth={getColumnWidth}
					rowCount={rowCount + 1}
					rowHeight={getRowHeight}
					estimatedColumnWidth={containerWidth / columnCount}
					estimatedRowHeight={estimatedRowHeight}
					isMobileDevice={isMobileDevice}
					scrollTo={scrollTo}
					itemData={{
						splittedKeys,
						contentData,
						rowCount,
						columnCount,
						rowsCountBeforeFolders,
						rowsCountBeforeFiles,
						getItemName,
						onItemClick,
						onContentClick,
						onContentContextClick,
						onAllFoldersSelect,
						onAllFilesSelect,
						onSingleItemSelect,
						selectedFolders,
						selectedFiles
					}}
				>
					{GridCell}
				</StickyList>
			)}
		</AutoSizer>
	);

};

export default FolderGrid;
